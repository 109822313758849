import { map } from 'lodash';
import { all, fork } from 'redux-saga/effects';

import * as authSagas from './Authentication/Authentication.saga';
import * as preferenceSagas from './Preference/Preference.saga';
import * as entitySagas from './Entity/Entity.saga';
import * as sharedSagas from './Shared/Shared.saga';
import * as uiSagas from './UI/uiSagas';
import * as assetsSagas from './Assets/Assets.saga';
import * as permissionSagas from './Permission/Permission.saga';
import * as userSagas from './User/User.saga';
import * as groupSagas from './Group/Group.saga';
import * as blockSagas from './Blocks/Blocks.saga';
import * as notificationSagas from './Notifications/Notifications.saga';
import * as correlationSagas from './Correlations/Correlations.saga';
import * as imagesSagas from './Images/Images.saga';
import * as schedulesSagas from './Schedules/Schedules.saga';
import * as labelSagas from './Labels/Labels.saga';
import * as skuSagas from './Sku/Sku.saga';
import * as skuChartsSagas from './SkuCharts/SkuCharts.saga';
import * as dataSagas from './Data/Data.saga.js';
import * as fusionSagas from './Fusions/Fusions.saga';
import * as deviceSagas from './Devices/Devices.saga';
import * as issueSagas from './Issues/Issues.saga';
import * as metadataSagas from './Metadata/Metadata.saga';
import * as targetSagas from './Target/Target.saga';
import * as trackedIssueSagas from './TrackedIssues/TrackedIssues.saga';
import * as tilesSagas from './Tiles/Tiles.saga';
import * as gatewaySagas from './Gateway/Gateway.saga';
import * as invitationSagas from './Invitation/Invitation.saga';
import * as reportsSagas from './UI/Report/Report.saga';
import * as rootSagas from './Root.saga';

export default function* rootSaga() {
    yield all(map(authSagas, (item) => fork(item)));
    yield all(map(entitySagas, (item) => fork(item)));
    yield all(map(preferenceSagas, (item) => fork(item)));
    yield all(map(sharedSagas, (item) => fork(item)));
    yield all(map(uiSagas, (item) => fork(item)));
    yield all(map(assetsSagas, (item) => fork(item)));
    yield all(map(permissionSagas, (item) => fork(item)));
    yield all(map(userSagas, (item) => fork(item)));
    yield all(map(groupSagas, (item) => fork(item)));
    yield all(map(blockSagas, (item) => fork(item)));
    yield all(map(labelSagas, (item) => fork(item)));
    yield all(map(skuSagas, (item) => fork(item)));
    yield all(map(skuChartsSagas, (item) => fork(item)));
    yield all(map(dataSagas, (item) => fork(item)));
    yield all(map(notificationSagas, (item) => fork(item)));
    yield all(map(correlationSagas, (item) => fork(item)));
    yield all(map(imagesSagas, (item) => fork(item)));
    yield all(map(schedulesSagas, (item) => fork(item)));
    yield all(map(fusionSagas, (item) => fork(item)));
    yield all(map(deviceSagas, (item) => fork(item)));
    yield all(map(issueSagas, (item) => fork(item)));
    yield all(map(metadataSagas, (item) => fork(item)));
    yield all(map(targetSagas, (item) => fork(item)));
    yield all(map(trackedIssueSagas, (item) => fork(item)));
    yield all(map(tilesSagas, (item) => fork(item)));
    yield all(map(gatewaySagas, (item) => fork(item)));
    yield all(map(invitationSagas, (item) => fork(item)));
    yield all(map(reportsSagas, (item) => fork(item)));
    yield all(map(rootSagas, (item) => fork(item)));
}
