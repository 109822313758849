import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react"
import BlockModel from "../../../api/models/block.model"
import { flatten, uniqBy } from "lodash"
import { errorFlash } from "../../../legacy/components/Flash"
import Checklist, { ChecklistItem } from "../../../components/Checklist/Checklist"
import { AnyFunction } from "../../../types/any"
import { useSelector } from "react-redux"
import { currentEntitySelector } from "../../../store/old/Entity/Entity.selector"

const AssetsChecklist = forwardRef((props: Props, ref) => {
    const { entity_id } = useSelector(currentEntitySelector)
    const [labelled, setLabelled] = useState<Set<number>>(new Set())
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const labelledSet: Set<number> = new Set()
        if (!props.block.children) {
            setLabelled(labelledSet)
            return
        };

        setLoading(true)
        const promises = props.block.children
            .filter(({ assetId }) => assetId)
            .map(({ assetId }) => props.fetchLabels(entity_id as number, assetId as number, props.range))
        
        Promise.all(promises)
            .then((response: any) => {
                const combined = flatten(response)
                const labelledAssets = uniqBy(combined, "asset_id").map((l: any) => l.asset_id)
                setLabelled(new Set(labelledAssets))
            })
            .catch(errorFlash)
            .finally(() => setLoading(false))

    }, [props.block, props.range])

    useImperativeHandle(ref, () => ({ isLoading: loading }), [loading])

    const items: ChecklistItem[] = useMemo(() => {
        if (!props.block.children) return []
        return props.block.children.map((b) => ({
            label: b.label,
            value: b.blockId,
            disabled: !b.assetId || labelled.has(b.assetId),
        }))
    }, [props.block, labelled])

    const [checkedKeys, setCheckedKeys] = useState<(string | number)[]>(items.filter(({ disabled }) => !disabled).map(({ value }) => value))

    useEffect(() => {
        setCheckedKeys(items.filter(({ disabled }) => !disabled).map(({ value }) => value))
    }, [items])

    useEffect(() => {
        props.onChange(checkedKeys)
    }, [checkedKeys])

    return <Checklist
        items={items}
        onChange={setCheckedKeys}
        checkedKeys={checkedKeys}
    />
})

AssetsChecklist.displayName = "AssetsChecklist"

export default AssetsChecklist

interface Props {
    block: BlockModel
    range: Date[]
    fetchLabels: AnyFunction
    onChange: AnyFunction
}
