import { isString, keys } from 'lodash';
import { regexMatch } from './helpers';
import moment from 'moment';

/* Validators should be in the form:

  ** assessor functions should return true if failing

  const VALIDATE = {
    [FIELDS.NAME]: [
      { err: '...is required.', assessor: (v) => isRequiredField(v) },
      {
        err: '...contains invalid characters ([#%&*?|<>).',
        assessor: (v) => containsSuspiciousCharacters(v),
      },
    ],
    [FIELDS.CODE]: [
      { err: '...is required.', assessor: (v) => isRequiredField(v) },
      {
        err: '...contains invalid characters ([#%&*?|<>).',
        assessor: (v) => containsSuspiciousCharacters(v),
      },
    ],
    [FIELDS.UOM]: [
      { err: '...is required.', assessor: (v) => isRequiredField(v) },
      {
        err: '...contains invalid characters ([#%&*?|<>).',
        assessor: (v) => containsSuspiciousCharacters(v),
      },
    ],
    [FIELDS.CATEGORY]: [
      { err: '...is required.', assessor: (v) => isRequiredField(v) },
      {
        err: '...contains invalid characters ([#%&*?|<>).',
        assessor: (v) => containsSuspiciousCharacters(v),
      },
    ],
    [FIELDS.DESCRIPTION]: [
      { err: '...is required.', assessor: (v) => isRequiredField(v) },
      {
        err: '...contains invalid characters ([#%&*?|<>).',
        assessor: (v) => containsSuspiciousCharacters(v),
      },
    ],
  };

*/

export const timezoneRegex = /[+-][0-9]{2}:[0]{2}\b/;

export const mathOperatorsRegex = /[\(\)!^&*\-+.<>()/|=]/g;

export const suspiciousCharactersRegex = /[#%&*?|<>\\]/;

export const containsSuspiciousCharacters = (str, flag) =>
    regexMatch(str, suspiciousCharactersRegex, flag);

export const validateSingleField = (validator, fieldName, formData) => {
    // returns array of errors if field is invalid, else empty array
    const fieldValidator = validator[fieldName];

    if (!fieldValidator) return [];

    for (let i = 0; i < fieldValidator.length; i++) {
        const { assessor, err } = fieldValidator[i];
        if (assessor(formData)) return [err];
    }

    return [];
};

export const validateDateTimeRange = (arrayDates) => {
    return Array.isArray(arrayDates) && !!arrayDates.filter(val => moment.isMoment(val)).length;
}

export const getFormErrors = (formData, validator) => {
    return keys(formData).reduce((acc, curr) => {
        const errors = validateSingleField(validator, curr, formData);
        errors.length && acc.push(...errors);
        return acc;
    }, []);
};

export const isRequiredField = (v) => {
    if (isString(v)) return !(v && v.trim().length);
    return !v;
};

export const isValidFloat = (x) => {
    const f = parseFloat(x);
    return f === 0 || f;
};
