import * as d3 from 'd3';
import React from 'react';
import { AutoSizer } from 'react-virtualized';

import { BaseChartSimple } from './BaseChart';
import {
    clearCanvas,
    genColor,
    getTimeIntervalBandwidth,
    getTimeSeriesBrushXSelection,
} from '../../utils/charts';

const FOCUS_RADIUS = 6;
const HIDDEN_RADIUS = 12;

class MultiLineChartSimple extends BaseChartSimple {
    constructor(props) {
        super(props);

        this.redrawCustom = this.redrawCustom.bind(this);
        this.redrawCanvas = this.redrawCanvas.bind(this);
        this.redrawHidden = this.redrawHidden.bind(this);
    }

    redrawCustom() {
        const {
            props: {
                transition,
                duration,
                data,
                xAccessor,
                yAccessor,
                colorAccessor,
                lineDashAccessor
            },
            x,
            y,
            h,
            custom,
        } = this;

        const t = transition || null;

        // line
        const lineSelection = custom.selectAll('.path').data(data, (d, i) => i);

        lineSelection
            .enter()
            .append('custom')
            .attr('class', 'path')
            .attr('stroke', colorAccessor)
            .attr('dash', lineDashAccessor);

        lineSelection.transition(t).duration(duration);

        lineSelection.exit().remove();

        const datapoints = data.reduce((acc, curr) => acc.concat(...curr), []);

        const pointSelection = custom
            .selectAll('.point')
            .data(datapoints, (d, i) => i);

        pointSelection
            .enter()
            .append('custom')
            .attr('class', 'point')
            .attr('fill', colorAccessor)
            .attr('cx', (d) => x(xAccessor(d)))
            .attr('cy', (d) => Math.min(y(yAccessor(d)), h - 0.5));

        pointSelection
            .transition(t)
            .duration(duration)
            .attr('cx', (d) => x(xAccessor(d)))
            .attr('cy', (d) => Math.min(y(yAccessor(d)), h - 0.5));

        pointSelection.exit().remove();

        this.redrawYGridLinesCustom();
    }

    redrawCanvas() {
        const {
            props: { xAccessor, yAccessor },
            x,
            y,
            w,
            h,
            canvas,
            custom,
        } = this;

        // clear canvas
        const context = canvas.node().getContext('2d');
        clearCanvas(context, w, h);

        // draw line
        const line = d3
            .line()
            .x((d) => Math.round(x(xAccessor(d))))
            .y((d) => Math.round(Math.min(y(yAccessor(d)), h - 0.5)))
            .context(context);

        const lineSelection = custom.selectAll('.path');

        lineSelection.each(function (d, i) {
            const node = d3.select(this);

            if (node.empty()) return;

            const dashAttr = node.attr('dash') ? node.attr('dash').split(',') : []
            context.setLineDash(dashAttr);
            context.beginPath();
            line(d);
            context.lineWidth = 2;
            context.strokeStyle = node.attr('stroke');
            context.stroke();
            context.closePath();
        });

        // const pointSelection = custom.selectAll('.point');
        // pointSelection.each(function (d, i) {
        //     const node = d3.select(this);

        //     if (node.empty()) return;

        //     context.beginPath();
        //     context.arc(
        //         node.attr('cx'),
        //         node.attr('cy'),
        //         1,
        //         2 * Math.PI,
        //         false
        //     );
        //     context.fillStyle = node.attr('fill');
        //     context.fill();
        //     context.closePath();
        // });

        this.redrawYGridLinesCanvas();
    }

    redrawHidden() {
        const {
            props: { getBandwidth },
            w,
            h,
            x,
            colorToNode,
            hidden,
            custom,
        } = this;

        const hiddenContext = hidden.node().getContext('2d');
        clearCanvas(hiddenContext, w, h);

        const pointSelection = custom.selectAll('.point');
        pointSelection.each(function (d, i) {
            const node = d3.select(this);
            if (node.empty()) return;
            
            const color = genColor(i);
            colorToNode.set(color, d);

            hiddenContext.beginPath();
            hiddenContext.fillStyle = color;
            hiddenContext.arc(
                +node.attr('cx'),
                +node.attr('cy'),
                HIDDEN_RADIUS,
                2 * Math.PI,
                false
            );
            hiddenContext.fill();
            hiddenContext.closePath();
        });
    }

    showFocus(d) {
        const {
            props: { xAccessor, yAccessor, focusColorAccessor },
            x,
            y,
            focus,
        } = this;

        focus
            .attr('opacity', 1)
            .attr('r', FOCUS_RADIUS)
            .attr('fill', focusColorAccessor)
            .attr('cx', x(xAccessor(d)))
            .attr('cy', y(yAccessor(d)))
            .style('stroke', focusColorAccessor)
            .style('fill', 'none');
    }
}

export class MultiLineChart extends React.Component {
    render() {
        return (
            <AutoSizer>
                {({ width, height }) => (
                    <MultiLineChartSimple {...this.props} width={width} height={height} />
                )}
            </AutoSizer>
        );
    }
}

MultiLineChart.defaultProps = {
    colorAccessor: () => 'steelblue',
    focusColorAccessor: () => '#616161',
    data: [],
    xDomain: [],
    yDomain: [],
    dots: true,
    xAccessor: (d) => d,
    yAccessor: (d) => d,
    useBrush: false,
    snapBrush: getTimeSeriesBrushXSelection,
    getBandwidth: getTimeIntervalBandwidth,
    useTooltip: true,
    htmlTooltip: (d) => d,
    focusEl: 'circle',
};

/* requiredProps - example */
/*
  <MultiLineChart
    xScale={d3.scaleTime()}
    yScale={d3.scaleLinear()}
    data={data}
    xDomain={[store.startDate, store.endDate]}
    yDomain={generateContinuousDomain(data, (d) => d.val)}
    xAccessor={(d) => d.time}
    yAccessor={(d) => d.val}
    useBrush={true}
    htmlTooltip={ColumnChartTooltip}
    focusEl="circle"
  /> 
*/
