import { random } from 'faker';
import { put, all, takeLatest } from 'redux-saga/effects';
import { watcherBuilder } from '../../Base/Base.saga';

import { api_getAssetRowData, api_getBlockRowData } from './TrendUI.services';
import { Label, parseLabelArguments } from '../../../../legacy/models';

import { TrendSetNavigatorState, TrendCreateRows, TrendFetchDataRequest, TrendFetchDataSuccess } from './TrendUI.action';
import { TrendConstants as K } from './TrendUI.constants';
import { errorFlash, flash } from '../../../../legacy/components/Flash';
import { OEEFactory } from '../../../../legacy/utils/oee';
import { TimeSeriesFactory } from '../../../../legacy/utils/data';
import { store } from '../../..';
import moment from 'moment';
import { currentEntitySelector } from '../../Entity/Entity.selector';

function generateTrendRows() {
    const {
        ui: {
            trend: {
                block_id,
                metric,
                chart_id,
                start_date,
                resolution,
                span,
                step: { x: step_x, unit: step_unit },
                single,
                repeats,
            },
        },
    } = store.getState();

    const count = single ? 1 : repeats;
    return [...Array(count).keys()].map((i) => {
        const uuid = random.uuid();
        const start = start_date.clone().add(step_x * i, step_unit);

        return {
            uuid,
            metric,
            block_id,
            chart_id,
            resolution,
            start_date: start,
            span,
            block_id,
        };
    });
}

const MAX_ROWS = 10;
function* handleTrendLoadData(action) {
    try {
        yield put(TrendSetNavigatorState(action.payload));

        const {
            ui: {
                controls: { sku_oee },
                trend: { rows },
            },
        } = store.getState();

        const newRows = generateTrendRows().map((r) => ({ ...r, sku_oee }));

        const totalRows = rows.length + newRows.length;
        if (totalRows > MAX_ROWS) {
            return flash({
                message: 'Cannot load more than ten rows. Please clear and try again',
                status: 'warning',
            });
        }

        yield put(TrendCreateRows(newRows));
        yield put(TrendFetchDataRequest(newRows));
        action.callback && action.callback(newRows);
    } catch (e) {
        errorFlash(e);
    }
}

export function* trendGetDataSaga() {
    yield takeLatest(
        K.ACTIONS.TREND_LOAD_DATA,
        handleTrendLoadData
    );
}

function* handleTrendFetchDataRequest(action) {
    try {
        const { payload: rows } = action;
        const appState = store.getState()
        const entity = currentEntitySelector(appState)
        const { blocks: { blocks } } = appState;

        const promises = rows.map((row) => {
            const { asset } = blocks[row.block_id];
            const query = buildTrendRowQuery(row);

            if (asset) {
                const chart = asset._charts.find(({chart_id}) => chart_id === row.chart_id);
                return api_getAssetRowData(
                    entity.entity_id, 
                    blocks[row.block_id].asset_id,
                    chart.metadata_id,
                    chart.fusion_id,
                    query
                )
            }

            return api_getBlockRowData(entity, row.block_id, query);
        });

        const _dataRows = yield all(promises);
        const result = _dataRows
            .map((d) => {
                const [oee, labels, data] = d;
                return {
                    oee: OEEFactory(oee),
                    labels: labels.map((l) => new Label(...parseLabelArguments(l))),
                    data: data ? TimeSeriesFactory(data.data) : TimeSeriesFactory([]),
                };
            })
            .reduce((acc, curr, i) => {
                return {
                    ...acc,
                    [rows[i].uuid]: curr,
                };
            }, {});

        yield put(TrendFetchDataSuccess(result));
    } catch (e) {
        errorFlash(e);
    }
}

export function* trendGetDataRequestSaga() {
    yield takeLatest(K.ACTIONS.TREND_FETCH_DATA_REQUEST, handleTrendFetchDataRequest);
}

function* trendRowUpdateCb(action) {
    const { payload: row } = action;
    yield put(TrendFetchDataRequest([row]));
}

export function* trendRowUpdateWatcherSaga() {
    yield watcherBuilder(
        K.ACTIONS.TREND_ROW_UPDATE,
        trendRowUpdateCb
    );
}

function* handleTrendRefreshData(action) {
    const {
        ui: {
            trend: { rows },
            controls: { sku_oee },
        },
    } = store.getState();

    yield put(TrendFetchDataRequest(rows.map((r) => ({ ...r, sku_oee }))));

    if (action.callback) {
        action.callback();
    }
}

export function* trendRefreshDataSaga() {
    yield takeLatest(K.ACTIONS.TREND_REFRESH_DATA, handleTrendRefreshData);
}


const buildTrendRowQuery = (params) => {
    const {
        start_date,
        resolution: { res_x, res_period },
        span: { x: span_x, unit: span_unit },
        sku_oee,
    } = params;

    const end_date = start_date.clone().add(span_x, span_unit);
    const date_range = {
        lower: start_date.toISOString(),
        upper: end_date.toISOString(),
    };

    return { res_x, res_period, date_range, sku_oee };
}