import React, { useEffect, useMemo } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import './TimeReportTable.scss'
import { AutoSizer } from 'react-virtualized';
import ReportNoData from './ReportNoData';
import { reportAccessors } from '../accessors';
import { getRecordLabel, REPORT_COLUMNS } from '../reportColumns';
import { generateUrlQuery } from '../../../utils/url';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import CONSTANTS from '../../../Constants';
import { CUSTOM_SPAN } from '../../../../store/old/UI/Controls/Controls.constants';
import { labelDisplayText } from '../../../utils/labels';
import { setSkuTableData } from '../../../../store/old/UI/Report/Report.action';

const SkuReportTable = () => {
    const dispatch = useDispatch();
    const reportStore = useSelector(appState => appState.ui.report);
    const blocksResource = useSelector(appState => appState.blocks.blocks);
    const skusResource = useSelector(appState => appState.sku.skus);

    const columns = useMemo(() => {
        const selectedAssets = reportStore.skuFilter.blockIds.map(id => blocksResource[id].asset);
        const selectedSkus = reportStore.skuFilter.skuIds.map(id => skusResource[id]);
        const defaultColumns = [
            {
                title: 'SKU',
                dataIndex: 'sku',
                width: 180,
                ellipsis: true,
                render: (text, record) => labelDisplayText(record.label),
                fixed: 'left',
                sorter: (a, b) => {
                    const labelA = labelDisplayText(a.label);
                    const labelB = labelDisplayText(b.label);
                    if (labelA < labelB) return -1;
                    if (labelA > labelB) return 1;
                    return 0;
                },
                filters: selectedSkus.map((s) => ({
                    value: s.sku_id,
                    text: s.codeName,
                })),
                filteredValue: reportStore.skuTableData.filters?.sku || null ,
                onFilter: (value, record) => record.label.sku?.sku_id === value,
                filterSearch: true,
            },
            {
                title: 'Asset',
                dataIndex: 'asset',
                width: 140,
                render: (text, record) => getRecordLabel(blocksResource, record),
                fixed: 'left',
                sorter: (a, b) => {
                    const labelA = getRecordLabel(blocksResource, a);
                    const labelB = getRecordLabel(blocksResource, b);
                    if (labelA < labelB) return -1;
                    if (labelA > labelB) return 1;
                    return 0;
                },
                filteredValue: reportStore.skuTableData.filters?.asset || null ,
                filters: selectedAssets.map((a) => ({ value: a.block_id, text: a.asset_name })),
                onFilter: (value, record) => record.blockId === value,
                filterSearch: true,
            },
            {
                title: 'Start',
                width: 120,
                render: (text, record) => reportAccessors.START(record),
                fixed: 'left',
                sorter: (a, b) => {
                    const unixA = moment(a.start).unix();
                    const unixB = moment(b.start).unix();
                    return unixA - unixB;
                },
            },
            {
                title: 'End',
                width: 120,
                render: (text, record) => reportAccessors.END(record),
                fixed: 'left',
                sorter: (a, b) => {
                    const unixA = moment(a.end).unix();
                    const unixB = moment(b.end).unix();
                    return unixA - unixB;
                },
            },
        ];

        const navigateColumn = {
            title: ' ',
            width: 50,
            render: getRowLink(blocksResource),
            fixed: 'right'
        };
        
        return defaultColumns.concat(...reportStore.skuColumns.map(c => REPORT_COLUMNS[c]), navigateColumn);
    }, [blocksResource, reportStore]);

    if (reportStore.skuData.length  === 0) {
        return <ReportNoData />;
    }

    return (
        <AutoSizer>
            {({ width, height }) => {
                return (
                    <div style={{width, height, overflow: 'hidden'}}>
                        <Table
                            size="small"
                            id="skuReportTable"
                            className="report-table"
                            dataSource={reportStore.skuData}
                            columns={columns}
                            rowKey={(row, index) => index}
                            pagination={false}
                            scroll={{
                                x: width,
                                y: height - 55 // subtract header height
                            }}
                            onChange={(pagination, filters, sorter, { currentDataSource }) => {
                                dispatch(setSkuTableData(filters, currentDataSource))
                            }}
                        />
                    </div>
                )
            }}
        </AutoSizer>
    )
}

export default SkuReportTable;

const getRowLink = (blocksResource) => {
    return (record) => {
        const startDate = moment(record.start);
        const endDate = moment(record.end);
        const resolution = { res_x: 1, res_period: 'hours' };
        const block = blocksResource[record.blockId];
        const pathname = block.asset
            ? `${CONSTANTS.URLS.ASSET}/${record.blockId}`
            : `${CONSTANTS.URLS.BLOCK}/${record.blockId}`;
    
        const query = generateUrlQuery({ startDate, endDate, resolution, span: CUSTOM_SPAN });
        return <Link to={`${pathname}${query}`}><ArrowRightOutlined/></Link>

    }
};
