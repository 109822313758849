import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { reportAccessors } from '../accessors';
import { SUMMARY_COLUMNS } from '../reportColumns';
import { getColumnFunctionMenu, OP, ABBREVIATE } from './FunctionMenu';
import ReportSummaryTable, { getSummaryRecordColumn } from './ReportSummaryTable';
import { ReportConstants as K } from '../../../../store/old/UI/Report/Report.constants';
import { toLocaleDecimals } from '../../../utils/helpers';
import { Table } from 'antd';

const SkuReportSummaryTable = () => {
    const columnsState = useSelector(appState => appState.ui.report.skuColumns);
    const skuData = useSelector(appState => appState.ui.report.skuData);
    const skuFilteredData = useSelector(appState => appState.ui.report.skuTableData.dataSource);

    const data = useMemo(() => skuFilteredData?.length ? skuFilteredData : skuData, [skuData, skuFilteredData]);
    
    const [operations, setOperations] = useState({});

    const columns = useMemo(() => {
        const recordsColumn = getSummaryRecordColumn(data.length);
        const optionalColumns = columnsState.map(c => {
            const tableCol = SUMMARY_COLUMNS[c];
            return {
                ...tableCol,
                title: operations[c] ? `${ABBREVIATE[operations[c]]} ${tableCol.title}` : `${ABBREVIATE[K.REPORT_FIELDS[c].defaultFn]} ${tableCol.title}`,
                filterDropdown: getColumnFunctionMenu({
                    onSelect: ({key}) => setOperations({...operations, [c]: key}),
                    items: K.REPORT_FIELDS[c].fnOptions
                })
            }
        });

        return [recordsColumn].concat(...optionalColumns);
    }, [data, columnsState, operations]);

    const dataSource = useMemo(() => {
        return [columnsState.reduce((acc, curr) => {
            const fn = operations[curr] || K.REPORT_FIELDS[curr].defaultFn;
            acc[curr] = OP[fn](data, reportAccessors[curr]);
            return acc;
        }, {})];
    }, [columnsState, data, operations]);

    const summaryFooter = useMemo(() => {
        return columnsState.map((col) => {
            return `${toLocaleDecimals(OP.AVERAGE(data, reportAccessors[col]), 2)} ${K.REPORT_FIELDS[col].unit}`;
        });
    }, [columnsState, data, operations])

    if (data.length === 0) {
        return null;
    }
    
    return (
        <ReportSummaryTable
            columns={columns}
            dataSource={dataSource}
            summary={() => {
                return (
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>Average</Table.Summary.Cell>
                        {
                            summaryFooter.map((item, i) => (
                                <Table.Summary.Cell index={i+1}>
                                    {item}
                                </Table.Summary.Cell>
                            ))
                        }
                    </Table.Summary.Row>
                )
            }}
        />
    );
}

export default SkuReportSummaryTable;