import React, { useCallback, useMemo, useRef, useState } from "react"
import { LabelRangeZoom, Labeller, LabellerBody, LabellerBodyItem, LabellerHeader, LabellerHeaderItem } from "./components"
import { checkFutureLabel, compileLabel, fetchAssetIssueLabels, getSpan } from "../../utils/labels"
import { Issue, Label } from "../../models"
import { round } from "lodash"
import { LabelNotesInput } from "."
import { oeeFactors, oeeWaterfall } from "../../utils/oee"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import BlockModel from "../../../api/models/block.model"
import { Checkbox, Popover } from "antd"
import { ExclamationCircleOutlined, SaveFilled } from "@ant-design/icons"
import { isQualityIssue } from "../../utils/issues"
import { showLabelNotesSelector } from "../../../store/old/Labels/Labels.selector"
import AukButton from "../../components/AukButton"
import { flash } from "../../components/Flash"
import { AnyFunction } from "../../../types/any"
import { createBlockLabels } from "../../../store/old/UI/block/block.action"
import { getMapFromArr } from "../../utils/helpers"
import AssetsChecklist from "../../../routes/block/components/AssetsChecklist"
import PermittedIssuesTreeSelect from "./components/PermittedIssuesTreeSelect"
import IssueLabelListItem from "./components/IssueLabelListItem"
import PopoverBulkTagging from "./components/PopoverBulkTagging"

const BlockIssueLabeller = (props: Props) => {
    return (
        <Labeller className="issue-labeller" id="issueLabeller">
            {props.children}
        </Labeller>
    )
}

interface Props {
    children: React.ReactNode;
}

const BlockIssueLabellerHeader = (props: HeaderProps) => {
    const spanDuration = useMemo(() => getSpan(props.range), [props.range])

    return (
        <LabellerHeader className="flex-column">
            <LabellerHeaderItem label="Span:">{spanDuration}</LabellerHeaderItem>
            <LabellerHeaderItem label="Range">
                <LabelRangeZoom range={props.range} />
            </LabellerHeaderItem>
        </LabellerHeader>
    )
}

interface HeaderProps {
    range: Date[];
}

const BrushedOeeSummary = (props: SummaryProps) => {
    const oee2 = useAppSelector((appState) => appState.ui.controls.oee2)

    const brushedOEE = useMemo(() => {
        const index = oee2 === true ? 1 : 0
        return oeeFactors(oeeWaterfall(props.brushedData))[index]
    }, [props.brushedData, oee2])

    return (
        <LabellerBodyItem header={"OEE"}>
            <div>
                {oee2 ? "OEE-2" : "OEE-1"}: {round(brushedOEE.value, 2)} %
            </div>
        </LabellerBodyItem>
    )
}

interface SummaryProps {
    brushedData: any[];
}

export const BlockIssueLabelView = (props: ViewProps) => {
    return (
        <BlockIssueLabeller>
            <BlockIssueLabellerHeader range={props.range} />
            <LabellerBody>
                <BrushedOeeSummary brushedData={props.brushedData} />
                <LabellerBodyItem header={"Label"}>
                    <IssueLabelListItem data={props.selection} readOnly={true} />
                </LabellerBodyItem>
                <LabellerBodyItem header="Notes">
                    <LabelNotesInput
                        value={props.selection.notes}
                        disabled={true}
                        showCount={false}
                    />
                </LabellerBodyItem>
            </LabellerBody>
        </BlockIssueLabeller>
    )
}

interface ViewProps extends SummaryProps, HeaderProps {
    selection: Label;
}

export const BlockIssueLabelCreate = (props: CreateProps) => {
    const dispatch = useAppDispatch()
    const checklistRef = useRef()
    const showLabelNotes = useAppSelector(showLabelNotesSelector)
    const forBlocks = useMemo(() => {
        const blocks = [props.block].concat(...props.block?.children ?? [])
        return blocks.map(({ blockId }) => blockId)
    }, [props.block])

    const [checkedKeys, setCheckedKeys] = useState<(string | number)[]>([])
    const [issueSelection, setIssueSelection] = useState<Issue | null>(null)
    const [override, setOverride] = useState(false)
    const [notes, setNotes] = useState("")

    const handleSave = useCallback(() => {
        if (!props.block.children) return
        if (!issueSelection?.issue_id) return flash({ message: "Please select an issue", status: "warning" })

        const values = {
            issue: issueSelection.issue_id,
            notes: showLabelNotes ? notes : undefined,
        }

        const payload = compileLabel(
            {
                values,
                from: props.range[0],
                to: props.range[1],
            },
            { override }
        )

        if (checkFutureLabel(payload))
            return flash({
                message: "Cannot create label in future time.",
                status: "warning",
            })

        const blocksToAssets = getMapFromArr(props.block.children, "blockId")
        const checkedAssets = checkedKeys.map(key => blocksToAssets[key].assetId)

        if (!checkedAssets.length) {
            return flash({
                message: "No assets selected.",
                status: "warning",
            })
        }

        dispatch(createBlockLabels(checkedAssets, payload, () => {
            props.onSubmit && props.onSubmit()
        }))

    }, [checklistRef, issueSelection, override, props.range, notes, showLabelNotes, checkedKeys])

    return (
        <BlockIssueLabeller>
            <BlockIssueLabellerHeader range={props.range} />
            <LabellerBody>
                <BrushedOeeSummary brushedData={props.brushedData} />
                <LabellerBodyItem header={"Create"}>
                    <>
                        <AukButton.Blue
                            style={{ position: "absolute", top: "59px", right: "1px", height: "348px", zIndex: 2 }}
                            icon={<SaveFilled />}
                            onClick={handleSave}
                            disabled={checklistRef?.current ? (checklistRef.current as any).isLoading : false}
                        />
                        <PermittedIssuesTreeSelect
                            forBlocks={forBlocks}
                            withDefects
                            onChange={(d: Issue | null) => {
                                setIssueSelection(d)
                            }}
                        />
                    </>
                </LabellerBodyItem>
                <LabellerBodyItem header="Options">
                    <Checkbox
                        checked={override}
                        onChange={(e) => setOverride(e.target.checked)}
                    >
                        <div className="d-flex align-items-center">
                            OEE Override{" "}
                            {isQualityIssue(issueSelection) ? 
                                <Popover
                                    placement="right"
                                    content={
                                        <span style={{ width: 200, color: "#dc3545" }}>
                                        Does not apply to QUA issues
                                        </span>
                                    }
                                >
                                    <ExclamationCircleOutlined
                                        className="ml-2"
                                        style={{ color: "#dc3545" }}
                                    />
                                </Popover>
                                : null}
                        </div>
                    </Checkbox>
                </LabellerBodyItem>
                {showLabelNotes ? 
                    <LabellerBodyItem header="Notes">
                        <LabelNotesInput
                            value={notes}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setNotes(e.target.value)
                            }
                        />
                    </LabellerBodyItem>
                    : null}
                <LabellerBodyItem header={<span>Tag Assets <PopoverBulkTagging/></span>}>
                    <AssetsChecklist
                        ref={checklistRef}
                        block={props.block}
                        range={props.range}
                        fetchLabels={fetchAssetIssueLabels}
                        onChange={setCheckedKeys}
                    />
                </LabellerBodyItem>
                <AukButton.Blue
                    className="my-3"
                    icon={<SaveFilled />}
                    onClick={handleSave}
                    disabled={checklistRef?.current ? (checklistRef.current as any).isLoading : false}
                >
          Save
                </AukButton.Blue>
            </LabellerBody>
        </BlockIssueLabeller>
    )
}

interface CreateProps extends SummaryProps, HeaderProps {
    block: BlockModel;
    onSubmit: AnyFunction;
}
