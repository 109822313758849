/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react"
import withBlock, { BlockRenderProps } from "../../lib/hoc/withBlock"
import { useDispatch } from "react-redux"
import useCurrentEntity from "../../hooks/useCurrentEntity"
import BlockModel from "../../api/models/block.model"
import { dataApiQueryParams } from "../../legacy/utils/controls"
import moment from "moment"
import { Permission } from "../../legacy/components/Permission"
import { useAppSelector } from "../../store/hooks"
import { SPAWrapper } from "../../legacy/components/SPAWrapper"
import BlockHeader from "./components/BlockHeader"
import { BlockConstants as K } from "../../store/old/UI/block/block.constants"
import OeeAggregateTab from "./components/OeeAggregateTab"
import OeeTimeSeriesTab from "./components/OeeTimeSeriesTab"
import OutputTimeSeriesTab from "./components/OutputTimeSeriesTab"
import { getBlockOee, getBlockOutput } from "../../store/old/UI/block/block.action"
import { BlockPageContext } from "./BlockPageContext"

const WITH_LABELS_RANGE = moment.duration(1, "week").as("seconds")

const BlockPage = (props: Props) => {
    const dispatch = useDispatch()
    const mode = useAppSelector(appState => appState.ui.block.mode)
    const block = props.block as BlockModel
    const getOeeData = (controls: any) => {
        const query = dataApiQueryParams(controls)
        const from = moment(query.date_range.lower)
        const to = moment(query.date_range.upper)
        const rangeDurationSeconds = moment.duration(to.diff(from)).as("seconds")
        const withLabels = rangeDurationSeconds <= WITH_LABELS_RANGE
        dispatch(getBlockOee(block.blockId, query, { withLabels }))
    }

    const getOutputData = (controls: any) => {
        const query = dataApiQueryParams(controls)
        const from = moment(query.date_range.lower)
        const to = moment(query.date_range.upper)
        const rangeDurationSeconds = moment.duration(to.diff(from)).as("seconds")
        const withLabels = rangeDurationSeconds <= WITH_LABELS_RANGE
        dispatch(getBlockOutput(block.blockId, query, { withLabels }))
    }

    return <BlockPageContext.Provider 
        value={{
            getOeeData,
            getOutputData
        }}
    >
        <Permission
            forBlock
            blockId={block.blockId} 
            resource={undefined} 
            forResource={undefined} 
            canDo={undefined}
        >
            <SPAWrapper className="block-page">
                <BlockHeader block={block}/>
                {
                    mode === K.VIEWS.OEE_AGGREGATED ?
                        <OeeAggregateTab key={K.VIEWS.OEE_AGGREGATED}/> : 
                        mode === K.VIEWS.OEE_TIME_SERIES ?
                            <OeeTimeSeriesTab key={K.VIEWS.OEE_TIME_SERIES}/> : 
                            <OutputTimeSeriesTab key={K.VIEWS.OUTPUT}/>
                }
            </SPAWrapper>
        </Permission>

    </BlockPageContext.Provider>
}

interface Props extends BlockRenderProps {}

export default withBlock(BlockPage)