import { Space } from 'antd';
import React from 'react';
import { NoData } from '../../../components/None';

const ReportNoData = () => {
    return (
        <Space className='w-100 h-100 d-flex justify-content-center align-items-center'>
            <NoData description="No data, please select report range and asset(s) to begin"/>;
        </Space>
    )
}

export default ReportNoData;