import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Checkbox, Form, Input, Select } from 'antd';

// CONSTANT
import CONSTANTS from '../../Constants';

// COMPONENT
import AukInputPhone from '../../components/AukInputPhone';
import AukButton from '../../components/AukButton';
import UserAgreement from './UserAgreement';
import { errorFlash, flash } from '../../components/Flash';
import { Logo } from '../../components/Logo';
import {
    Panel,
    PanelBody,
    PanelFooter,
} from '../../components/Panel';
import mainLogoSrc from '../../../assets/images/auk-logo-full.png';
import UserAppointmentSelect from './components/UserAppointmentSelect'

// HELPERS
import { decode, emailRegex, pwRegex } from '../../utils/helpers';

// ACTION
import {
    Authenticate,
    FetchUser,
    SignUp,
} from '../../../store/old/Authentication/Authentication.action';

// STYLE
import './Account.scss';
import translate from '../../utils/translate';
import UnauthLayout from '../../components/Layout/UnauthLayout';
import { fetchOrganizationRequest } from '../../../store/old/Entity/Entity.action';
import { isEmpty } from 'lodash';
import { api_requestInviteUser } from '../../../store/old/User/User.services';

const VALIDATE = {
    NAME_FIRST: [
        {
            required: true,
            message: 'First name is required.',
        },
        {
            whitespace: true,
            message: 'First name is required.',
        },
    ],
    NAME_LAST: [
        {
            required: true,
            message: 'Last name is required.',
        },
        {
            whitespace: true,
            message: 'Last name is required.',
        },
    ],
    EMAIL: [
        {
            required: true,
            message: 'Email is required.',
        },
        {
            whitespace: true,
            message: 'Email is required.',
        },
        (_form) => ({
            validator: (_, value) => {
                if (!value.match(emailRegex))
                    return Promise.reject(new Error('Must be a valid email address'));
                return Promise.resolve();
            },
        }),
    ],
    APPOINTMENT: [
        {
            required: true,
            message: 'Appointment is required.',
        },
    ],
    PHONE: [
        (_form) => ({
            validator: (_, value) => {
                if (!value.country_code && !value.mobile) return Promise.resolve();
                if (!value.country_code || !value.mobile) {
                    return Promise.reject(
                        new Error('Missing country code / Invalid phone number')
                    );
                }
                return Promise.resolve();
            },
        }),
    ],
    PASSWORD: [
        {
            required: true,
            message: 'Password is required',
        },
        {
            type: 'string',
            min: 8,
            message: 'Cannot be less than 8 characters',
        },
        {
            type: 'string',
            max: 20,
            message: 'Cannot be more than 20 characters',
        },
        (_form) => ({
            validator: (_, value) => {
                if (!value.match(pwRegex))
                    return Promise.reject(
                        new Error(
                            'Must contain at least 1 uppercase letter, 1 lowercase letter and 1 numeral'
                        )
                    );
                return Promise.resolve();
            },
        }),
    ],
    CONFIRM_PASSWORD: [
        {
            required: true,
            message: 'Please confirm your password',
        },
        (_form) => ({
            validator(_, value) {
                if (!value || _form.getFieldValue('password') === value) {
                    return Promise.resolve();
                }
                return Promise.reject(new Error('Passwords do not match'));
            },
        }),
    ],
    EULA: [
        {
            required: true,
            message: 'Please accept the user agreement',
        },
        (_form) => ({
            validator(_, value) {
                if (value) {
                    return Promise.resolve();
                }
                return Promise.reject(new Error('Please accept the user agreement'));
            },
        })
    ],
};

const Register = (props) => {
    const [params] = useSearchParams();
    const ref = params.get('ref') || ''


    // const [userId, email, inviteCode] = decode(ref).split('-');
    const splitArray = decode(ref).split('-');
    const userId = splitArray[0];
    const inviteCode = splitArray[splitArray.length - 1];
    const email = splitArray.slice(1, splitArray.length - 1).join('-');

    return (
        <UnauthLayout className="register-page">
            <Logo className="main-logo" src={mainLogoSrc} />
            <RegisterForm email={email} inviteCode={inviteCode}/>
        </UnauthLayout>
    );
};

export default Register;

const RegisterForm = (props) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const signUp = (formData, callback) => dispatch(SignUp(formData, callback));
    const fetchUser = (callback) => dispatch(FetchUser(callback));
    const authError = useSelector(appState => appState.auth.error)

    const signUpError = useMemo(() => {
        if (!isEmpty(authError)) {
            return authError.message === 'Invite code required'
        }

        return false;
    }, [authError])

    const [showEULA, setShowEULA] = useState(false);

    return (
        <Panel>
            <Form
                name="userSignupForm"
                id="userSignupForm"
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                className="user-signup-form"
                onFinish={() => {
                    const formData = form.getFieldsValue(true);
                    const data = {
                        name_first: formData.name_first,
                        name_last: formData.name_last,
                        email: formData.email.trim(),
                        appointment: formData.appointment,
                        password: formData.password,
                        confirm_password: formData.confirm_password,
                        country_code: formData.phone && formData.phone.country_code
                            ? formData.phone.country_code
                            : null,
                        mobile: formData.phone && formData.phone.mobile
                            ? formData.phone.mobile
                            : null,
                        invite_code: formData.invite_code,
                    };

                    signUp(data, () => {
                        flash({ message: 'Welcome!', status: 'success' });
                        fetchUser((newUser) => {
                            if (newUser.entity_id) {
                                dispatch(Authenticate({ 
                                    username: data.email,
                                    password: data.password
                                 }, () => {
                                    dispatch(fetchOrganizationRequest(newUser.entity_id));
                                    navigate(CONSTANTS.URLS.DASHBOARD)
                                }))
                                return;
                            }
                            
                            navigate(CONSTANTS.URLS.JOIN_COMPANY)
                        });
                    });
                }}
            >
                <PanelBody>
                    <Form.Item
                        colon={false}
                        name={'name_first'}
                        label={translate('firstname')}
                        rules={VALIDATE.NAME_FIRST}
                    >
                        <Input maxLength={50}/>
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        name={'name_last'}
                        label={translate('lastname')}
                        rules={VALIDATE.NAME_LAST}
                    >
                        <Input maxLength={50}/>
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label={translate('email')}
                        rules={VALIDATE.EMAIL}
                        initialValue={props.email || ''}
                    >
                        <Input autoComplete="off" disabled={props.email} maxLength={255}/>
                    </Form.Item>
                    <Form.Item
                        name="appointment"
                        label={translate('appointment')}
                        rules={VALIDATE.APPOINTMENT}
                    >
                        <UserAppointmentSelect/>
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        name={'phone'}
                        label={translate('mobile')}
                        rules={VALIDATE.PHONE}
                    >
                        <AukInputPhone />
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        name={'password'}
                        label={translate('password')}
                        rules={VALIDATE.PASSWORD}
                        hasFeedback
                    >
                        <Input.Password autoComplete="off" />
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        name="confirm"
                        label={translate('confirmpassword')}
                        dependencies={['password']}
                        hasFeedback
                        rules={VALIDATE.CONFIRM_PASSWORD}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        hidden={!signUpError}
                        colon={false}
                        label={"Invite Code"}
                        required
                    >
                        <Form.Item
                            colon={false}
                            name={'invite_code'}
                            initialValue={props.inviteCode}
                        >
                            <Input disabled={!signUpError}/>
                        </Form.Item>
                        <AukButton.Outlined 
                            onClick={async () => {
                                try {
                                    await api_requestInviteUser({ email: form.getFieldValue('email').trim() });
                                    flash({ message: 'Invite code sent to your email'})
                                } catch(e) {
                                    errorFlash(e)
                                }
                            }}
                        >
                            Send Invite Code
                        </AukButton.Outlined>
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        name="eula"
                        label=" "
                        valuePropName="checked"
                        rules={VALIDATE.EULA}
                    >
                        <Checkbox>
                            <span>
                                &nbsp;
                                {translate('iacceptthe')}
                                &nbsp;
                                <Link
                                    to="/usragr"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowEULA(true);
                                    }}
                                    className="card-link"
                                >
                                    {translate('useragreement')}
                                </Link>
                            </span>
                        </Checkbox>
                    </Form.Item>
                </PanelBody>
                <PanelFooter className="p-2">
                    <AukButton.Reset
                        className="mr-2"
                        onClick={() => form.resetFields()}
                    />
                    <AukButton.Save htmlType="submit" />
                </PanelFooter>
                <UserAgreement visible={showEULA} close={() => setShowEULA(false)} />
            </Form>
        </Panel>
    );
};
