import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { reportAccessors } from '../accessors';
import { ISSUE_SUMMARY_COLUMNS } from '../reportColumns';
import { getColumnFunctionMenu, OP, ABBREVIATE } from './FunctionMenu';
import { keys } from 'lodash';
import ReportSummaryTable, { getSummaryRecordColumn } from './ReportSummaryTable';
import { ReportConstants as K } from '../../../../store/old/UI/Report/Report.constants';

const IssueReportSummaryTable = () => {
    const issueData = useSelector(appState => appState.ui.report.issueData);
    const issueFilteredData = useSelector(appState => appState.ui.report.issueTableData.dataSource);

    const data = useMemo(() => issueFilteredData?.length ? issueFilteredData : issueData, [issueData, issueFilteredData]);
    
    const [operations, setOperations] = useState({});

    const columns = useMemo(() => {
        const recordsColumn = getSummaryRecordColumn(data.length);
        const optionalColumns = keys(ISSUE_SUMMARY_COLUMNS).map(c => {
            const tableCol = ISSUE_SUMMARY_COLUMNS[c];
            return {
                ...tableCol,
                title: operations[c] ? `${ABBREVIATE[operations[c]]} ${tableCol.title}` : `${ABBREVIATE[K.ISSUE_REPORT_FIELDS[c].defaultFn]} ${tableCol.title}`,
                filterDropdown: getColumnFunctionMenu({
                    onSelect: ({key}) => setOperations({...operations, [c]: key}),
                    items: K.ISSUE_REPORT_FIELDS[c].fnOptions
                })
            }
        });

        return [recordsColumn].concat(...optionalColumns);
    }, [data, operations]);

    const dataSource = useMemo(() => {
        return [keys(ISSUE_SUMMARY_COLUMNS).reduce((acc, curr) => {
            const fn = operations[curr] || K.ISSUE_REPORT_FIELDS[curr].defaultFn;
            acc[curr] = OP[fn](data, reportAccessors[curr]);
            return acc;
        }, {})];
    }, [data, operations]);

    if (data.length === 0) {
        return null;
    }
    
    return (
        <ReportSummaryTable
            columns={columns}
            dataSource={dataSource}
        />
    );
}

export default IssueReportSummaryTable;