import { DatePicker } from 'antd';
import React, { useState } from 'react';

const { RangePicker } = DatePicker;

const ALLOWED_RANGE = {
    X: 2,
    UNIT: 'months'
}

const ReportRangePicker = (props) => {
    const [dates, setDates] = useState(null);

    const disabledDate = (current) => {
        if (!dates) {
            return false;
        }

        const tooLate = dates[0] && current.diff(dates[0], ALLOWED_RANGE.UNIT) >= ALLOWED_RANGE.X;
        const tooEarly = dates[1] && dates[1].diff(current, ALLOWED_RANGE.UNIT) >= ALLOWED_RANGE.X;
        return !!tooEarly || !!tooLate;
    };

    return (
        <RangePicker
            onOpenChange={(open) => { 
                if (open) {
                    setDates([null, null]);
                } else {
                    setDates(null);
                }
             }}
            onCalendarChange={(val) => { setDates(val) }}
            disabledDate={disabledDate}
            {...props}
            value={dates || props.value}
        />
    );
}

export default ReportRangePicker;