import moment from "moment"
import { callBlockReportsDestroy, callBlockReportsIndex, callBlockReportsPreview, callBlockReportsShow, callBlockReportsStore, callBlockReportsUpdate, callEntityReportsIndex } from "../../api"
import type ImpactReportModel from "../../api/models/impact_report.model"
import { createAppAsyncThunk, type RootState } from "../index"
import { clear, set, add, setLoading, view, remove } from "../reducers/impact_reports.reducer"
import { snacksErrorMessage } from "./snacks.action"
import ImpactReportTransformer from "../../api/transformers/impact_report.transformer"
import { flash } from "../../legacy/components/Flash"

export const entityReportsGetAction = createAppAsyncThunk<Promise<void>, GetEntityReportsPayload>(
    "reports/get",
    async ({ entityId }, { dispatch }) => {
        void dispatch(clear())
        void dispatch(setLoading(true))

        try {
            const { data }: { data: ImpactReportModel[] } = await callEntityReportsIndex(entityId, {
                report_type: "roi",
            })

            void dispatch(set(data.map(d => ImpactReportTransformer(d))))
        } catch (e: any) {
            void dispatch(snacksErrorMessage(e.response?.data?.message ?? e.toString()))
        } finally {
            void dispatch(setLoading(false))
        }
    })

interface GetEntityReportsPayload {
    entityId: number
}

export const reportsGetAction = createAppAsyncThunk<Promise<void>, GetImpactReportPayload>(
    "reports/get",
    async ({ entityId, blockId }, { dispatch }) => {
        void dispatch(clear())
        void dispatch(setLoading(true))

        try {
            const { data }: { data: ImpactReportModel[] } = await callBlockReportsIndex(entityId, blockId, {
                report_type: "roi",
            })

            void dispatch(set(data.map(d => ImpactReportTransformer(d))))
            if (data.length !== 0) {
                const { data: report } = await callBlockReportsShow(entityId, blockId, data[0].id)
                void dispatch(view(ImpactReportTransformer(report)))
            }
        } catch (e: any) {
            void dispatch(snacksErrorMessage(e.response?.data?.message ?? e.toString()))
        } finally {
            void dispatch(setLoading(false))
        }
    })

interface GetImpactReportPayload {
    entityId: number
    blockId: number
}

export const reportsShowAction = createAppAsyncThunk<Promise<void>, ShowImpactReportPayload>(
    "reports/get",
    async ({ entityId, blockId, reportId }, { dispatch }) => {
        void dispatch(setLoading(true))

        try {
            const { data: report } = await callBlockReportsShow(entityId, blockId, reportId)
            void dispatch(view(ImpactReportTransformer(report)))
        } catch (e: any) {
            void dispatch(snacksErrorMessage(e.response?.data?.message ?? e.toString()))
        } finally {
            void dispatch(setLoading(false))
        }
    })

interface ShowImpactReportPayload {
    entityId: number
    blockId: number
    reportId: number
}

export const reportsSaveAction = createAppAsyncThunk<Promise<void>, SaveImpactReportPayload, { state: RootState }>(
    "reports/save",
    async ({ entityId, blockId, reportName, startsAt, period, variables }, { dispatch }) => {
        void dispatch(setLoading(true))

        try {
            const endsAt = startsAt.clone().add(1, period)
            const { data } = await callBlockReportsStore(entityId, blockId, {
                report_type: "roi",
                starts_at: startsAt.toISOString(),
                ends_at: endsAt.toISOString(),
                report_name: reportName,
                variables: {
                    unit_of_time: period,
                    ...variables
                },
            })

            const report = ImpactReportTransformer(data)
            dispatch(add([report]))
            void dispatch(view(report))
            flash({ message: "Report saved" })
        } catch (e: any) {
            void dispatch(snacksErrorMessage(e.response?.data?.message ?? e.toString()))
        } finally {
            void dispatch(setLoading(false))
        }
    })

export const reportsUpdateAction = createAppAsyncThunk<Promise<void>, SaveImpactReportPayload, { state: RootState }>(
    "reports/update",
    async ({ entityId, blockId, reportId, reportName, startsAt, period, variables }, { dispatch }) => {
        void dispatch(setLoading(true))

        try {
            const endsAt = startsAt.clone().add(1, period)
            const { data } = await callBlockReportsUpdate(entityId, blockId, reportId as number, {
                report_type: "roi",
                starts_at: startsAt.toISOString(),
                ends_at: endsAt.toISOString(),
                report_name: reportName,
                variables: {
                    unit_of_time: period,
                    ...variables
                },
            })

            const report = ImpactReportTransformer(data)
            dispatch(add([report]))
            void dispatch(view(report))
            flash({ message: "Report updated" })
        } catch (e: any) {
            void dispatch(snacksErrorMessage(e.response?.data?.message ?? e.toString()))
        } finally {
            void dispatch(setLoading(false))
        }
    })

export interface SaveImpactReportPayload {
    entityId: number
    blockId: number
    reportId?: number
    reportName: string
    startsAt: moment.Moment
    period: moment.unitOfTime.Base
    variables?: ImpactReportVariablesPayload
}

export interface ImpactReportVariablesPayload {
    cost_per_operating_hour?: number
    operating_hours_year?: number
    margin_per_piece?: number
    output?: number
    benchmark?: number
    currency?: string
    current?: { starts_at: string, ends_at: string }
}

export const reportsPreviewAction = createAppAsyncThunk<Promise<void>, PreviewImpactReportPayload>(
    "reports/preview",
    async ({ entityId, blockId, startsAt, period, variables = {} }, { dispatch }) => {
        void dispatch(setLoading(true))
        try {
            const now = moment()
            const endsAt = startsAt.clone().add(1, period)
            if (endsAt.isAfter(now)) {
                void dispatch(snacksErrorMessage("Cannot load future date"))
                return
            }

            if (variables.current) {
                const currentEndsAt = moment(variables.current?.ends_at)
                if (currentEndsAt.isAfter(now)) {
                    void dispatch(snacksErrorMessage("Cannot load future date"))
                    return
                }
            }

            const { data } = await callBlockReportsPreview(entityId, blockId, {
                report_type: "roi",
                starts_at: startsAt.toISOString(),
                ends_at: endsAt.toISOString(),
                variables: {
                    unit_of_time: period,
                    ...variables
                },
            })
            void dispatch(view(ImpactReportTransformer(data)))
        } catch (e: any) {
            void dispatch(snacksErrorMessage(e.response?.data?.message ?? e.toString()))
        } finally {
            void dispatch(setLoading(false))
        }
    })

interface PreviewImpactReportPayload {
    entityId: number
    blockId: number
    startsAt: moment.Moment
    period: moment.unitOfTime.Base
    variables?: ImpactReportVariablesPayload
}

export const reportsClearAction = createAppAsyncThunk<Promise<void>>(
    "reports/clear",
    async (_, { dispatch }) => {
        void dispatch(clear())
    }
)

export const reportsDestroyAction = createAppAsyncThunk<Promise<void>, RemoveImpactReportPayload>(
    "reports/destroy",
    async ({ entityId, blockId, reportId }, { dispatch }) => {
        await callBlockReportsDestroy(entityId, blockId, reportId)
        void dispatch(remove(reportId))
        flash({ message: "Report deleted" })
    }
)

interface RemoveImpactReportPayload {
    entityId: number
    blockId: number
    reportId: number
}
