/* eslint-disable new-cap */
/* eslint-disable no-use-before-define */
/* eslint-disable one-var */
/* eslint-disable prefer-template */
import React from 'react'
import moment from 'moment';

import CONSTANTS from '../Constants';

export const nameRegex = /^[\w- ]+$/;
export const emailRegex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
export const pwRegex = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20})/;
const isoStringRegex =
  /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})((Z)?)/gim;

export const uiReadableDatetimeFormat = 'dddd, D MMM YYYY, H:mm A';
export const uiReadableDateFormat = 'D MMM YYYY';
export const uiDateFormat = 'YYYY/MM/DD';
export const uiDatetimeFormat = 'YYYY/MM/DD HH:mm';
export const uiDatetimeFormatWithSeconds = 'YYYY/MM/DD HH:mm:ss';
export const csvDatetimeFormat = 'YYYY-MM-DD HH:mm:ss';
export const fileDatetimeFormat = 'YYYY-MM-DDTHH:mm:ss';

export const formatValidNumber = (input, fallback = '-') => {
    if (isFinite(input)) return input;
    return fallback;
}

export const toLocaleDecimals = (value, dp = 2) => value.toLocaleString(undefined, {
    minimumFractionDigits: dp,
    maximumFractionDigits: dp
  })

export const localizeTime = (str, tz = '') => {
    const words = str.split(' ');

    return words
        .map((word) =>
            word.match(isoStringRegex) ? isoStringToLocalTime(word, tz) : word
        )
        .join(' ');
};

export const isoStringToLocalTime = (isoString, tz = '') => {
    return isoString.replace(isoStringRegex, (match) => moment(match).toString());
};

export function generateId() {
    return Math.random().toString(36).substr(2, 15);
}

export const numberToOrdinal = (n) => {
    // https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number (Tomas Langkaas)
    const convertToWord = {
        1: 'first',
        2: 'second',
        3: 'third',
        4: 'fourth',
        5: 'fifth',
        6: 'sixth',
        7: 'seventh',
        8: 'eighth',
        9: 'ninth',
        10: 'tenth',
    };
    if (n <= 10) return convertToWord[n];
    return `${n}${
        ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th'
    }`;
};

export const regexEscape = (str) => {
    return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

export const regexMatch = (str, strToMatch, options = {}) => {
    const flag = _.has(options, 'flag') ? options.flag : 'gi';
    strToMatch = _.has(options, 'escape')
        ? strToMatch.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
        : strToMatch;

    const regex = new RegExp(strToMatch, flag);
    return str.match(regex);
};

export const evalViewport = (
    breakpoint = CONSTANTS.VIEWPORTS.BREAKPOINTS.DEFAULT
) => {
    let containerWidth = document.querySelector('#root').clientWidth;

    if (containerWidth < breakpoint) {
        return true;
    }
    return false;
};

export const getDataSubarrByTime = (dataset = [], from, to) => {
    from = moment(from);
    to = moment(to);

    return dataset.filter((d) => moment(d.time).isBetween(from, to, null, '[)'));
};

/* generate map of a particular collection */
export const getMapFromArr = (arr, assessor) => {
    return arr.reduce((acc, curr) => ({ ...acc, [curr[assessor]]: curr }), {});
};

/* flattens a tree, returns an array of nodes */
export const flattenTree = (branch, childAccessor = (b) => b.children) => {
    let nodeArray = [];
    nodeArray.push(branch);

    const children = childAccessor(branch) || [];

    if (children.length) {
        const childNodes = children.map((subBranches) =>
            flattenTree(subBranches, childAccessor)
        );
        nodeArray = nodeArray.concat(...childNodes);
    }

    return nodeArray;
};

export const depthFirstTraversal = (node, callback) => {
    let result = [node];
    callback && callback(node);
    if (!node.children || !node.children.length) return result;
    if (node.children) {
        result = node.children.reduce((acc, curr) => {
            return acc.concat(...depthFirstTraversal(curr, callback));
        }, result);
    }
    return result;
};

export function findParentAt(
    map,
    nodeHierarchyAccessor,
    nodeParentAccessor,
    destinationLevel
) {
    const recurseUpwards = (node) => {
        const hl = nodeHierarchyAccessor(node);
        if (hl < destinationLevel) return;
        if (hl === destinationLevel) return node;
        return recurseUpwards(map[nodeParentAccessor(node)]);
    };
    return recurseUpwards(this);
}

/* gets all nodes nested within given node */
export const getAllChildNodes = (node, childAccessor = (n) => n.children) => {
    return childAccessor(node).reduce((acc, curr) => {
        return acc.concat(getAllChildNodes(curr, childAccessor));
    }, childAccessor(node));
};

/* finds all leaves in a given node */
export const getAllLeaves = (node, leafAccessor) => {
    const leaves = [];

    if (!leafAccessor(node)) {
        return leaves.concat(
            ...node.children.map((child) => getAllLeaves(child, leafAccessor))
        );
    }

    return leaves.concat(node);
};

/*  tree bfs */
export const breadthFirstSearch = (nodeArr = [], assessor) => {
    if (!nodeArr.length) return null;

    const nextDepth = [];

    for (let i = 0; i < nodeArr.length; i++) {
        const node = nodeArr[i];
        if (assessor(node)) {
            return node;
        } else {
            node.children && nextDepth.push(...node.children);
        }
    }

    return breadthFirstSearch(nextDepth, assessor);
};

export const defaultFilterOption = (input, option) =>
    regexMatch(option.label, input, { escape: true });

export const getForeignKeyAssociations = (
    models,
    primaryKeyAssessor,
    foreignKeyAssessor
) => {
    return models.reduce((acc, curr) => {
        const pk = primaryKeyAssessor(curr);
        const fk = foreignKeyAssessor(curr);
        const result = acc[fk] ? acc[fk].concat(pk) : [pk];
        return { ...acc, [fk]: result };
    }, {});
};

export const toMappedArrays = (array, keyAccessor, valueAccessor) => {
    return array.reduce((acc, curr) => {
        const key = keyAccessor(curr);
        const value = valueAccessor(curr);
        return {
            ...acc,
            [key]: acc[key] ? acc[key].concat(value) : [value],
        };
    }, {});
};

export const sortAscAlphabet = (a, b, accessor) => {
    const first = accessor(a);
    const second = accessor(b)
    if (first < second) {
        return -1;
      }
      if (first > second) {
        return 1;
      }
      return 0;
}

export const decode = (str) => {
    return Buffer.from(str, 'base64').toString('utf-8')
}
