import { all, call, put, takeLatest } from 'redux-saga/effects';

// // CONSTANT
import { ReportConstants as K } from './Report.constants';
import { watcherBuilder } from '../../Base/Base.saga';
import { errorFlash } from '../../../../legacy/components/Flash';
import { store } from '../../..';
import { currentEntitySelector } from '../../Entity/Entity.selector';
import { api_getBlockOEEData } from '../../Data/Data.services';
import { setIssueReportData, setIssueTableData, setOeeReportData, setOeeTableData, setSkuReportData, setSkuTableData } from './Report.action';
import { OEEFactory } from '../../../../legacy/utils/oee';
import { validateDateTimeRange } from '../../../../legacy/utils/validate';
import { api_getAssetLabels } from '../../Labels/Labels.services';
import moment from 'moment';
import { flatten } from 'lodash';
import { Label, parseLabelArguments } from '../../../../legacy/models';
import { filterLabelByTypeId } from '../../../../legacy/utils/labels';

const reportTimeSeriesFactory = (blockId, queryRange, oee) => {
    return {
        blockId,
        start: queryRange.lower,
        end: queryRange.upper,
        oee: OEEFactory(oee)
    }
}

const reportSkuSeriesFactory = (blockId, queryRange, oee, label) => {
    return {
        blockId,
        start: queryRange[0],
        end: queryRange[1],
        oee: OEEFactory(oee),
        label: new Label(...parseLabelArguments(label))
    }
}

const reportIssueSeriesFactory = (blockId, queryRange, oee, label) => {
    return {
        blockId,
        start: queryRange[0],
        end: queryRange[1],
        oee: OEEFactory(oee),
        label: new Label(...parseLabelArguments(label))
    }
}

function* fetchDataOnOeeFilterChange(action) {
    try {
        yield put(setOeeTableData(null, []));

        const appState = store.getState();
        const entity = currentEntitySelector(appState);
        const sku_oee = appState.ui.controls.sku_oee;

        const requests = generateOeeRowQueries(entity, action.payload.oeeFilter, { sku_oee });

        const promises = requests.map(req => call(api_getBlockOEEData,entity.entity_id, req.blockId, req.query));

        const result = yield all(promises);

        yield put(setOeeReportData(
            requests.map((request, index) => reportTimeSeriesFactory(result[index].block_id, request.query.date_range, result[index]))
        ));
    } catch (error) {
        errorFlash(error);
    }
}

export function* watchOeeFilterChangeSaga() {
    yield watcherBuilder(
        K.ACTIONS.SET_OEE_REPORT_FILTER,
        fetchDataOnOeeFilterChange
    );
}

function* handleRefreshOeeReportData(action) {
    try {
        yield put(setOeeTableData(null, []));

        const appState = store.getState();
        const entity = currentEntitySelector(appState);
        const { sku_oee } = action.payload.options || {};

        const requests = generateOeeRowQueries(entity, appState.ui.report.oeeFilter, { sku_oee });

        const promises = requests.map(req => call(api_getBlockOEEData, entity.entity_id, req.blockId, req.query));

        const result = yield all(promises);

        yield put(setOeeReportData(
            requests.map((request, index) => reportTimeSeriesFactory(result[index].block_id, request.query.date_range, result[index]))
        ));
    } catch (error) {
        errorFlash(error);
    }
}

export function* handleRefreshOeeReportDataSaga() {
    yield takeLatest(
        K.ACTIONS.REFRESH_OEE_REPORT_DATA,
        handleRefreshOeeReportData
    );
}

function* fetchDataOnSkuFilterChange(action) {
    try {
        yield put(setSkuTableData(null, []));

        const appState = store.getState();
        const entity = currentEntitySelector(appState);
        const sku_oee = appState.ui.controls.sku_oee;

        const skuReportData = yield fetchSkuReportData(entity, action.payload.skuFilter, { sku_oee });

        yield put(setSkuReportData(skuReportData))
    } catch (error) {
        errorFlash(error);
    }
}

export function* watchSkuFilterChangeSaga() {
    yield watcherBuilder(
        K.ACTIONS.SET_SKU_REPORT_FILTER,
        fetchDataOnSkuFilterChange
    );
}

function* handleRefreshSkuReportData(action) {
    try {
        yield put(setSkuTableData(null, []));

        const appState = store.getState();
        const entity = currentEntitySelector(appState);
        const { sku_oee } = action.payload.options || {};

        const skuReportData = yield fetchSkuReportData(entity, appState.ui.report.skuFilter, { sku_oee });

        yield put(setSkuReportData(skuReportData))
    } catch (error) {
        errorFlash(error);
    }
}

export function* handleRefreshSkuReportDataSaga() {
    yield takeLatest(
        K.ACTIONS.REFRESH_SKU_REPORT_DATA,
        handleRefreshSkuReportData
    );
}

function* fetchDataOnIssueFilterChange(action) {
    try {
        yield put(setIssueTableData(null, []));

        const appState = store.getState();
        const entity = currentEntitySelector(appState);
        const sku_oee = appState.ui.controls.sku_oee;

        const issueReportData = yield fetchIssueReportData(entity, action.payload.issueFilter, { sku_oee });

        yield put(setIssueReportData(issueReportData))
    } catch (error) {
        errorFlash(error);
    }
}

export function* watchIssueFilterChangeSaga() {
    yield watcherBuilder(
        K.ACTIONS.SET_ISSUE_REPORT_FILTER,
        fetchDataOnIssueFilterChange
    );
}

const generateOeeRowQueries = (entityModel, reportFilterState, options = {}) => {
    const { dateRange, period, blockIds, timeRange } = reportFilterState;
    let [rangeStart, rangeEnd] = dateRange;
    let queryRanges = [];

    while (rangeStart.isBefore(rangeEnd)) {
        let start, end;
        if (validateDateTimeRange(timeRange)) {
            const startHour = timeRange[0].hour();
            const startMinute = timeRange[0].minute();
            const endHour = timeRange[1].hour();
            const endMinute = timeRange[1].minute();

            start = rangeStart.clone().set({
                hour: startHour,
                minute: startMinute,
            });

            end = rangeStart.clone().set({
                hour: endHour,
                minute: endMinute,
            });

            end = start.isBefore(end) ? end : end.add('1', 'day');
            
            queryRanges.push([start, end]);
        } else {
            start = rangeStart.clone();
            end = rangeStart.clone().add(1, period);
            queryRanges.push([
                entityModel.getStartOfDay(start).toISOString(),
                entityModel.getStartOfDay(end).toISOString()
            ]);
        }

        rangeStart = rangeStart.clone().add(1, period);
    }

    const queryParams = queryRanges.map(([lower, upper]) => {
        return {
            date_range: { lower, upper },
            res_x: 1,
            res_period: 'hours',
            sku_oee: options.sku_oee || false
        }
    });

    return blockIds.reduce((acc, blockId) => {
        return acc.concat(...queryParams.map(query => ({ blockId, query })))
    }, []);
}

function* fetchSkuReportData(entityModel, skuFilterState, options = {}) {
    const appState = store.getState();
    const blocksResource = appState.blocks.blocks;

    const { blockIds, skuIds, dateRange } = skuFilterState;

    const skuIdsSet = new Set(skuIds);
    const assetIdToBlockId = blockIds.reduce((acc, curr) => {
        return { ...acc, [blocksResource[curr].asset_id]: curr}
    }, {});

    const labelsPromises = yield all(
        blockIds.map(blockId => call(api_getAssetLabels, entityModel.entity_id, blocksResource[blockId].asset_id, {
            types: ["sku"],
            date_range: {
                lower: entityModel.getStartOfDay(moment(dateRange[0])).toISOString(),
                upper: entityModel.getStartOfDay(moment(dateRange[1])).toISOString()
            }
        })
    ));

    const labels = flatten(labelsPromises).filter(l => filterLabelByTypeId(l, 'sku', (v) => {
        return skuIdsSet.has(v.sku_id);
    }));

    const result = yield all(
        labels.map(l => call(api_getBlockOEEData, entityModel.entity_id, assetIdToBlockId[l.asset_id], {
            date_range: {
                lower: l.from,
                upper: l.to
            },
            res_x: 1,
            res_period: 'hours',
            sku_oee: options.sku_oee || false
        })
    ));
    
    return labels.map((l, index) => reportSkuSeriesFactory(result[index].block_id, [l.from, l.to], result[index], l));
}

function* fetchIssueReportData(entityModel, issueFilterState, options = {}) {
    const appState = store.getState();
    const blocksResource = appState.blocks.blocks;

    const { blockIds, issueId, dateRange } = issueFilterState;

    const assetIdToBlockId = blockIds.reduce((acc, curr) => {
        return { ...acc, [blocksResource[curr].asset_id]: curr}
    }, {});

    const labelsPromises = yield all(
        blockIds.map(blockId => call(api_getAssetLabels, entityModel.entity_id, blocksResource[blockId].asset_id, {
            types: ["issue"],
            date_range: {
                lower: entityModel.getStartOfDay(moment(dateRange[0])).toISOString(),
                upper: entityModel.getStartOfDay(moment(dateRange[1])).toISOString()
            }
        })
    ));

    const labels = flatten(labelsPromises).filter(l => filterLabelByTypeId(l, 'issue', (v) => {
        return v.issue_id === issueId
    }));

    const result = yield all(
        labels.map(l => call(api_getBlockOEEData, entityModel.entity_id, assetIdToBlockId[l.asset_id], {
            date_range: {
                lower: l.from,
                upper: l.to
            },
            res_x: 1,
            res_period: 'hours',
            sku_oee: options.sku_oee || false
        })
    ));
    
    return labels.map((l, index) => reportIssueSeriesFactory(result[index].block_id, [l.from, l.to], result[index], l));
}