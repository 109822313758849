import { put, takeLatest } from 'redux-saga/effects';
import { watcherBuilder } from '../../Base/Base.saga';

import { utilizationUpdateResolution } from './Utilization.action';
import { utilizationResolutions } from './Utilization.selector';
import { currentEntitySelector } from '../../Entity/Entity.selector';
import { UtilizationConstants as K } from './Utilization.constants';
import { errorFlash, flash } from '../../../../legacy/components/Flash';
import { clearDataResource, fetchBlockOeeData } from '../../Data/Data.action';
import { store } from '../../..';

function isValidUtilizationFetchDataRequest() {
    const {
        ui: {
            utilization: {
                resolution,
                date_range: [start, end],
            },
        },
    } = store.getState();

    return resolution && start.isSameOrBefore(end);
}

function* handleUtilizationFetchDataRequest(action) {
    try {
        const { block_id } = action.payload;

        if (!block_id) return;
        if (!isValidUtilizationFetchDataRequest()) {
            yield put(clearDataResource(['blocks']));
            return flash({
                message: 'Invalid range/resolution selection',
                status: 'error',
            });
        }

        const appState = store.getState();
        const entity = currentEntitySelector(appState);
        // const { tzStartDifference, entity_id } = currentEntitySelector(appState);

        const {
            ui: {
                utilization: {
                    resolution: { res_x, res_period },
                    date_range: [start, end],
                },
                controls: { sku_oee },
            },
        } = appState;

        const date_range = {
            // lower: start.utcOffset(tzStartDifference).startOf('day').toISOString(),
            // upper: end.utcOffset(tzStartDifference).startOf('day').toISOString(),
            lower: entity.getStartOfDay(start).toISOString(),
            upper: entity.getStartOfDay(end).toISOString(),
        };
        const query = { res_x, res_period, date_range, sku_oee };

        yield put(fetchBlockOeeData(entity.entity_id, block_id, query));
        if (action.callback) {
            action.callback();
        }
    } catch (e) {
        errorFlash(e);
    }
}

export function* utilizationGetDataRequest() {
    yield takeLatest(
        K.ACTIONS.UTILIZATION_FETCH_DATA_REQUEST,
        handleUtilizationFetchDataRequest
    );
}

function* handleResolutionUpdate(action) {
    const { date_range } = action.payload;
    const { resolution: resCurrent } = store.getState().ui.utilization;
    const resolutions = utilizationResolutions(date_range);

    const resolution = resolutions.find(
        (r) => resCurrent && r.res_period === resCurrent.res_period
    );

    if (resolution && !resolution.disabled) return;

    const alternatives = resolutions.filter((r) => !r.disabled);

    if (alternatives.length) {
        yield put(utilizationUpdateResolution(alternatives[0]));
    } else {
        yield put(utilizationUpdateResolution(null));
    }
}

export function* watchHeatmapResolutionUpdateSaga() {
    yield watcherBuilder(
        K.ACTIONS.UTILIZATION_UPDATE_RANGE,
        handleResolutionUpdate
    );
}
