export const SUMMARY_FN = {
    SUM: 'SUM',
    AVERAGE: 'AVERAGE',
    MIN: 'MIN',
    MAX: 'MAX'
};

export const ReportConstants = {
    ACTIONS: {
        SET_OEE_REPORT_FILTER: 'SET_OEE_REPORT_FILTER',
        SET_OEE_FILTERED_DATA: 'SET_OEE_FILTERED_DATA',
        SET_OEE_TABLE_DATA: 'SET_OEE_TABLE_DATA',
        REFRESH_OEE_REPORT_DATA: 'REFRESH_OEE_REPORT_DATA',
        SET_OEE_REPORT_COLUMNS: 'SET_OEE_REPORT_COLUMNS',
        SET_OEE_REPORT_DATA: 'SET_OEE_REPORT_DATA',
        SET_SKU_REPORT_FILTER: 'SET_SKU_REPORT_FILTER',
        SET_SKU_FILTERED_DATA: 'SET_SKU_FILTERED_DATA',
        SET_SKU_TABLE_DATA: 'SET_SKU_TABLE_DATA',
        REFRESH_SKU_REPORT_DATA: 'REFRESH_SKU_REPORT_DATA',
        SET_SKU_REPORT_COLUMNS: 'SET_SKU_REPORT_COLUMNS',
        SET_SKU_REPORT_DATA: 'SET_SKU_REPORT_DATA',
        SET_ISSUE_REPORT_FILTER: "SET_ISSUE_REPORT_FILTER",
        SET_ISSUE_FILTERED_DATA: 'SET_ISSUE_FILTERED_DATA',
        SET_ISSUE_TABLE_DATA: 'SET_ISSUE_TABLE_DATA',
        SET_ISSUE_REPORT_DATA: "SET_ISSUE_REPORT_DATA",
        REFRESH_ISSUE_REPORT_DATA: "REFRESH_ISSUE_REPORT_DATA",
    },
    REPORT_FIELDS: {
        OEE1: {
            label: 'OEE-1',
            key: 'OEE1',
            unit: '',
            defaultFn: SUMMARY_FN.AVERAGE,
            fnOptions: [SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        OEE2: {
            label: 'OEE-2',
            key: 'OEE2',
            unit: '',
            defaultFn: SUMMARY_FN.AVERAGE,
            fnOptions: [SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        OUTPUT: {
            label: 'Output',
            key: 'OUTPUT',
            unit: 'pcs',
            defaultFn: SUMMARY_FN.SUM,
            fnOptions: [SUMMARY_FN.SUM, SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        DEFECTS: {
            label: 'Defects',
            key: 'DEFECTS',
            unit: 'pcs',
            defaultFn: SUMMARY_FN.SUM,
            fnOptions: [SUMMARY_FN.SUM, SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        PRODUCTION_SPEED: {
            label: 'Production Speed',
            key: 'PRODUCTION_SPEED',
            unit: 'pcs/min',
            defaultFn: SUMMARY_FN.AVERAGE,
            fnOptions: [SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        CYCLE_TIME: {
            label: 'Cycle Time',
            key: 'CYCLE_TIME',
            unit: 'min/pc',
            defaultFn: SUMMARY_FN.AVERAGE,
            fnOptions: [SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        MTBF: {
            label: 'MTBF',
            key: 'MTBF',
            unit: 'hours',
            defaultFn: SUMMARY_FN.AVERAGE,
            fnOptions: [SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        MTTR: {
            label: 'MTTR',
            key: 'MTTR',
            unit: 'hours',
            defaultFn: SUMMARY_FN.AVERAGE,
            fnOptions: [SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        CALENDAR_TIME: {
            label: 'Calendar Time',
            key: 'CALENDAR_TIME',
            unit: 'hours',
            defaultFn: SUMMARY_FN.SUM,
            fnOptions: [SUMMARY_FN.SUM, SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        SCHEDULED_PRODUCTION_TIME: {
            label: 'Scheduled Production Time',
            key: 'SCHEDULED_PRODUCTION_TIME',
            unit: 'hours',
            defaultFn: SUMMARY_FN.SUM,
            fnOptions: [SUMMARY_FN.SUM, SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        GROSS_OPERATING_TIME: {
            label: 'Gross Operating Time',
            key: 'GROSS_OPERATING_TIME',
            unit: 'hours',
            defaultFn: SUMMARY_FN.SUM,
            fnOptions: [SUMMARY_FN.SUM, SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        NET_OPERATING_TIME: {
            label: 'Net Operating Time',
            key: 'NET_OPERATING_TIME',
            unit: 'hours',
            defaultFn: SUMMARY_FN.SUM,
            fnOptions: [SUMMARY_FN.SUM, SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        EFFECTIVE_TIME: {
            label: 'Effective Time',
            key: 'EFFECTIVE_TIME',
            unit: 'hours',
            defaultFn: SUMMARY_FN.SUM,
            fnOptions: [SUMMARY_FN.SUM, SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        UNUTILISED: {
            label: 'Un-utilized',
            key: 'UNUTILISED',
            unit: 'hours',
            defaultFn: SUMMARY_FN.SUM,
            fnOptions: [SUMMARY_FN.SUM, SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        BREAKDOWNS: {
            label: 'Breakdowns',
            key: 'BREAKDOWNS',
            unit: 'hours',
            defaultFn: SUMMARY_FN.SUM,
            fnOptions: [SUMMARY_FN.SUM, SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        SETUP: {
            label: 'Setup & others',
            key: 'SETUP',
            unit: 'hours',
            defaultFn: SUMMARY_FN.SUM,
            fnOptions: [SUMMARY_FN.SUM, SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        MINOR_STOPS: {
            label: 'Minor Stops',
            key: 'MINOR_STOPS',
            unit: 'hours',
            defaultFn: SUMMARY_FN.SUM,
            fnOptions: [SUMMARY_FN.SUM, SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        SPEED_LOSS: {
            label: 'Speed Loss',
            key: 'SPEED_LOSS',
            unit: 'hours',
            defaultFn: SUMMARY_FN.SUM,
            fnOptions: [SUMMARY_FN.SUM, SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        REJECTS: {
            label: 'Rejects',
            key: 'REJECTS',
            unit: 'hours',
            defaultFn: SUMMARY_FN.SUM,
            fnOptions: [SUMMARY_FN.SUM, SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        REWORK: {
            label: 'Rework',
            key: 'REWORK',
            unit: 'hours',
            defaultFn: SUMMARY_FN.SUM,
            fnOptions: [SUMMARY_FN.SUM, SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        NO_DATA: {
            label: 'No Data',
            key: 'NO_DATA',
            unit: 'hours',
            defaultFn: SUMMARY_FN.SUM,
            fnOptions: [SUMMARY_FN.SUM, SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        UNSCHEDULED: {
            label: 'Unscheduled',
            key: 'UNSCHEDULED',
            unit: 'hours',
            defaultFn: SUMMARY_FN.SUM,
            fnOptions: [SUMMARY_FN.SUM, SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        PLANNED_DOWNTIME: {
            label: 'Planned Downtime',
            key: 'PLANNED_DOWNTIME',
            unit: 'hours',
            defaultFn: SUMMARY_FN.SUM,
            fnOptions: [SUMMARY_FN.SUM, SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        LOA_LOSS: {
            label: 'Loading Factor',
            key: 'LOA_LOSS',
            defaultFn: SUMMARY_FN.AVERAGE,
            fnOptions: [SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        AVA_LOSS: {
            label: 'Availability Factor',
            key: 'AVA_LOSS',
            defaultFn: SUMMARY_FN.AVERAGE,
            fnOptions: [SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        PER_LOSS: {
            label: 'Performance Factor',
            key: 'PER_LOSS',
            defaultFn: SUMMARY_FN.AVERAGE,
            fnOptions: [SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        QUA_LOSS: {
            label: 'Quality Factor',
            key: 'QUA_LOSS',
            fixed: 'right',
            defaultFn: SUMMARY_FN.AVERAGE,
            fnOptions: [SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        }
    },
    ISSUE_REPORT_FIELDS: {
        LABEL_DURATION: {
            label: 'Duration',
            key: 'LABEL_DURATION',
            unit: 'hours',
            defaultFn: SUMMARY_FN.SUM,
            fnOptions: [SUMMARY_FN.SUM, SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        },
        EFFECTIVE_TIME: {
            label: 'Effective Time',
            key: 'EFFECTIVE_TIME',
            unit: 'hours',
            defaultFn: SUMMARY_FN.SUM,
            fnOptions: [SUMMARY_FN.SUM, SUMMARY_FN.AVERAGE, SUMMARY_FN.MIN, SUMMARY_FN.MAX]
        }
    }
};
