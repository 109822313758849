import React, { useMemo } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import './TimeReportTable.scss'
import { AutoSizer } from 'react-virtualized';
import ReportNoData from './ReportNoData';
import { reportAccessors } from '../accessors';
import { getRecordLabel, REPORT_COLUMNS } from '../reportColumns';
import { generateUrlQuery } from '../../../utils/url';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import CONSTANTS from '../../../Constants';
import { CUSTOM_SPAN } from '../../../../store/old/UI/Controls/Controls.constants';
import { setOeeTableData } from '../../../../store/old/UI/Report/Report.action';

const TimeBasedReportTable = () => {
    const dispatch = useDispatch();
    const reportStore = useSelector(appState => appState.ui.report);
    const blocksResource = useSelector(appState => appState.blocks.blocks);

    const columns = useMemo(() => {
        const selectedAssets = reportStore.oeeFilter.blockIds.map(id => blocksResource[id].asset);
        const defaultColumns = [
            {
                title: 'Asset',
                dataIndex: 'asset',
                width: 140,
                render: (text, record) => getRecordLabel(blocksResource, record),
                fixed: 'left',
                sorter: (a, b) => {
                    const labelA = getRecordLabel(blocksResource, a);
                    const labelB = getRecordLabel(blocksResource, b);
                    if (labelA < labelB) return -1;
                    if (labelA > labelB) return 1;
                    return 0;
                },
                filteredValue: reportStore.oeeTableData.filters?.asset || null ,
                filters: selectedAssets.map((a) => ({ value: a.block_id, text: a.asset_name })),
                onFilter: (value, record) => record.blockId === value,
            },
            {
                title: 'Start',
                width: 140,
                render: (text, record) => reportAccessors.START(record),
                fixed: 'left',
                sorter: (a, b) => {
                    const unixA = moment(a.start).unix();
                    const unixB = moment(b.start).unix();
                    return unixA - unixB;
                },
            },
            {
                title: 'End',
                width: 140,
                render: (text, record) => reportAccessors.END(record),
                fixed: 'left',
                sorter: (a, b) => {
                    const unixA = moment(a.end).unix();
                    const unixB = moment(b.end).unix();
                    return unixA - unixB;
                },
            },
        ];
        const navigateColumn = {
            title: ' ',
            width: 50,
            render: getRowLink(blocksResource),
            fixed: 'right'
        };

        return defaultColumns.concat(...reportStore.oeeColumns.map(c => REPORT_COLUMNS[c]), navigateColumn);
    }, [blocksResource, reportStore]);

    if (reportStore.oeeData.length  === 0) {
        return <ReportNoData />;
    }

    return (
        <AutoSizer>
            {({ width, height }) => {
                return (
                    <div style={{width, height, overflow: 'hidden'}}>
                        <Table
                            size="small"
                            id="timeBasedReportTable"
                            className="report-table"
                            dataSource={reportStore.oeeData}
                            columns={columns}
                            rowKey={(row, index) => {
                                return `${row.blockId}-${row.start}`;
                            }}
                            pagination={false}
                            scroll={{
                                x: width,
                                y: height - 55 // subtract header height
                            }}
                            onChange={(pagination, filters, sorter, { currentDataSource }) => {
                                dispatch(setOeeTableData(filters, currentDataSource))
                            }}
                        />
                    </div>
                )
            }}
        </AutoSizer>
    )
}

export default TimeBasedReportTable;

const getRowLink = (blocksResource) => {
    return (record) => {
        const startDate = moment(record.start);
        const endDate = moment(record.end);
        const resolution = { res_x: 1, res_period: 'hours' };
        const block = blocksResource[record.blockId];
        const pathname = block.asset
            ? `${CONSTANTS.URLS.ASSET}/${record.blockId}`
            : `${CONSTANTS.URLS.BLOCK}/${record.blockId}`;
    
        const query = generateUrlQuery({ startDate, endDate, resolution, span: CUSTOM_SPAN });
        return <Link to={`${pathname}${query}`}><ArrowRightOutlined/></Link>

    }
}

// const DEFAULT_COLUMNS = (blocksResource, assets) => [
//     {
//         title: 'Asset',
//         width: 140,
//         render: (text, record) => getRecordLabel(blocksResource, record),
//         fixed: 'left',
//         sorter: (a, b) => {
//             const labelA = getRecordLabel(blocksResource, a);
//             const labelB = getRecordLabel(blocksResource, b);
//             if (labelA < labelB) return -1;
//             if (labelA > labelB) return 1;
//             return 0;
//         },
//         filterSearch: true,
//         filters: assets.map((a) => ({
//             value: a.asset_name,
//             text: a.asset_name,
//         })),
//         onFilter: (value, record) => {
//             return getRecordLabel(blocksResource, record) === value;
//         },
//     },
//     {
//         title: 'Start',
//         width: 140,
//         render: (text, record) => reportAccessors.START(record),
//         fixed: 'left',
//         sorter: (a, b) => {
//             const unixA = moment(a.start).unix();
//             const unixB = moment(b.start).unix();
//             return unixA - unixB;
//         },
//     },
//     {
//         title: 'End',
//         width: 140,
//         render: (text, record) => reportAccessors.END(record),
//         fixed: 'left',
//         sorter: (a, b) => {
//             const unixA = moment(a.end).unix();
//             const unixB = moment(b.end).unix();
//             return unixA - unixB;
//         },
//     },
// ];

// const NAVIGATE_COLUMN = (blocksResource) => ({
//     title: ' ',
//     width: 50,
//     render: getRowLink(blocksResource),
//     fixed: 'right'
// });