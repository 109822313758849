import { updateStoreRedux } from '../../../../legacy/utils/redux';

// CONSTANT
import { ReportConstants as K } from './Report.constants';

const initialReducer = () => ({
    oeeFilter: {
        dateRange: [null, null],
        period: null,
        blockIds: [],
        timeRange: [null, null],
    },
    oeeTableData: {},
    oeeColumns: ['CALENDAR_TIME', 'SCHEDULED_PRODUCTION_TIME', 'OUTPUT'],
    oeeData: [],
    skuFilter: {
        dateRange: [null, null],
        blockIds: [],
        // labelTypes: [],
        // labelValues: {},
        skuIds: []
    },
    skuTableData: {},
    skuColumns: ['CALENDAR_TIME', 'SCHEDULED_PRODUCTION_TIME', 'OUTPUT'],
    skuData: [],
    issueFilter: {
        dateRange: [null, null],
        blockIds: [],
        // labelTypes: [],
        // labelValues: {},
        issueId: null
    },
    issueTableData: {},
    issueData: [],
});

export const ReportReducer = (
    state = initialReducer(),
    action
) => {
    if (action.type === K.ACTIONS.SET_OEE_REPORT_FILTER) {
        return updateStoreRedux(state, {
            oeeFilter: { ...state.oeeFilter, ...action.payload.oeeFilter },
        });
    }

    if (action.type === K.ACTIONS.SET_OEE_REPORT_DATA) {
        return updateStoreRedux(state, {
            oeeData: action.payload.oeeData,
        });
    }

    if (action.type === K.ACTIONS.SET_SKU_REPORT_FILTER) {
        return updateStoreRedux(state, {
            skuFilter: { ...state.skuFilter, ...action.payload.skuFilter },
        });
    }

    if (action.type === K.ACTIONS.SET_SKU_REPORT_DATA) {
        return updateStoreRedux(state, {
            skuData: action.payload.skuData,
        });
    }

    if (action.type === K.ACTIONS.SET_ISSUE_REPORT_FILTER) {
        return updateStoreRedux(state, {
            issueFilter: { ...state.issueFilter, ...action.payload.issueFilter },
        });
    }

    if (action.type === K.ACTIONS.SET_ISSUE_REPORT_DATA) {
        return updateStoreRedux(state, {
            issueData: action.payload.issueData,
        });
    }

    if (action.type === K.ACTIONS.SET_OEE_REPORT_COLUMNS) {
        return updateStoreRedux(state, {
            oeeColumns: action.payload.oeeColumns,
        });
    }

    if (action.type === K.ACTIONS.SET_SKU_REPORT_COLUMNS) {
        return updateStoreRedux(state, {
            skuColumns: action.payload.skuColumns,
        });
    }

    if (action.type === K.ACTIONS.SET_OEE_TABLE_DATA) {
        return updateStoreRedux(state, {
            oeeTableData: {...action.payload},
        });
    }

    if (action.type === K.ACTIONS.SET_SKU_TABLE_DATA) {
        return updateStoreRedux(state, {
            skuTableData: {...action.payload},
        });
    }

    if (action.type === K.ACTIONS.SET_ISSUE_TABLE_DATA) {
        return updateStoreRedux(state, {
            issueTableData: {...action.payload},
        });
    }

    return updateStoreRedux(state, {});
};
