import React from 'react';

import './ReportSummaryTable.scss';
import { Table } from 'antd';

const ReportSummaryTable = (props) => {
    return (
        <Table
            columns={props.columns}
            dataSource={props.dataSource}
            size="small"
            className="report-summary-table"
            rowKey={(row, index) => index}
            pagination={false}
            scroll={{ x: 240 }}
            summary={props.summary}
        />
    )
}

export default ReportSummaryTable;

export const getSummaryRecordColumn = (totalRecords) => {
    return {
        title: 'Records',
        width: 100,
        render: () => totalRecords.toLocaleString(),
    }
}