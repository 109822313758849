import { makeRequest } from '../Shared/Shared.action';
import { queryStringData } from '../../../legacy/utils/service';

export const api_getAssetChannelsData = (entity_id, asset_id, query) => {
    return new Promise((resolve, reject) => {
        const q = queryStringData(query);
        makeRequest({
            path: `v1/entity/${entity_id}/asset/${asset_id}/data${q}`,
            method: 'GET',
            monitor: true,
            success: (res) => resolve(res),
            error: reject,
        });
    });
};

export const api_getAssetOEEData = (entity_id, asset_id, query) => {
    return new Promise((resolve, reject) => {
        const q = queryStringData(query);
        makeRequest({
            path: `v1/entity/${entity_id}/asset/${asset_id}/oee${q}`,
            method: 'GET',
            monitor: true,
            success: (res) => resolve(res),
            error: reject,
        });
    });
};

export const api_getAssetFusionsData = (entity_id, asset_id, query) => {
    return new Promise((resolve, reject) => {
        const q = queryStringData(query);
        makeRequest({
            path: `v1/entity/${entity_id}/asset/${asset_id}/fusion_data${q}`,
            method: 'GET',
            monitor: true,
            success: (res) => resolve(res),
            error: reject,
        });
    });
};

export const api_getAssetChartsData = (entity_id, asset_id, query) => {
    const args = [entity_id, asset_id, query];
    const promises = [
        api_getAssetChannelsData(...args),
        api_getAssetFusionsData(...args),
    ];

    return Promise.all(promises);
};

export const api_getAssetsChartsData = (entity_id, assets, query) => {
    return new Promise((resolve, reject) => {
        const q = queryStringData({ ...query, assets });
        makeRequest({
            path: `v1/entity/${entity_id}/combined_assets${q}`,
            method: 'GET',
            monitor: true,
            success: (res) => resolve(res),
            error: reject,
        });
    });
};

export const api_getAssetOperatorData = (entity_id, asset_id, query) => {
    const q = queryStringData(query);
    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/asset/${asset_id}/basic/data${q}`,
            method: 'GET',
            monitor: true,
            success: (res) => resolve(res),
            error: reject,
        });
    });
};

export const api_getChannelData = (entity_id, metadata_id, query) => {
    return new Promise((resolve, reject) => {
        const q = queryStringData(query);
        makeRequest({
            path: `v1/entity/${entity_id}/metadata/${metadata_id}/data${q}`,
            method: 'GET',
            monitor: true,
            success: (res) => resolve(res),
            error: reject,
        });
    });
};

export const api_getFusionData = (entity_id, fusion_id, query) => {
    return new Promise((resolve, reject) => {
        const q = queryStringData(query);
        makeRequest({
            path: `v1/entity/${entity_id}/fusion/${fusion_id}/data${q}`,
            method: 'GET',
            monitor: true,
            success: (res) => resolve(res),
            error: reject,
        });
    });
};

export const api_getChartData = (entity_id, metadata_id, fusion_id, query) => {
    if (fusion_id) {
        return api_getFusionData(entity_id, fusion_id, query)
    };

    return api_getChannelData(entity_id, metadata_id, query);
};

export const api_getBlockOEEData = (entity_id, block_id, query) => {
    return new Promise((resolve, reject) => {
        const q = queryStringData(query);
        makeRequest({
            path: `v1/entity/${entity_id}/block/${block_id}/data${q}`,
            method: 'GET',
            monitor: true,
            success: resolve,
            error: reject,
        });
    });
};

export const api_getSummaryTileData = (entity_id, query) => {
    return new Promise((resolve, reject) => {
        let tileQuery = '';
        if (query.ids) {
            tileQuery = `&summary_tiles=[${query.ids.join(',')}]`;
        }

        const q = queryStringData({
            res_x: query.res_x,
            res_period: query.res_period,
            date_range: query.date_range,
            sku_oee: query.sku_oee,
        });

        makeRequest({
            path: `v1/entity/${entity_id}/dashboard/summary_tile/data${q}${tileQuery}`,
            method: 'GET',
            monitor: true,
            success: resolve,
            error: reject,
        });
    });
};

export const api_getTileDataAndLabels = (entity_id, query) => {
    return new Promise((resolve, reject) => {
        let tileQuery = '';
        if (query.ids) {
            tileQuery = `&tiles=[${query.ids.join(',')}]`;
        }

        const q = queryStringData({
            res_x: query.res_x,
            res_period: query.res_period,
            date_range: query.date_range,
        });

        makeRequest({
            path: `v1/entity/${entity_id}/dashboard/data/label${q}${tileQuery}`,
            method: 'GET',
            monitor: true,
            success: (res) => resolve(res),
            error: reject,
        });
    });
};

export const api_deleteDeviceData = (entity_id, device_id, data) => {
    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/device/${device_id}/data`,
            method: 'DELETE',
            params: JSON.stringify(data),
            success: (res) => resolve(res),
            error: reject,
        });
    });
};
