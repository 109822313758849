import { FunctionOutlined } from "@ant-design/icons";
import { toLocaleDecimals } from "../../utils/helpers";
import { reportAccessors } from "./accessors";

export const REPORT_COLUMNS = {
    OEE1: {
        title: 'OEE-1',
        width: 100,
        render: (text, record) => toLocaleDecimals(reportAccessors.OEE1(record)),
        sorter: (a, b) => reportAccessors.OEE1(a) - reportAccessors.OEE1(b),
    },
    OEE2: {
        title: 'OEE-2',
        width: 100,
        render: (text, record) => toLocaleDecimals(reportAccessors.OEE2(record)),
        sorter: (a, b) => reportAccessors.OEE2(a) - reportAccessors.OEE2(b),
    },
    OUTPUT: {
        title: 'Output (pcs)',
        width: 100,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.OUTPUT(record)),
        sorter: (a, b) => reportAccessors.OUTPUT(a) - reportAccessors.OUTPUT(b),
    },
    DEFECTS: {
        title: 'Defects (pcs)',
        width: 100,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.DEFECTS(record)),
        sorter: (a, b) => reportAccessors.DEFECTS(a) - reportAccessors.DEFECTS(b),
    },
    CALENDAR_TIME: {
        title: 'Calendar Time (hours)',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.CALENDAR_TIME(record)),
        sorter: (a, b) => reportAccessors.CALENDAR_TIME(a) - reportAccessors.CALENDAR_TIME(b),
    },
    SCHEDULED_PRODUCTION_TIME: {
        title: 'Scheduled Production Time (hours)',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.SCHEDULED_PRODUCTION_TIME(record)),
        sorter: (a, b) => reportAccessors.SCHEDULED_PRODUCTION_TIME(a) - reportAccessors.SCHEDULED_PRODUCTION_TIME(b),
    },
    GROSS_OPERATING_TIME: {
        title: 'Gross Operating Time (hours)',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.GROSS_OPERATING_TIME(record)),
        sorter: (a, b) => reportAccessors.GROSS_OPERATING_TIME(a) - reportAccessors.GROSS_OPERATING_TIME(b),
    },
    NET_OPERATING_TIME: {
        title: 'Net Operating Time (hours)',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.NET_OPERATING_TIME(record)),
        sorter: (a, b) => reportAccessors.NET_OPERATING_TIME(a) - reportAccessors.NET_OPERATING_TIME(b),
    },
    EFFECTIVE_TIME: {
        title: 'Effective Time (hours)',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.EFFECTIVE_TIME(record)),
        sorter: (a, b) => reportAccessors.EFFECTIVE_TIME(a) - reportAccessors.EFFECTIVE_TIME(b),
    },
    UNUTILISED: {
        title: 'Unutilised (hours)',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.UNUTILISED(record)),
        sorter: (a, b) => reportAccessors.UNUTILISED(a) - reportAccessors.UNUTILISED(b),
    },
    BREAKDOWNS: {
        title: 'Breakdowns (hours)',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.BREAKDOWNS(record)),
        sorter: (a, b) => reportAccessors.BREAKDOWNS(a) - reportAccessors.BREAKDOWNS(b),
    },
    SETUP: {
        title: 'Setup & others (hours)',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.SETUP(record)),
        sorter: (a, b) => reportAccessors.SETUP(a) - reportAccessors.SETUP(b),
    },
    MINOR_STOPS: {
        title: 'Minor Stops (hours)',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.MINOR_STOPS(record)),
        sorter: (a, b) => reportAccessors.MINOR_STOPS(a) - reportAccessors.MINOR_STOPS(b),
    },
    SPEED_LOSS: {
        title: 'Speed Loss (hours)',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.SPEED_LOSS(record)),
        sorter: (a, b) => reportAccessors.SPEED_LOSS(a) - reportAccessors.SPEED_LOSS(b),
    },
    REJECTS: {
        title: 'Rejects (hours)',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.REJECTS(record)),
        sorter: (a, b) => reportAccessors.REJECTS(a) - reportAccessors.REJECTS(b),
    },
    REWORK: {
        title: 'Rework (hours)',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.REWORK(record)),
        sorter: (a, b) => reportAccessors.REWORK(a) - reportAccessors.REWORK(b),
    },
    NO_DATA: {
        title: 'No Data (hours)',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.NO_DATA(record)),
        sorter: (a, b) => reportAccessors.NO_DATA(a) - reportAccessors.NO_DATA(b),
    },
    UNSCHEDULED: {
        title: 'Unscheduled (hours)',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.UNSCHEDULED(record)),
        sorter: (a, b) => reportAccessors.UNSCHEDULED(a) - reportAccessors.UNSCHEDULED(b),
    },
    PLANNED_DOWNTIME: {
        title: 'Planned Downtime (hours)',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.PLANNED_DOWNTIME(record)),
        sorter: (a, b) => reportAccessors.PLANNED_DOWNTIME(a) - reportAccessors.PLANNED_DOWNTIME(b),
    },
    LOA_LOSS: {
        title: 'Loading Factor',
        width: 100,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.LOA_LOSS(record)),
        sorter: (a, b) => reportAccessors.LOA_LOSS(a) - reportAccessors.LOA_LOSS(b),
    },
    AVA_LOSS: {
        title: 'Availability Factor',
        width: 100,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.AVA_LOSS(record)),
        sorter: (a, b) => reportAccessors.AVA_LOSS(a) - reportAccessors.AVA_LOSS(b),
    },
    PER_LOSS: {
        title: 'Performance Factor',
        width: 100,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.PER_LOSS(record)),
        sorter: (a, b) => reportAccessors.PER_LOSS(a) - reportAccessors.PER_LOSS(b),
    },
    QUA_LOSS: {
        title: 'Quality Factor',
        width: 100,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.QUA_LOSS(record)),
        sorter: (a, b) => reportAccessors.QUA_LOSS(a) - reportAccessors.QUA_LOSS(b),
    },
    PRODUCTION_SPEED: {
        title: 'Production Speed (pc/min)',
        width: 100,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.PRODUCTION_SPEED(record)),
        sorter: (a, b) => reportAccessors.PRODUCTION_SPEED(a) - reportAccessors.PRODUCTION_SPEED(b),
    },
    CYCLE_TIME: {
        title: 'Cycle Time (min/pc)',
        width: 100,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.CYCLE_TIME(record)),
        sorter: (a, b) => reportAccessors.CYCLE_TIME(a) - reportAccessors.CYCLE_TIME(b),
    },
    MTBF: {
        title: 'MTBF (hours)',
        width: 100,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.MTBF(record)),
        sorter: (a, b) => reportAccessors.MTBF(a) - reportAccessors.MTBF(b),
    },
    MTTR: {
        title: 'MTTR (hours)',
        width: 100,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.MTTR(record)),
        sorter: (a, b) => reportAccessors.MTTR(a) - reportAccessors.MTTR(b),
    },
}

export const SUMMARY_COLUMNS = {
    OEE1: {
        title: 'OEE-1',
        width: 100,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.OEE1),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    OEE2: {
        title: 'OEE2',
        width: 100,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.OEE2),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    OUTPUT: {
        title: 'Output',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.OUTPUT),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    DEFECTS: {
        title: 'Defects',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.DEFECTS),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    CALENDAR_TIME: {
        title: 'Calendar Time',
        width: 140,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.CALENDAR_TIME),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    SCHEDULED_PRODUCTION_TIME: {
        title: 'Scheduled Production Time',
        width: 140,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.SCHEDULED_PRODUCTION_TIME),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    GROSS_OPERATING_TIME: {
        title: 'Gross Operating Time',
        width: 140,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.GROSS_OPERATING_TIME),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    NET_OPERATING_TIME: {
        title: 'Net Operating Time',
        width: 140,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.NET_OPERATING_TIME),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    EFFECTIVE_TIME: {
        title: 'Effective Time',
        width: 140,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.EFFECTIVE_TIME),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    UNUTILISED: {
        title: 'Unutilised',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.UNUTILISED),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    BREAKDOWNS: {
        title: 'Breakdowns',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.BREAKDOWNS),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    SETUP: {
        title: 'Setup & others',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.SETUP),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    MINOR_STOPS: {
        title: 'Minor Stops',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.MINOR_STOPS),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    SPEED_LOSS: {
        title: 'Speed Loss',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.SPEED_LOSS),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    REJECTS: {
        title: 'Rejects',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.REJECTS),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    REWORK: {
        title: 'Rework',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.REWORK),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    NO_DATA: {
        title: 'No Data',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.NO_DATA),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    UNSCHEDULED: {
        title: 'Unscheduled',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.UNSCHEDULED),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    PLANNED_DOWNTIME: {
        title: 'Planned Downtime',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.PLANNED_DOWNTIME),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    LOA_LOSS: {
        title: 'Loading Factor',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.LOA_LOSS),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    AVA_LOSS: {
        title: 'Availability Factor',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.AVA_LOSS),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    PER_LOSS: {
        title: 'Performance Factor',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.PER_LOSS),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    QUA_LOSS: {
        title: 'Quality Factor',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.QUA_LOSS),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    PRODUCTION_SPEED: {
        title: 'Production Speed',
        width: 100,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.PRODUCTION_SPEED),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    CYCLE_TIME: {
        title: 'Cycle Time',
        width: 100,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.CYCLE_TIME),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    MTBF: {
        title: 'MTBF',
        width: 100,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.MTBF),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    MTTR: {
        title: 'MTTR',
        width: 100,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.MTTR),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
}

export const ISSUE_REPORT_COLUMNS = {
    LABEL_DURATION: {
        title: 'Duration (hours)',
        width: 100,
        render: (text, record) => toLocaleDecimals(reportAccessors.LABEL_DURATION(record)),
    },
    EFFECTIVE_TIME: {
        title: 'OEE Effective (hours)',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(reportAccessors.EFFECTIVE_TIME(record)),
    },
    LABEL_NOTES: {
        title: 'Notes',
        width: 320,
        ellipsis: true,
        render: (text, record) => reportAccessors.LABEL_NOTES(record),
    }
}

export const ISSUE_SUMMARY_COLUMNS = {
    LABEL_DURATION: {
        title: 'Duration (hours)',
        width: 100,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.LABEL_DURATION),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    EFFECTIVE_TIME: {
        title: 'OEE Effective (hours)',
        width: 120,
        ellipsis: true,
        render: (text, record) => toLocaleDecimals(record.EFFECTIVE_TIME),
        onFilter: (value, record) => true,
        filterIcon: (filtered) => <FunctionOutlined/>,
    },
    // LABEL_NOTES: {
    //     title: 'Notes',
    //     width: 320,
    //     ellipsis: true,
    //     render: (text, record) => record.LABEL_NOTES,
    //     onFilter: (value, record) => true,
    //     filterIcon: (filtered) => <FunctionOutlined/>,
    // }
}

export const getRecordLabel = (blockResource, record) => blockResource[record.blockId]?.asset?.asset_name;