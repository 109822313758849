import type ImpactReportModel from "../models/impact_report.model"
import { type ImpactReportData, type ImpactReportDataPoint, type ImpactReportVariables } from "../models/impact_report.model"

const ImpactReportTransformer = (res: Record<any, any>): ImpactReportModel => ({
    id: res.id,
    entityId: res.entity_id,
    blockId: res.block_id,
    startsAt: res.starts_at,
    endsAt: res.ends_at,
    variables: ImpactReportVariablesTransformer(res.variables),
    data: ImpactReportDataTransformer(res.data),
    reportName: res.report_name,
    reportType: res.report_type.toLowerCase(),
    createdAt: res.created_at,
    updatedAt: res.updated_at
})

export const ImpactReportVariablesTransformer = (vars: any): ImpactReportVariables => {
    return {
        unitOfTime: vars.unit_of_time,
        operatingHoursYear: vars.operating_hours_year ?? "",
        costPerOperatingHour: vars.cost_per_operating_hour ?? "",
        output: vars.output ?? "",
        marginPerPiece: vars.margin_per_piece ?? "",
        benchmark: vars.benchmark ?? "",
        currency: vars.currency ?? "",
        current: vars.current ?? undefined
    }
}

export const ImpactReportDataTransformer = (data: any): ImpactReportData | undefined => {
    if (!data) return undefined
    return {
        baseline: ImpactReportDataPointTransformer(data.baseline),
        current: ImpactReportDataPointTransformer(data.current),
        best: ImpactReportDataPointTransformer(data.best),
    }
}

export const ImpactReportDataPointTransformer = (datapoint: any): ImpactReportDataPoint | null => {
    if (!datapoint) return null
    return {
        startsAt: datapoint.starts_at,
        endsAt: datapoint.ends_at,
        value: datapoint.value
    }
}

export default ImpactReportTransformer
