import moment from 'moment';
import CONSTANTS from '../Constants';
import { timeEnd, timeStart } from './date';

const ONE_HOUR = 60 * 60;
const ONE_DAY = ONE_HOUR * 24;
// const ONE_WEEK = ONE_DAY * 7;
const ONE_MONTH = ONE_DAY * 31;
const ONE_QUARTER = ONE_DAY * 91;
const ONE_YEAR = ONE_DAY * 366; // LEAP YEAR

export const RESOLUTIONS_MAP = CONSTANTS.RESOLUTIONS.reduce((acc, curr) => {
    return {
        ...acc,
        [`${curr.res_x} ${curr.res_period}`]: curr,
    };
}, {});

export const getDefaultResolution = (durationSeconds) => {
    let result;

    if (durationSeconds <= ONE_YEAR) result = '1 months';
    if (durationSeconds <= ONE_QUARTER) result = '1 weeks';
    if (durationSeconds <= ONE_MONTH) result = '1 days';
    if (durationSeconds <= ONE_DAY) result = '1 hours';
    if (durationSeconds <= ONE_HOUR) result = '1 minutes';

    return RESOLUTIONS_MAP[result];
};

export const getAppropriateResolution = (durationSeconds, current) => {
    const defaultResolution = getDefaultResolution(durationSeconds);
    if (!current) return defaultResolution;

    const resolutionsList = getAllowableResolutions({
        duration: durationSeconds,
    });

    
    const result = resolutionsList.find(
        (r) => r.res_x === current.res_x && r.res_period === current.res_period
    );
        
    return result ? result : defaultResolution;
};

export const _isRangeResolutionAllowed = (total_dur_sec, res_x, res_period) => {
    let period_dur_sec = moment.duration(res_x, res_period).asSeconds();
    return (
        total_dur_sec / period_dur_sec < 800 &&
    total_dur_sec / period_dur_sec > 1 && // Number of datapoints displayed per chart to be 800 max
    (total_dur_sec > 86400 &&
    (res_period === 'seconds' || res_period === 'minutes')
        ? false
        : true)
    );
};

export const getAllowableResolutions = ({ start, end, duration }) => {
    let allowableResolutions = [];

    let totalDurationSeconds = 1;
    if (duration !== NaN) {
        totalDurationSeconds = duration;
    }

    if (!duration && start && end) {
        totalDurationSeconds = moment.duration(end.diff(start)).as('seconds');
    }

    if (totalDurationSeconds <= 0) return [];

    allowableResolutions = CONSTANTS.RESOLUTIONS.filter((res) => {
        return _isRangeResolutionAllowed(
            totalDurationSeconds,
            res.res_x,
            res.res_period
        );
    });

    return allowableResolutions;
};

export const floorTimeByResolution = (moment, resolution) => {
    const { res_x, k } = resolution;
    const durationInSeconds = res_x * k;
    if (durationInSeconds < 60) {
        const multiplier = Math.floor(moment.seconds() / res_x);
        const seconds = multiplier * res_x;
        return moment.set({ seconds: seconds, milliseconds: 0 });
    }
    return moment.set({ seconds: 0, milliseconds: 0 });
};

export const buildUiControlState = (params) => {
    const { from, to, res_x, res_period, span = 'Custom' } = params;

    const now = moment()
    const startDate = moment(from);
    const endDate = moment(to);
    const streaming = now.isBetween(startDate, endDate);
    const resolution = CONSTANTS.RESOLUTIONS.find(
        (r) => r.res_x === +res_x && r.res_period === res_period
    );

    return {
        startDate,
        endDate,
        resolution,
        span: span.replace('+', ' '),
        streaming
    };
};

export const shouldStream = (start, end) => {
    return moment().isBetween(start, end, null, '[]');
};

export const withinStreamingWindow = (start, end, now, resolution) => {
    const { res_x, res_period } = resolution;
    now.subtract(res_x, res_period);
    return now.isBetween(start, end, null, '[]');
};

export const isControlledView = (href) => {
    const { DASHBOARD, PARETO, ASSET, BLOCK, ASSET_MANAGEMENT } = CONSTANTS.URLS;
    const paretoRegex = new RegExp(PARETO);
    const dashboardRegex = new RegExp(DASHBOARD);
    const assetRegex = new RegExp(ASSET);
    const blockRegex = new RegExp(BLOCK);
    const assetManagementRegex = new RegExp(ASSET_MANAGEMENT);
    const assetPlannerRegex = new RegExp('/planner');
    const shopfloorRegex = new RegExp('/production');

    href = href || window.location.href;

    return (
        (href.match(dashboardRegex) && !href.match(shopfloorRegex)) ||
    href.match(paretoRegex) ||
    (href.match(assetRegex) &&
      !(href.match(assetManagementRegex) || href.match(assetPlannerRegex))) ||
    href.match(blockRegex)
    );
};

export const dataApiQueryRange = (controls) => {
    const { resolution, startDate, endDate } = controls;

    const now = moment();
    const _lower = floorTimeByResolution(startDate, resolution);
    const _upper = floorTimeByResolution(endDate, resolution);
    const lower = now.isBefore(startDate)
        ? now.clone().set({ seconds: 0, milliseconds: 0 })
        : _lower;
    const upper = now.isBefore(endDate)
        ? now.isBefore(startDate)
            ? now.clone().set({ seconds: 0, milliseconds: 0 })
            : floorTimeByResolution(now.clone(), resolution)
        : _upper;

    return { lower, upper };
};

export const dataApiQueryParams = (controls) => {
    const date_range = dataApiQueryRange(controls);
    const {
        resolution: { res_x, res_period },
        sku_oee,
    } = controls;

    return {
        res_x,
        res_period,
        date_range,
        sku_oee,
    };
};

export const getRangeFromCustomSpan = (
    startDate,
    endDate,
    anchor,
    displace = 0
) => {
    const customSpanSeconds = moment
        .duration(endDate.diff(startDate))
        .as('seconds');

    const diffSeconds = moment.duration(anchor.diff(startDate)).as('seconds');

    const multiple = Math.floor(diffSeconds / customSpanSeconds) + displace;

    const newStart = startDate
        .clone()
        .add(multiple * customSpanSeconds, 'seconds');
    const newEnd = newStart.clone().add(customSpanSeconds, 'seconds');

    return [newStart, newEnd];
};

export const getRangeFromSpan = (anchor, span, timezone, tzStartDifference) => {
    const res = {
        res_x: span.size,
        res_period: span.unit === 'week' ? 'isoWeek' : span.unit,
    };

    return [
        timeStart(anchor, res, tzStartDifference),
        timeEnd(anchor, res, tzStartDifference),
    ].map((m) => m.utcOffset(timezone));
};

export const generateSpanObj = (span) => {
    const [size, unit] = span.split(' ');
    return { size, unit };
};

export const getBrowserTz = () => {
    const date = new Date();
    const offset = date.getTimezoneOffset();

    return offset * -1;
};
