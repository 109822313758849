import React from 'react';
import './ToggleSummary.scss';
import AukButton from '../../../components/AukButton';

const SummaryToggle = (props) => {
    return (
        <AukButton.Text className="report-summary-toggle" onClick={props.onClick}>
            Show/Hide Summary
        </AukButton.Text>
    );
}

export default SummaryToggle;