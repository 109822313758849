import React, { useMemo } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import './TimeReportTable.scss'
import { AutoSizer } from 'react-virtualized';
import ReportNoData from './ReportNoData';
import { reportAccessors } from '../accessors';
import { getRecordLabel, ISSUE_REPORT_COLUMNS } from '../reportColumns';
import { generateUrlQuery } from '../../../utils/url';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import CONSTANTS from '../../../Constants';
import { CUSTOM_SPAN } from '../../../../store/old/UI/Controls/Controls.constants';
import { labelDisplayText } from '../../../utils/labels';
import { values } from 'lodash';
import { setIssueTableData } from '../../../../store/old/UI/Report/Report.action';

const IssueReportTable = () => {
    const dispatch = useDispatch();
    const reportStore = useSelector(appState => appState.ui.report);
    const blocksResource = useSelector(appState => appState.blocks.blocks);

    const columns = useMemo(() => {
        const selectedAssets = reportStore.issueFilter.blockIds.map(id => blocksResource[id].asset);
        const defaultColumns = [
            {
                title: 'Issue',
                width: 180,
                ellipsis: true,
                render: (text, record) => labelDisplayText(record.label),
                fixed: 'left'
            },
            {
                title: 'Asset',
                dataIndex: 'asset',
                width: 140,
                render: (text, record) => getRecordLabel(blocksResource, record),
                fixed: 'left',
                sorter: (a, b) => {
                    const labelA = getRecordLabel(blocksResource, a);
                    const labelB = getRecordLabel(blocksResource, b);
                    if (labelA < labelB) return -1;
                    if (labelA > labelB) return 1;
                    return 0;
                },
                filterSearch: true,
                filteredValue: reportStore.issueTableData.filters?.asset || null ,
                filters: selectedAssets.map((a) => ({ value: a.block_id, text: a.asset_name })),
                onFilter: (value, record) => record.blockId === value,
            },
            {
                title: 'Start',
                width: 120,
                render: (text, record) => reportAccessors.START(record),
                fixed: 'left'
            },
            {
                title: 'End',
                width: 120,
                render: (text, record) => reportAccessors.END(record),
                fixed: 'left'
            },
        ];
        const navigateColumn = {
            title: ' ',
            width: 50,
            render: getRowLink(blocksResource),
            fixed: 'right'
        };
        
        return defaultColumns.concat(...values(ISSUE_REPORT_COLUMNS), navigateColumn);
    }, [blocksResource, reportStore]);

    if (reportStore.issueData.length  === 0) {
        return <ReportNoData />;
    }

    return (
        <AutoSizer>
            {({ width, height }) => {
                return (
                    <div style={{width, height, overflow: 'hidden'}}>
                        <Table
                            size="small"
                            id="issueReportTable"
                            className="report-table"
                            dataSource={reportStore.issueData}
                            columns={columns}
                            rowKey={(row, index) => {
                                return index
                            }}
                            pagination={false}
                            scroll={{
                                x: width,
                                y: height - 55 // subtract header height
                            }}
                            onChange={(pagination, filters, sorter, { currentDataSource }) => {
                                dispatch(setIssueTableData(filters, currentDataSource))
                            }}
                        />
                    </div>
                )
            }}
        </AutoSizer>
    )
}

export default IssueReportTable;

const getRowLink = (blocksResource) => {
    return (record) => {
        const startDate = moment(record.start);
        const endDate = moment(record.end);
        const resolution = { res_x: 1, res_period: 'hours' };
        const block = blocksResource[record.blockId];
        const pathname = block.asset
            ? `${CONSTANTS.URLS.ASSET}/${record.blockId}`
            : `${CONSTANTS.URLS.BLOCK}/${record.blockId}`;
    
        const query = generateUrlQuery({ startDate, endDate, resolution, span: CUSTOM_SPAN });
        return <Link to={`${pathname}${query}`}><ArrowRightOutlined/></Link>;
    }
}
