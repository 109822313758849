import { Menu } from 'antd';
import React from 'react';
import { capitalize } from 'lodash';

export const getColumnFunctionMenu = ({ onSelect, items }) => {
    return ({ close }) => (
        <FunctionMenu close={close} onSelect={onSelect} items={items}/>
    )
}

const FunctionMenu = (props) => {
    return (
        <Menu 
            onSelect={(val) => {
                props.onSelect(val)
                props.close()
            }}
        >
            {
                props.items.map(item => (
                    <Menu.Item key={item}>{capitalize(item.toLowerCase())}</Menu.Item>
                ))
            }
        </Menu>
    )
}

export const ABBREVIATE = {
    SUM: 'Sum',
    AVERAGE: 'Avg',
    MIN: 'Min',
    MAX: 'Max',
}

export const OP = {
    SUM: (data, accessor) => {
        const validDataset = data.filter(d => isFinite(accessor(d)));
        return sum(validDataset, accessor);
    },
    AVERAGE: (data, accessor) => {
        const validDataset = data.filter(d => isFinite(accessor(d)));
        return sum(validDataset, accessor) / validDataset.length
    },
    MIN: (data, accessor) => {
        const validDataset = data.filter(d => isFinite(accessor(d)));
        return Math.min(...validDataset.map(accessor))
    },
    MAX: (data, accessor) => {
        const validDataset = data.filter(d => isFinite(accessor(d)));
        return Math.max(...validDataset.map(accessor))
    },
}

const sum = (data, accessor) => data.reduce((acc, curr) =>  {
    return acc += accessor(curr)
}, 0);

export default FunctionMenu;