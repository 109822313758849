import { mapValues, round, values } from 'lodash';
import CONSTANTS from '../Constants';
import { store } from '../../store';
import moment from 'moment';
import { IssueColors, OeeToIssue } from '../models';

export const OEEFactory = ({ oee = [], effective_labels = [] }, options = {}) => {
    // oee === OEE1/TEEP
    const teep = oeeTimeSeriesParser(oee);
    return {
        oee: teep,
        effective_labels: options.noLabels ? [] : deduplicateLabels(effective_labels),
        get oee2() {
            delete this.oee2;
            return (this.oee2 = oee2TimeSeries(teep));
        },

        get aggregate_oee() {
            delete this.aggregate_oee;
            return (this.aggregate_oee = oeeReducedPercent(teep));
        },

        get aggregate_oee2() {
            delete this.aggregate_oee2;
            return (this.aggregate_oee2 = oeeReducedPercent(this.oee2))
        },

        get overall() {
            delete this.overall;

            const { factors } = this;

            const loading = factors[2].value;
            const availability = factors[3].value;
            const performance = factors[4].value;
            const quality = factors[5].value;
            const final_effective = (availability * performance * quality) / 10000;

            return (this.overall = {
                loading: round(loading, 2),
                availability: round(availability, 2),
                performance: round(performance, 2),
                quality: round(quality, 2),
                final_effective: round(final_effective, 2),
            });
        },

        get waterfall() {
            delete this.waterfall;
            return (this.waterfall = oeeWaterfall(teep));
        },

        get factors() {
            delete this.factors;
            return (this.factors = oeeFactors(this.waterfall));
        },

        get totalOutput() {
            delete this.totalOutput;
            return (this.totalOutput = teep.reduce((acc, curr) => acc += curr.yield, 0));
        },

        get totalDefects() {
            delete this.totalDefects;
            return (this.totalDefects = teep.reduce((acc, curr) => acc += curr.defects, 0));
        }
    };
};

export const getMaskedOEE = (masks) => {
    return Object.keys(CONSTANTS.CAT).map((k) => {
        if (masks[k]) {
            const { abbreviation: masked, mask } = masks[k];
            const prefix = `${masked.toUpperCase()}`;
            return {
                ...CONSTANTS.CAT[k],
                key: k,
                prefix,
                name: `[${prefix}] ${mask}`,
                altLabel: mask,
            };
        }
        return { ...CONSTANTS.CAT[k], key: k };
    });
};

const mapLabelsToIssue = (labels) => {
    return labels.reduce((acc, curr) => {
        if (!curr.issue) {
            return acc;
        }

        const { issue_id } = curr;
        acc[issue_id] = acc[issue_id] ? acc[issue_id].concat(curr) : [curr];

        return acc;
    }, {});
};

// export const OEE_ABBREVIATIONS = {
//   Effective: 'ef',
//   'Un-utilised': 'uu',
//   Unscheduled: 'us',
//   'Planned Downtime': 'pd',
//   Breakdown: 'bd',
//   'Setup/ Changeover': 'st',
//   'Minor Stops': 'ms',
//   'Speed Losses': 'sl',
//   Rejects: 'rj',
//   Rework: 'rw',
//   'No Data': 'na',
// };

// export const override = {
//   us: ['uu', 'pd', 'bd', 'st', 'ms'],
//   pd: ['uu', 'us', 'bd', 'st', 'ms'],
//   bd: ['uu', 'us', 'pd', 'st', 'ms'],
//   st: ['uu', 'us', 'pd', 'bd', 'ms'],
//   ms: ['uu'],
//   sl: [],
//   rj: ['ef', 'rw'],
//   rw: ['ef', 'rj'],
// };

export const oee2TimeSeries = (oee1) => {
    const oee2 = oee1.map((d) => {
        const { pd, us, na } = d.oee;
        const loadingPercent = pd + us + na;
        const scheduledPercent = 100 - loadingPercent;

        return {
            ...d,
            oee: {
                ...mapValues(d.oee, (v) => (scheduledPercent === 0 ? 0 : (v / scheduledPercent)) * 100),
                pd: 0,
                us: 0,
                na: 0,
            },
            effInt: (scheduledPercent * d.int) / 100,
        };
    });
    return oee2;
};

export const oeeTimeSeriesParser = (data) => {
    return data
        .map((d) => {
            return {
                ...d,
                time: new Date(d.time),
                effInt: d.int
            };
        })
        .map((d) => {
            // keep for csv download
            const waterfall = oeeWaterfall([d]);
            const factors = oeeFactors(waterfall);
            return {
                ...d,
                waterfall,
                factors,
            };
        })
        .sort((a, b) => a.time - b.time);
};

export const oeeReduced = (data) => {
    const categories = Object.keys(CONSTANTS.CAT);

    const result = data.reduce((acc, curr) => {
        categories.forEach((cat) => {
            const durationSeconds = ((curr.oee[cat] / 100) * curr.effInt) / 1000;
            acc[cat] = acc[cat] ? acc[cat] + durationSeconds : durationSeconds;
        });
        return acc;
    }, {});

    return result;
};

export const oeeReducedPercent = (data) => {
    const reduced = oeeReduced(data);
    const totalSeconds = Object.keys(reduced).reduce(
        (acc, curr) => (acc += reduced[curr]),
        0
    );

    const result = Object.keys(reduced).reduce((acc, curr) => {
        acc[curr] = (reduced[curr] / totalSeconds) * 100;
        return acc;
    }, {});

    result.totalSeconds = totalSeconds;

    return result;
};

export const oeeWaterfall = (data) => {
    if (!data.length) return [];

    const oeeCondensed = oeeReduced(data);
    
    let total_time = data.reduce((acc, curr) => (acc += curr.effInt / 1000), 0);
    let time_remaining = total_time;
    
    return CONSTANTS.OEE.WATERFALL_CHART.map((item) => {
        const result = { ...item }
        if (result.type === 'loss') {
            result.duration = oeeCondensed[result.key];
            time_remaining -= result.duration;
        } else {
            result.duration = time_remaining;
        }
        result.color = CONSTANTS.CAT[result.key]
            ? CONSTANTS.CAT[result.key].color
            : '#f2f2f2';
        result.percent_duration = (result.duration / total_time) * 100;
        return result;
    });
};

export const oeeFactors = (data) => {
    // data is of oeeWaterfall form
    const dataMap = data.reduce(
        (acc, curr) => ({ ...acc, [curr.key]: curr.percent_duration }),
        {}
    );
    const { ct, pt, gt, nt, ef } = dataMap;

    const l = pt / ct;
    const a = pt > 0 ? (gt / pt) : 0;
    const p = gt > 0 ? (nt / gt) : 0;
    const q = nt > 0 ? (ef / nt) : 0;

    const percent = (n) => n * 100;

    return [
        { label: 'OEE1 / TEEP (%)', value: percent(l * a * p * q) },
        { label: 'OEE2 / OEE (%)', value: percent(a * p * q) },
        { label: 'Loading Factor (%)', value: percent(l) },
        { label: 'Availability Factor (%)', value: percent(a) },
        { label: 'Performance Factor (%)', value: percent(p) },
        { label: 'Quality Factor (%)', value: percent(q) },
    ];
};

export const oeePareto = (data) => {
    const issueLabels = mapLabelsToIssue(data.sort((a, b) => b.from - a.from));

    return Object.keys(issueLabels)
        .map((issue_id) => {
            const labelArray = issueLabels[issue_id];

            const total_duration = labelArray.reduce(
                (acc, curr) => {
                    const from = moment(curr.from);
                    const to = moment(curr.to);
                    const duration = round(moment.duration(to.diff(from)).as('seconds'), 2)
                    return acc + duration
                },
                0
            ); // in seconds

            const total_effective_duration = labelArray.reduce(
                (acc, curr) => acc + (curr.effective_duration || 0),
                0
            ); // in seconds

            const occurrence = labelArray.length;
            const issue = {
                name: labelArray[0].issue,
                issue_id: labelArray[0].issue_id,
                color: IssueColors[OeeToIssue[labelArray[0].oee]],
                oee: labelArray[0].oee,
            };

            return { issue, total_duration, occurrence, total_effective_duration };
        })
        .filter((d) => d.total_duration)
        .sort((a, b) => b.total_effective_duration - a.total_effective_duration);
};

export const oeeHeadersCSV = Object.keys(CONSTANTS.CAT)
    .map((k) => ({ key: k, ...CONSTANTS.CAT[k] }))
    .sort((a, b) => a.csvOrder - b.csvOrder);

export const onlyTEEP = new Set(['ct', 'us', 'pd']);

export const oeeCSV = (data) => {
    const { masks } = store.getState().ui.oee;

    data = data.concat(data.splice(1, 1)); // move no data column to bottom
    const factors = oeeFactors(data)
        .map((d) => `${d.label}, ${round(d.value, 2)}`)
        .join('\n');
    const factorsHeader = 'OEE Summary, Percent';
    const factorsBlock = [factorsHeader, factors].join('\n');

    const scheduledTime = data.find(({ key }) => key === 'pt');

    const categoriesHeader =
    'OEE Category, Percent (OEE1 / TEEP), Percent (OEE2), Duration (hrs)';
    const categories = data
        .map((d, index) => {
            const { type, key, name, percent_duration, duration } = d;
            const abbr = (masks[key] ? masks[key].abbreviation : key).toUpperCase();
            const category = masks[key] ? masks[key].mask : name;
            const percentTEEP = round(percent_duration, 2);
            const percentOEE = onlyTEEP.has(key)
                ? 'NA'
                : round((duration / scheduledTime.duration) * 100, 2);
            const durationHrs = round(duration / 3600, 2);

            const str = `[${abbr}] ${category}, ${percentTEEP}, ${percentOEE}, ${durationHrs}`;
            return type !== 'loss' && index ? `\n${str}` : str;
        })
        .join('\n');
    const categoriesBlock = [categoriesHeader, categories].join('\n');

    return [factorsBlock, categoriesBlock].join('\n\n');
};

export const oeeChartAccessors = {
    y: (d, key) => d.oee[key],
    color: (d) => CONSTANTS.CAT[d.key].color,
};

export const chartOeeKeys = Object.keys(CONSTANTS.CAT).sort((a, b) => {
    if (a === 'ef') return 1;
    return 0;
});

export const deduplicateLabels = (effectiveLabels) => {
    const uniqueByAssetStart = effectiveLabels.reduce((acc, l) => {
        const key = `${l.asset_id}-${l.from}`;
        if (!acc[key]) {
            return { ...acc, [key]: [l] }
        }
        
        return {
            ...acc, [key]: acc[key].concat(l).sort((a, b) => moment(a.to).isAfter(moment(b.to)))
        }
    }, {})
    
    const deduplicatedLabels = mapValues(uniqueByAssetStart, (arrayEffectiveLabels) => {
        return arrayEffectiveLabels.reduce((acc, l) => {
            if (!acc) return effectiveLabelTransformer(l)
            return effectiveLabelTransformer({
                ...acc,
                isOpen: true,
                to: l.to,
                effective_duration: acc.effective_duration + l.effective_duration
            })
        }, undefined)
    })

    return values(deduplicatedLabels)
}

export const effectiveLabelTransformer = (l) => {
    const notes = l.label_values.find((value) => value.type === 'notes');
    return {
        ...l,
        notes: notes ? notes.value : '',
        from: new Date(l.from),
        to: new Date(l.to)
    }
}
