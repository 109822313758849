/* eslint-disable react/prop-types */

import React from 'react';
import { find, maxBy, minBy, round } from 'lodash';

import { DonutChart, PercentBar } from '../../Charts/v1';

import './OEEDials.scss';
import { useAppSelector } from '../../../store/hooks';

const dp = 1;

const getPieColor = (d, oeeColors) => {
    const goodOeeColor = maxBy(oeeColors, (v) => Number(v.th));
    const badOeeColor = minBy(oeeColors, (v) => Number(v.th));
    const averageColor = find(
        oeeColors,
        (v) =>
            Number(v.th) < Number(goodOeeColor.th) &&
      Number(v.th) > Number(badOeeColor.th)
    );

    return d <= Number(badOeeColor.th)
        ? badOeeColor.color
        : d <= Number(averageColor.th)
            ? averageColor.color
            : goodOeeColor.color;
};

export const OEEDials = (props) => {
    const oeeColors = useAppSelector(appState => appState.preference.entity.oee_colors);
    const { data, style, oee2, donutProps, className, display = 'column' } = props;
    const { final_effective, loading, availability, performance, quality } = data;

    const ef = round(
        oee2 ? final_effective : (loading * final_effective) / 100,
        dp
    );

    const lapq = [
        {
            label: 'LOA',
            value: loading,
            style: { opacity: oee2 ? 0.3 : 1 },
        },
        {
            label: 'AVA',
            value: availability,
        },
        {
            label: 'PER',
            value: performance,
        },
        {
            label: 'QUA',
            value: quality,
        },
    ];

    return (
        <div
            className={`oee-dials ${
                display === 'column' ? 'display-column' : 'display-row'
            } ${className || ''}`}
            style={style}
        >
            <div className="oee-dials__effective">
                <DonutChart
                    className="eff-chart"
                    {...donutProps}
                    data={[{ name: 'OEE', value: ef, color: getPieColor(ef, oeeColors) }]}
                />
            </div>
            <div className="oee-dials__lapq">
                {lapq.map((field) => (
                    <PercentBar
                        withLabel
                        label={field.label}
                        style={field.style || {}}
                        key={field.label}
                        width={`${field.value ? Math.min(field.value, 100) : 0}%`}
                        color={getPieColor(field.value || 0, oeeColors)}
                        value={`${field.value ? field.value.toFixed(2) : '0.00'}`}
                    />
                ))}
            </div>
        </div>
    );
};

OEEDials.defaultProps = {
    data: {
        final_effective: 0,
        loading: 0,
        availability: 0,
        performance: 0,
        quality: 0,
    },
    donutProps: {},
};
