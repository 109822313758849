import * as htmlToImage from 'html-to-image';

export const saveCsv = (() => {
    // https://stackoverflow.com/questions/46638343/download-csv-file-as-response-on-ajax-request
    // https://stackoverflow.com/questions/24007073/open-links-made-by-createobjecturl-in-ie11

    return function (data, fileName) {
        const blob = new Blob([data], { encoding:"UTF-8", type:"text/plain;charset=UTF-8" })
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // for IE
            window.navigator.msSaveOrOpenBlob(blob, fileName)
        } else {
            // for Non-IE (chrome, firefox etc.)
            const a = document.createElement("a")
            document.body.appendChild(a)
            a.style.cssText = "display: none"
            const url = window.URL.createObjectURL(blob)
            a.href = url
            a.download = fileName
            a.click()
            window.URL.revokeObjectURL(url)
            a.remove()
        }
    }
})()

export const exportTableToCSV = (tableId, fileName, options = {}) => {
    const table = document.getElementById(tableId); // Reference to your table
    const rows = Array.from(table.querySelectorAll("tr")); // Get all rows
    const csvContent = rows
    .map(row => {
        const cells = Array.from(row.querySelectorAll("th, td")); // Get all header and data cells
        
        if (options.cellsFilter) {
            return cells.filter(options.cellsFilter).map(cell => `"${cell.textContent}"`).join(",");
        }

        return cells.map(cell => `"${cell.textContent}"`).join(","); // Escape content with quotes
    })
    .join("\n"); // Join rows with new line

    saveCsv([csvContent], fileName);
};

export const multiSaveCsv = (dataArray) => {
    function download_next(i) {
        if (i >= dataArray.length) return
        const { data, fileName } = dataArray[i]
        saveCsv(data, fileName)
        setTimeout(() => download_next(i + 1), 500)
    }

    download_next(0)
}

export const saveSvg = (elementId, fileName) => {
    const node = document.getElementById(elementId)
    const blob = new Blob([node.outerHTML], { type: 'image/svg+xml' })

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
}

export const savePng = (elementId, fileName, options = {}) => {
    const node = document.getElementById(elementId);

    htmlToImage.toPng(node, options)
        .then(function (dataUrl) {
            const a = document.createElement('a');
            a.download = fileName;
            a.href = dataUrl;
            a.click();
            a.remove()
        })
        .catch(function (error) {
            console.error('oops, something went wrong!', error);
        });
}
