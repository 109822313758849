import React, { useMemo, useState } from 'react';
import AukButton from '../../../components/AukButton';
import { ListOutlined } from '@mui/icons-material';
import { Modal, Space, Tree } from 'antd';
import { ReportConstants as K } from '../../../../store/old/UI/Report/Report.constants';
import { values } from 'lodash';

const ReportColumnsSelect = (props) => {
    const [showModal, setShowModal] = useState(false);
    const treeData = useMemo(() => {
        return values(K.REPORT_FIELDS).map(c => ({
            title: `${c.label} ${c.unit ? '(' + c.unit + ')' : ''}`,
            key: c.key
        }))
    }, []);

    return (
        <Space className='mb-3 mr-3'>
            <AukButton.Outlined 
                icon={<ListOutlined/>}
                onClick={() => { setShowModal(true) }}
            >
                Columns
            </AukButton.Outlined>
            <Modal
                title="Columns"
                open={showModal}
                cancelButtonProps={{ style: { display: 'none' }}}
                onCancel={() => { setShowModal(false) }}
                onOk={() => { setShowModal(false) }}
            >
                <Tree
                    checkable
                    onCheck={(values) => { props.onSelect(values) }}
                    checkedKeys={props.selectedKeys}
                    treeData={treeData}
                    />
            </Modal>
        </Space>
    )
}

export default ReportColumnsSelect;