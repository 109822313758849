import TuneIcon from "@mui/icons-material/Tune"
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material"
import { isEmpty, pickBy, round } from "lodash"
import moment, { type Moment, type unitOfTime } from "moment/moment"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import type { LabelSeriesPoint } from "react-vis"
import type BlockModel from "../../../api/models/block.model"
import type EntityModel from "../../../api/models/entity.model"
import type ImpactReportModel from "../../../api/models/impact_report.model"
import type { ImpactReportVariables } from "../../../api/models/impact_report.model"
import DrawerButton from "../../../components/Button/DrawerButton/DrawerButton"
import Drawer from "../../../components/Drawer/Drawer"
import { IMPACT_COLORS, ImpactEnum, ImpactTypeEnum, xAxisDisplaySet } from "../../../constants/impact.constants"
import useBlocks from "../../../hooks/useBlocks"
import useCurrentEntity from "../../../hooks/useCurrentEntity"
import convertToEntityDayStart from "../../../lib/utils/convertToEntityDayStart"
import { reportsPreviewAction, type ImpactReportVariablesPayload, reportsSaveAction, reportsUpdateAction, reportsDestroyAction, reportsShowAction } from "../../../store/actions/impact_reports.action"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import ImpactChart, { getXTickLabel, ImpactChartXTick, type ImpactComparisonChartSeriesPoint } from "./ImpactChart"
import ImpactForm, { type ImpactFormValues } from "./ImpactForm"
import ImpactTitle from "./ImpactTitle"
import ImpactToggleButtons from "./ImpactToggleButtons"
import ImpactTooltip from "./ImpactTooltip"
import ImpactReportsList from "./ImpactReportsList"
import InputDialog from "../../../components/Dialog/InputDialog"
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"

const ImpactResponsiveScreen = () => {
    const dispatch = useAppDispatch()
    const theme = useTheme()

    const screenUpMd = useMediaQuery(theme.breakpoints.up("md"))
    const screenDownSm = useMediaQuery(theme.breakpoints.down("sm"))

    const entity = useCurrentEntity()
    const { root } = useBlocks()
    const { viewing } = useAppSelector(appState => appState.impact)

    const [barSelection, setBarSelection] = useState<ImpactComparisonChartSeriesPoint | null>(null)
    const [impactType, setImpactType] = useState<ImpactTypeEnum>(ImpactTypeEnum.COST_SAVINGS)
    const [formValues, setFormValues] = useState<ImpactFormValues>(viewing ? getFormValues(viewing) : getDefaultFormValues(entity, root))
    const [currentStart, setCurrentStart] = useState<string | null>(null)
    const [showDrawer, setShowDrawer] = useState<boolean>(false)
    const [showFileNameDialog, setShowFileNameDialog] = useState<boolean>(false)

    const viewingData = useMemo(() => {
        if (viewing) return viewing.data
        return null
    }, [viewing])

    const previewReport = useCallback((params: ReturnType<typeof getImpactParameters>) => {
        void dispatch(reportsPreviewAction({
            entityId: entity.entityId,
            blockId: params.blockId as number,
            startsAt: convertToEntityDayStart(params.startsAt as string, entity),
            period: params.period as unitOfTime.Base,
            variables: params.variables as unknown as ImpactReportVariablesPayload
        }))
    }, [entity])

    useEffect(() => {
        const previewParameters = getImpactParameters(formValues, currentStart ? convertToEntityDayStart(currentStart, entity) : undefined)
        previewReport(previewParameters)
    }, [])

    const barSeriesData = useMemo(() => {
        if (!viewingData) return []

        const baselineOee = viewingData.baseline ? Math.round(viewingData.baseline.value * 10) / 10 : 0
        const currentOee = viewingData.current ? Math.round(viewingData.current.value * 10) / 10 : 0
        const bestOee = viewingData.best ? Math.round(viewingData.best.value * 10) / 10 : 0

        let benchmark: number | string | null = formValues.benchmark
        const { annualisedValue, withAnnualisedValue } = getAnnualisedValue({
            unitOfTime: formValues.baselinePeriod as string,
            operatingHoursYear: formValues.annualOperatingHours as string,
            costPerOperatingHour: formValues.operationCostPerHour as string,
            output: formValues.yearlyBaseOutput as string,
            marginPerPiece: formValues.marginPerPiece as string,
            benchmark: formValues.benchmark as string,
            currency: formValues.currency as string
        }, impactType)

        const d1 = (currentOee - baselineOee) / 100 * annualisedValue
        const d2 = (bestOee - currentOee) / 100 * annualisedValue

        const result: ImpactComparisonChartSeriesPoint[] = [
            {
                x: ImpactEnum.BASELINE,
                y: baselineOee,
                fill: IMPACT_COLORS.BASELINE,
                stroke: IMPACT_COLORS.BASELINE,
                type: "data"
            },
            {
                x: ImpactEnum.CAPTURED_DELTA,
                y: currentOee,
                y0: baselineOee,
                fill: isBarSelected(barSelection, ImpactEnum.CAPTURED_DELTA) ? IMPACT_COLORS.DELTA : "none",
                stroke: IMPACT_COLORS.DELTA,
                type: "delta",
                delta: d1,
                opacity: d1 <= 0 ? 0 : isBarSelected(barSelection, ImpactEnum.CAPTURED_DELTA) ? 0.5 : 1,
                withAnnualisedValue
            },
            {
                x: ImpactEnum.CURRENT,
                y: currentOee,
                fill: IMPACT_COLORS.CURRENT,
                stroke: IMPACT_COLORS.CURRENT,
                type: "data"
            },
            {
                x: ImpactEnum.POTENTIAL_DELTA,
                y: bestOee,
                y0: currentOee,
                fill: isBarSelected(barSelection, ImpactEnum.POTENTIAL_DELTA) ? IMPACT_COLORS.DELTA : "none",
                stroke: IMPACT_COLORS.DELTA,
                type: "delta",
                delta: d2,
                opacity: d2 <= 0 ? 0 : isBarSelected(barSelection, ImpactEnum.POTENTIAL_DELTA) ? 0.5 : 1,
                withAnnualisedValue
            },
            {
                x: ImpactEnum.BEST,
                y: bestOee,
                fill: IMPACT_COLORS.BEST,
                stroke: IMPACT_COLORS.BEST,
                type: "data"
            },
        ]

        if (benchmark) {
            benchmark = +benchmark
            const d3 = (benchmark - bestOee) / 100 * annualisedValue
            result.push(
                {
                    x: ImpactEnum.BENCHMARK_DELTA,
                    y: benchmark,
                    y0: bestOee,
                    fill: isBarSelected(barSelection, ImpactEnum.BENCHMARK_DELTA) ? IMPACT_COLORS.DELTA : "none",
                    stroke: IMPACT_COLORS.DELTA,
                    type: "delta",
                    delta: d3,
                    opacity: d3 <= 0 ? 0 : isBarSelected(barSelection, ImpactEnum.BENCHMARK_DELTA) ? 0.5 : 1,
                    withAnnualisedValue
                },
                {
                    x: ImpactEnum.BENCHMARK_GOAL,
                    y: benchmark,
                    fill: IMPACT_COLORS.BENCHMARK,
                    stroke: IMPACT_COLORS.BENCHMARK,
                    type: "data"
                }
            )
        }

        return result
    }, [
        viewing,
        barSelection,
        impactType,
        formValues.annualOperatingHours,
        formValues.benchmark,
        formValues.currency,
        formValues.operationCostPerHour,
        formValues.yearlyBaseOutput,
        formValues.marginPerPiece,
    ])

    const labelData: unknown = useMemo(() => {
        if (barSeriesData.length === 0) return []
        return barSeriesData
            .map(d => ({
                ...d,
                rotation: 0,
                label: getBarLabel(d, formValues.currency ?? "USD")
            }))
    }, [barSeriesData])

    const handleFormPreview = (values: ImpactFormValues) => {
        const previewParameters = getImpactParameters(values, currentStart ? convertToEntityDayStart(currentStart, entity) : undefined)
        previewReport(previewParameters)
    }

    const handleChangeCurrentStart = (value: string) => {
        setCurrentStart(value)
        const previewParameters = getImpactParameters(formValues, convertToEntityDayStart(value, entity))
        previewReport(previewParameters)
    }

    const handleClickReport = (report: ImpactReportModel) => {
        const _formValues = getFormValues(report)
        const _currentStart = report.variables.current ? moment(report.variables.current.starts_at).startOf("day").toISOString() : undefined
        setFormValues(_formValues)
        setCurrentStart(_currentStart ?? null)

        void dispatch(reportsShowAction({
            entityId: entity.entityId,
            blockId: report.blockId,
            reportId: report.id
        }))
    }

    const handleSaveReport = (reportName = "Saved Report") => {
        const parameters = getImpactParameters(formValues, currentStart ? convertToEntityDayStart(currentStart, entity) : undefined)
        if (isEmpty(parameters)) return

        void dispatch(reportsSaveAction({
            entityId: entity.entityId,
            blockId: parameters.blockId as number,
            reportName,
            startsAt: moment(parameters.startsAt),
            period: parameters.period as unitOfTime.Base,
            variables: parameters.variables as unknown as ImpactReportVariablesPayload
        }))

        setShowFileNameDialog(false)
    }

    const handleUpdateReport = (reportId: number, reportName: string) => {
        const parameters = getImpactParameters(formValues)
        if (isEmpty(parameters)) return

        void dispatch(reportsUpdateAction({
            entityId: entity.entityId,
            blockId: parameters.blockId as number,
            reportId,
            reportName,
            startsAt: moment(parameters.startsAt),
            period: parameters.period as unitOfTime.Base,
            variables: parameters.variables as unknown as ImpactReportVariablesPayload
        }))
    }

    const handleDestroyReport = (blockId: number, reportId: number) => {
        void dispatch(reportsDestroyAction({
            entityId: entity.entityId,
            blockId,
            reportId,
        }))
    }

    const xAxisDates = useMemo(() => {
        if (!viewingData) return {}

        return {
            [ImpactEnum.BASELINE as string]: viewingData.baseline ? [viewingData.baseline.startsAt, viewingData.baseline.endsAt] : undefined,
            [ImpactEnum.CURRENT as string]: viewingData.current ? [viewingData.current.startsAt, viewingData.current.endsAt] : undefined,
            [ImpactEnum.BEST as string]: viewingData.best ? [viewingData.best.startsAt, viewingData.best.endsAt] : undefined
        }
    }, [viewingData])

    if (screenUpMd) {
        return <Grid container columnSpacing="17px"
            columns={{ md: 13, lg: 13 }}
            height="100%"
            justifyContent="flex-end">
            <Grid item md={3} lg={3} sx={{ display: "flex", flexDirection: "column" }} height="100%">
                <ImpactTitle/>
                <ImpactToggleButtons value={impactType} onChange={setImpactType}/>
                <div className="impact-page-form-wrapper">
                    <ImpactForm
                        values={formValues}
                        type={impactType}
                        handlePreview={handleFormPreview}
                        onValuesChange={values => {
                            setFormValues(values)
                        }}
                        onSave={() => { setShowFileNameDialog(true) }}/>
                </div>
            </Grid>
            <Grid item md={10} lg={7}>
                <ImpactChart
                    id="impactChart"
                    screen="md"
                    data={barSeriesData}
                    labelData={labelData as LabelSeriesPoint[]}
                    xTickFormat={((d: ImpactEnum, tickWidth?: number) => {
                        if (!xAxisDisplaySet.has(d)) {
                            return ""
                        }

                        if (d === ImpactEnum.CURRENT) {
                            return <ImpactChartXTick
                                width={tickWidth}
                                label={getXTickLabel(d, xAxisDates[d], "md")}
                                popover={({ handleClose }) => <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DateCalendar
                                        value={moment(currentStart)}
                                        onChange={(val) => { handleChangeCurrentStart(val.toISOString()); handleClose() }}
                                        shouldDisableDate={(d) => shouldDisableDate(d, moment(formValues.baselineStartDate), formValues.baselinePeriod as unitOfTime.Base)}
                                    />
                                </LocalizationProvider>}
                            />
                        }

                        return <ImpactChartXTick label={getXTickLabel(d, xAxisDates[d], "md")}/>
                    })}
                    onClick={(value) => {
                        if (value.type !== "data" && value.delta > 0) {
                            setBarSelection(value as ImpactComparisonChartSeriesPoint)
                        }
                    }}
                />
            </Grid>
            <Grid item md={3} lg={3} style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                paddingBottom: 22
            }}>
                <ImpactReportsList handleUpdate={handleUpdateReport} handleClick={handleClickReport} handleDestroy={handleDestroyReport}/>
                <ImpactTooltip type={impactType} data={barSelection}/>
            </Grid>
            <InputDialog
                title="Save report as"
                placeholder="Report name"
                open={showFileNameDialog}
                handleClose={() => { setShowFileNameDialog(false) }}
                handleOk={(fileName) => { handleSaveReport(fileName) }}
            />
        </Grid>
    }

    return <Grid container direction="column" columnSpacing="17px" columns={4} height={"100%"} sx={{ overflow: "hidden" }}>
        <Grid item>
            <ImpactTitle/>
        </Grid>
        <Grid item marginBottom="20px">
            <ImpactToggleButtons value={impactType} onChange={setImpactType} sx={{ width: 233 }}/>
            <DrawerButton
                label={(
                    <>
                        <TuneIcon sx={{
                            transform: "rotate(90deg)",
                            fontSize: "18px"
                        }}/>{screenDownSm ? "" : "Filter"}
                    </>
                )}
                open={showDrawer}
                handleOpen={() => {
                    setShowDrawer(true)
                }}
                handleClose={() => {
                    setShowDrawer(false)
                }}
                container={document.getElementById("impactPage")}
            >
                <ImpactForm
                    handlePreview={handleFormPreview}
                    values={formValues}
                    type={impactType}
                    onValuesChange={values => {
                        setFormValues(values)
                    }}
                    onSave={() => { setShowFileNameDialog(true) }}/>
            </DrawerButton>
        </Grid>
        <Grid item flexGrow={1}>
            <ImpactChart
                id="impactChartSm"
                screen="sm"
                data={barSeriesData}
                labelData={labelData as LabelSeriesPoint[]}
                xTickFormat={((d: ImpactEnum, tickWidth?: number) => {
                    if (!xAxisDisplaySet.has(d)) {
                        return ""
                    }

                    if (d === ImpactEnum.CURRENT) {
                        return <ImpactChartXTick
                            width={tickWidth}
                            label={getXTickLabel(d, xAxisDates[d], "sm")}
                            popover={({ handleClose }) => <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DateCalendar
                                    value={moment(currentStart)}
                                    onChange={(val) => { handleChangeCurrentStart(val.toISOString()); handleClose() }}
                                    shouldDisableDate={(d) => shouldDisableDate(d, moment(formValues.baselineStartDate), formValues.baselinePeriod as unitOfTime.Base)}
                                />
                            </LocalizationProvider>}
                        />
                    }

                    return <ImpactChartXTick label={getXTickLabel(d, xAxisDates[d], "sm")}/>
                })}
                onClick={(value) => {
                    if (value.type !== "data" && value.delta > 0) {
                        setBarSelection(value as ImpactComparisonChartSeriesPoint)
                    }
                }}
                hideFootnote
            >
            </ImpactChart>
            <Drawer
                anchor={"bottom"}
                open={!!barSelection}
                close={() => {
                    setBarSelection(null)
                }}
                container={() => document.getElementById("impactChartSm")}
                variant="temporary"
                sx={{
                    bottom: "22px",
                    ".MuiDrawer-paper": {
                        borderRadius: "0px 0px 4px 4px",
                        boxShadow: "none",
                        border: "1px solid #C1C7CE",

                    }
                }}
            >
                <Box padding="16px">
                    <ImpactTooltip type={impactType} data={barSelection}/>
                </Box>
            </Drawer>
        </Grid>
        <InputDialog
            title="Save report as"
            placeholder="Report name"
            open={showFileNameDialog}
            handleClose={() => { setShowFileNameDialog(false) }}
            handleOk={(fileName) => { handleSaveReport(fileName) }}
        />
    </Grid>
}

const shouldDisableDate = (d: Moment, start: Moment, period: unitOfTime.Base) => {
    const now = moment()
    return d.isAfter(now.subtract(1, period)) || d.isBefore(start)
}

const getFormValues = (viewing: ImpactReportModel) => {
    return {
        process: viewing.blockId,
        baselinePeriod: viewing.variables.unitOfTime,
        baselineStartDate: viewing.startsAt,
        annualOperatingHours: viewing.variables.operatingHoursYear,
        operationCostPerHour: viewing.variables.costPerOperatingHour,
        yearlyBaseOutput: viewing.variables.output,
        marginPerPiece: viewing.variables.marginPerPiece,
        benchmark: viewing.variables.benchmark,
        currency: viewing.variables.currency
    }
}

const getDefaultFormValues = (entity: EntityModel, root?: BlockModel): ImpactFormValues => {
    const entityCreateDate = moment(entity.createdAt)
    const defaultStartDate = moment().subtract(1, "year")
    const baselineStartDate = entityCreateDate.isBefore(defaultStartDate) ? defaultStartDate : entityCreateDate

    return {
        process: root ? root.blockId : null,
        baselinePeriod: "weeks",
        baselineStartDate: baselineStartDate.toISOString(),
        annualOperatingHours: "3750",
        operationCostPerHour: "200",
        yearlyBaseOutput: "2500000",
        marginPerPiece: "0.50",
        benchmark: "80",
        currency: "USD"
    }
}

const validateRoiParams = (values: ImpactFormValues) => !(!values.process || !values.baselinePeriod || !values.baselineStartDate)

const getImpactParameters = (values: ImpactFormValues, specifiedCurrent?: Moment) => {
    if (!validateRoiParams(values)) return {}

    let current: any
    if (specifiedCurrent) {
        current = {}
        current.starts_at = specifiedCurrent.clone().toISOString()
        current.ends_at = specifiedCurrent.clone().add(1, values.baselinePeriod as unitOfTime.Base).toISOString()
    }

    return {
        blockId: values.process,
        startsAt: values.baselineStartDate,
        period: values.baselinePeriod,
        variables: pickBy({
            unit_of_time: values.baselinePeriod,
            operating_hours_year: values.annualOperatingHours,
            cost_per_operating_hour: values.operationCostPerHour,
            output: values.yearlyBaseOutput,
            margin_per_piece: values.marginPerPiece,
            benchmark: values.benchmark,
            currency: values.currency,
            current
        }, val => val)
    }
}

const getAnnualisedValue = (reportVariables: ImpactReportVariables, impactType: ImpactTypeEnum) => {
    let annualisedValue = 1
    let withAnnualisedValue = false
    if (impactType === ImpactTypeEnum.COST_SAVINGS && reportVariables.operatingHoursYear && reportVariables.costPerOperatingHour) {
        annualisedValue = +reportVariables.operatingHoursYear * +reportVariables.costPerOperatingHour
        withAnnualisedValue = true
    } else if (impactType === ImpactTypeEnum.REVENUE_GAIN && reportVariables.output && reportVariables.marginPerPiece) {
        annualisedValue = +reportVariables.output * +reportVariables.marginPerPiece
        withAnnualisedValue = true
    }

    return { annualisedValue, withAnnualisedValue }
}

const getBarLabel = (data: ImpactComparisonChartSeriesPoint, currency: string = "USD") => {
    if (data.type === "delta") {
        const currencyFormatter = new Intl.NumberFormat(navigator.language, {
            currency,
            style: "currency",
            notation: "compact",
            compactDisplay: "short",
        })
        const value = data.delta as number
        return data.withAnnualisedValue ? currencyFormatter.format(value) : `${round(value, 0)}%`
    }

    return `${round(data.y, 1)}%`
}

const isBarSelected = (selection: ImpactComparisonChartSeriesPoint | null, value: string) => {
    return selection ? selection.x === value : false
}

export default ImpactResponsiveScreen
