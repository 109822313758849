/* eslint-disable react/prop-types */

// LIBRARIES
import _ from 'lodash';
import * as d3 from 'd3';
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

// ACTIONS
import { getTrackedIssuesRequest } from '../../../store/old/TrackedIssues/TrackedIssues.action';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import {
    Comment,
    Label,
    parseCommentArguments,
    parseLabelArguments,
} from '../../models';

// COMPONENTS
import IssueTrackerComponent from './IssueTracker.component';

// SERVICES
import { api_getAssetLabels } from '../../../store/old/Labels/Labels.services';
import {
    api_createTrackedIssueComment,
    api_getTrackedIssueComments,
} from '../../../store/old/Comments/Comments.services';

// FLASH
import { errorFlash } from '../../components/Flash';
import { fetchBlocksIssues } from '../../../store/old/Blocks/Blocks.action';
import { withIssues } from '../../Wrappers/HOCs/withIssues';


class IssueTrackerSimple extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            labels: [],
            comments: [],
        };

        props.fetchBlocksIssues(props.entity.entity_id);
        props.getTrackedIssues();
    }

    get dateRange() {
        const { timezone } = this.props.entity;

        return {
            lower: moment().utcOffset(timezone).startOf('day').subtract(30, 'days'),
            upper: moment().utcOffset(timezone).endOf('day').add(1, 'day'),
        };
    }

    FetchLabels(trackedIssue) {
        const { assets } = trackedIssue;

        const promises = assets.map(({ asset_id }) =>
            api_getAssetLabels(
                this.props.entity.entity_id,
                asset_id, 
                {
                    date_range: this.dateRange,
                    type: ['issue'],
                }
            )
        );

        return Promise.all(promises);
    }

    fetchComments(trackedIssue) {
        return api_getTrackedIssueComments(this.props.entity.entity_id, trackedIssue.tracked_issue_id);
    }

    addComment(trackedIssue, comment, cb) {
        api_createTrackedIssueComment(
            this.props.entity.entity_id, 
            trackedIssue.tracked_issue_id, 
            { type: 'tracked_issue', message: comment }
        ).then((res) => {
            this.setState({
                comments: this.state.comments.concat(
                    new Comment(...parseCommentArguments(res))
                ),
            });
            cb && cb(res);
        }).catch((e) => errorFlash(e));
    }

    fetchDetails(row) {
        const promises = [
            this.FetchLabels(row), // returns an array of promises
            this.fetchComments(row),
        ];

        Promise.all(promises)
            .then((res) => {
                const [responseLabels, responseComments] = res;

                const labels = _.flatten(responseLabels)
                    .sort((a, b) => new Date(a.from) - new Date(b.from))
                    .map((l) => new Label(...parseLabelArguments(l)))
                    .filter((l) => {
                        return (
                            l.issue &&
              !_.isEmpty(l.issue) &&
              l.issue.issue_id === row.issue_id
                        );
                    });

                const comments = responseComments
                    .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                    .map((c) => new Comment(...parseCommentArguments(c)));

                this.setState({ labels, comments });
            })
            .catch((e) => errorFlash(e));
    }

    get occurrenceTimeSeries() {
        const { timezone } = this.props.entity;

        const compiledIssueLabels = this.state.labels.reduce((acc, curr) => {
            const day = moment(curr.from)
                .utcOffset(timezone)
                .startOf('day')
                .toISOString();
            return {
                ...acc,
                [day]: acc[day] ? acc[day].concat(curr) : [curr],
            };
        }, {});

        const [from, to] = [
            this.dateRange.lower.toDate(),
            this.dateRange.upper.toDate(),
        ];

        return d3.timeDay.range(from, to).map((time) => {
            time = moment(time).utcOffset(timezone).startOf('day');
            const val = (compiledIssueLabels[time.toISOString()] || []).length;
            const int = 1000 * 60 * 60 * 24;

            return { time: time.toDate(), int, val };
        });
    }

    render() {
        return (
            <IssueTrackerComponent
                {...(this.props)}
                {...(this.state)}
                fetchSelectionDetails={this.fetchDetails.bind(this)}
                occurrences={this.occurrenceTimeSeries}
                addComment={this.addComment.bind(this)}
            />
        );
    }
}

const mapStateToProps = (appState) => {
    return {
        entity: currentEntitySelector(appState),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTrackedIssues: (callback) =>
            dispatch(getTrackedIssuesRequest(callback)),
        fetchBlocksIssues: (entityId, callback) =>
            dispatch(fetchBlocksIssues(entityId, callback)),
    };
};

const IssueTracker = connect(
    mapStateToProps,
    mapDispatchToProps
)(IssueTrackerSimple);

export default withIssues(IssueTracker);
