import React, { useState } from 'react';
import './index.scss';

import { SPAWrapper } from "../../components/SPAWrapper";
import SkuSeriesFilter from './components/SkuSeriesFilter';
import SkuReportTable from './components/SkuReportTable';
import SkuReportSummaryTable from './components/SkuReportSummaryTable';
import ReportColumnsSelect from './components/ReportColumnsSelect';
import ReportExportButton from './components/ReportExportButton';
import OeeSkuButton from './components/OeeSkuButton';
import ToggleSummary from './components/ToggleSummary';
import { withSkus } from '../../Wrappers/HOCs/withSkus';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSkuReportData, setSkuReportColumns } from '../../../store/old/UI/Report/Report.action';

const MIN_COLUMNS_LENGTH = 4;

const SkuReportPage = () => {
    const dispatch = useDispatch();
    const [showSummary, setShowSummary] = useState(true);
    const selectedColumns = useSelector(appState => appState.ui.report.skuColumns);

    return <SPAWrapper className="report-page">
        <div className="report-page__header">
            <SkuSeriesFilter/>
        </div>
        <div className="report-page__sub-header">
            <OeeSkuButton onClick={(options) => dispatch(fetchSkuReportData(options))}/>
            <ReportColumnsSelect 
                onSelect={(values) => { dispatch(setSkuReportColumns(values)) }}
                selectedKeys={selectedColumns}
            />
            <ReportExportButton
                columnsLength={MIN_COLUMNS_LENGTH + selectedColumns.length}
                tableId="skuReportTable" 
                fileName="sku-report.csv"
            />
            <ToggleSummary onClick={() => setShowSummary(!showSummary)}/>
        </div>
        <div className="report-page__summary">
            { showSummary ? <SkuReportSummaryTable/> : null }
        </div>
        <div className="report-page__content">
            <SkuReportTable/>
        </div>
    </SPAWrapper>
}

export default withSkus(SkuReportPage);
