import React, { useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch } from "../../../../store/hooks"
import { DashboardConstants as K } from '../../../../store/old/UI/Dashboard/Dashboard.constants';
import { DashboardContext } from '../DashboardContext';
import AukButton from '../../../components/AukButton';
import BlockCSVExportModal from '../../Blocks/BlockCSVExportModal';
import { entityBlock } from '../../../../store/old/Blocks/Blocks.selector';
import {
    ChangeDashboardMode,
    ChangeDashboardViewMode,
} from '../../../../store/old/UI/Dashboard/Dashboard.action';
import { hasRolePermission } from '../../../components/Permission';
import translate from '../../../utils/translate';
import { Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const VIEW_MODES = [
    { id: 1, label: 'Time', value: K.VIEW.TIME_SERIES },
    { id: 2, label: 'Sum', value: K.VIEW.SUMMARY },
    { id: 3, label: 'Label', value: K.VIEW.LABEL },
];

const KEYS = {
    [K.MODES.VIEW]: '1',
    [K.MODES.EDIT]: '2',
    [K.VIEW.TIME_SERIES]: '1-1',
    [K.VIEW.SUMMARY]: '1-2',
    [K.VIEW.LABEL]: '1-3'
}

export const ControlButtons = () => {
    const {
        store,
        authUser,
        canViewDashboard,
        canEditDashboard,
        canFullyEditDashboard,
    } = useContext(DashboardContext);

    const { viewMode, mode } = store;

    const dispatch = useDispatch();
    const block = useSelector(entityBlock);
    const [exportVisible, setExportVisibility] = useState(false);
    const changeMode = (m) => dispatch(ChangeDashboardMode(m));
    const changeViewMode = (m) => {
        changeMode(K.MODES.VIEW)
        dispatch(ChangeDashboardViewMode(m))
    }

    const selectedMenuKey = useMemo(() => {
        if (mode === K.MODES.EDIT) {
            return [KEYS[K.MODES.EDIT]]
        };

        return [KEYS[viewMode]]
    }, [mode, viewMode])

    const isViewing = mode === K.MODES.VIEW;
    const hasEditorRights = hasRolePermission(authUser.role_name, 'editor');

    return (
        <>
            <Dropdown
                trigger={['click']}
                menu={{
                    items: [
                        {
                            key: '1',
                            label: <span onClick={() => {changeMode(K.MODES.VIEW)}}>View</span>,
                            disabled: !canViewDashboard,
                            children: [
                            {
                                key: '1-1',
                                label: <div onClick={() => {changeViewMode(K.VIEW.TIME_SERIES)}}>Time</div>,
                            },
                            {
                                key: '1-2',
                                label: <div onClick={() => {changeViewMode(K.VIEW.SUMMARY)}}>Sum</div>,
                            },
                            {
                                key: '1-3',
                                label: <div onClick={() => {changeViewMode(K.VIEW.LABEL)}}>Label</div>,
                            },
                            ],
                        },
                        {
                            label: <div onClick={() => {changeMode(K.MODES.EDIT)}}>Edit</div>,
                            key: '2',
                            disabled: !(canEditDashboard || canFullyEditDashboard)
                        },
                        {
                        type: 'divider',
                        },
                        {
                            disabled: !canViewDashboard || !hasEditorRights,
                            label: (
                                <div onClick={() => setExportVisibility(true)}>
                                    Export CSV
                                </div>
                            ),
                            key: '3',
                        },
                    ],
                    selectedKeys: selectedMenuKey
                }}
            >
                <a className="p-2" style={{color: '#808080', fontWeight: 300}} onClick={(e) => e.preventDefault()}>
                    <Space key={isViewing ? 1 : 0}>
                        {
                            isViewing ?  <i className="fas fa-eye" /> : <i className="fas fa-pencil-alt" />
                        }
                        <DownOutlined />
                    </Space>
                </a>
            </Dropdown>
            <BlockCSVExportModal
                 visible={exportVisible}
                 onCancel={() => setExportVisibility(false)}
                 block={block}
             />
        </>
    )
};
