/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { max, round } from 'lodash';
import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
} from '@ant-design/icons';

import { getBrowserTz, getRangeFromSpan } from '../../utils/controls';
import { withEntity } from '../../Wrappers/HOCs/withEntity';
import AukButton from '../AukButton';

import './WeekCalendar.scss';

const dateFormat = 'DD MMM';
const dayFormat = 'dddd';
const headerHeight = 50;
const hourlyCellHeight = 40;
const columnPaddingTop = 10;
const hoursInDay = Array(24)
    .fill(undefined)
    .map((_, i) => i);

const WeekCalendarSimple = (props) => {
    const {
        className = '',
        current,
        entity,
        events,
        cellHeight = hourlyCellHeight,
        onNavigate,
    } = props;
    const { tzHours } = entity;
    const browserTimezone = useMemo(getBrowserTz, []);
    const [hovered, setHovered] = useState(null);

    const findWeek = useCallback(
        (t) =>
            getRangeFromSpan(
                t,
                { size: 1, unit: 'week' },
                browserTimezone,
                tzHours
            ),
        []
    );

    const [week, setWeek] = useState(findWeek(current));

    const [weekStart, weekEnd] = week;
    const handleClickPrev = () => {
        setWeek(findWeek(weekStart.clone().subtract(1, 'week')));
    };
    const handleClickNext = () => {
        setWeek(findWeek(weekStart.clone().add(1, 'week')));
    };
    const handleClickToday = () => {
        setWeek(findWeek(moment()));
    };

    useEffect(() => onNavigate && onNavigate(week), [week]);

    const dates = useMemo(() => {
        let current = weekStart;
        const result = [];

        while (current.isBefore(weekEnd)) {
            result.push(current);
            current = current.clone().add(1, 'day');
        }

        return result;
    }, [weekStart, weekEnd]);

    const lastSunday = useMemo(
        () => weekStart.clone().subtract(1, 'day'),
        [weekStart]
    );
    const nextMonday = useMemo(() => weekEnd.clone(), [weekEnd]);

    const _events = useMemo(() => {
        const eventsByDate = events
            .map((event, index) =>
                generateDayEvents(current, event, browserTimezone, index)
            )
            .reduce((acc, curr) => acc.concat(...curr), [])
            .filter((_e) => !(_e.to.isBefore(weekStart) || _e.from.isAfter(weekEnd)));

        return eventsByDate.reduce((acc, curr) => {
            const date = moment(curr.from).date();
            return {
                ...acc,
                [date]: acc[date] ? acc[date].concat(curr) : [curr],
            };
        }, {});
    }, [events, current, weekStart, weekEnd]);

    return (
        <div
            className={classNames({
                'auk-calendar': true,
            })}
        >
            <div className="auk-calendar-header">
                <WeekCalendarAnchorButton onClick={handleClickToday} />
            </div>
            <div
                className={classNames({
                    'auk-calendar': true,
                    'auk-calendar-week': true,
                    [className]: !!className,
                })}
            >
                <WeekCalendarNav
                    icon={<ArrowLeftOutlined />}
                    date={lastSunday}
                    onClick={handleClickPrev}
                />
                <div className="auk-calendar-week-days">
                    <div className="auk-calendar-week-days__header">
                        {dates.map((date, i) => (
                            <WeekHeaderCell key={i} date={date} current={current} />
                        ))}
                    </div>
                    <div className="auk-calendar-week-days__grid">
                        <CurrentTimeIndicator
                            week={week}
                            current={current}
                            cellHeight={cellHeight}
                        />
                        <HourlyAxis cellHeight={cellHeight} />
                        {dates.map((d, i) => {
                            const cards = _events[moment(d).date()];
                            return (
                                <WeekGridColumn key={i} cellHeight={cellHeight}>
                                    <>
                                        {cards
                                            ? cards.map((e, n) => (
                                                <WeekCalendarEventCard
                                                    onMouseEnter={() => setHovered(e.shift_id)}
                                                    onMouseLeave={() => setHovered(null)}
                                                    hovered={hovered === e.shift_id}
                                                    key={n}
                                                    data={e}
                                                    cellHeight={cellHeight}
                                                />
                                            ))
                                            : null}
                                    </>
                                </WeekGridColumn>
                            );
                        })}
                    </div>
                </div>
                <WeekCalendarNav
                    icon={<ArrowRightOutlined />}
                    date={nextMonday}
                    onClick={handleClickNext}
                />
            </div>
        </div>
    );
};

export const WeekCalendar = withEntity(WeekCalendarSimple);

const WeekCalendarNav = ({ icon, date, onClick }) => {
    return (
        <div className="auk-calendar-week__nav" onClick={onClick}>
            <div className="auk-calendar-week__item auk-calendar-week__nav-arrow">
                {icon}
            </div>
            <div className="auk-calendar-week__item" style={{ marginBottom: 4 }}>
                {date.format(dateFormat)}
            </div>
            <div className="auk-calendar-week__item auk-calendar-week__item--bold">
                {date.format(dayFormat)}
            </div>
        </div>
    );
};

const WeekHeaderCell = ({ date, current }) => {
    const isActive = useMemo(
        () => current.dayOfYear() === date.dayOfYear(),
        [date, current]
    );
    const isHistory = useMemo(
        () => current.dayOfYear() > date.dayOfYear(),
        [date, current]
    );
    return (
        <div
            className={classNames({
                'auk-calendar-week-days__header-cell': true,
                'auk-calendar-week-days__header-cell--active': isActive,
                'auk-calendar-week-days__header-cell--blur': isHistory,
            })}
        >
            <div className="auk-calendar-week__item">{date.format(dateFormat)}</div>
            <div className="auk-calendar-week__item auk-calendar-week__item--bold">
                {date.format(dayFormat)}
            </div>
        </div>
    );
};

const HourlyAxis = ({ cellHeight }) => {
    const getLabel = (n) => {
        const multiple = n / 12;
        const result = n % 12;
        const meridiem = Math.floor(multiple % 2) === 0 ? 'AM' : 'PM';
        const time = result == 0 ? 12 : result;

        return `${time} ${meridiem}`;
    };

    return (
        <div
            className="auk-calendar-week-axis"
            style={{ height: cellHeight * 23 + headerHeight }}
        >
            {hoursInDay.concat(hoursInDay[0]).map((hour, i) => (
                <div
                    className="auk-calendar-week-axis-item"
                    key={i}
                    style={{
                        height: i !== 24 ? cellHeight : 0,
                        borderBottom: i !== 24 ? '1px solid #eee' : 'none',
                    }}
                >
                    <div className="auk-calendar-week-axis-label">{getLabel(hour)}</div>
                </div>
            ))}
        </div>
    );
};

const CurrentTimeIndicator = ({ current, cellHeight, week }) => {
    if (!current.isBetween(week[0], week[1])) return null;

    const midnight = moment().startOf('day');
    const minFromMidnight = moment.duration(current.diff(midnight)).as('minutes');
    const top = round((minFromMidnight / 60) * cellHeight, 0) + columnPaddingTop;
    return (
        <div className="auk-calendar-week__current_indicator" style={{ top }} />
    );
};

const WeekGridColumn = ({ cellHeight, children }) => (
    <div
        className="auk-calendar-week-days__grid-column"
        style={{ height: cellHeight * 23 + headerHeight }}
    >
        {children}
    </div>
);

const WeekCalendarEventCard = ({
    data,
    cellHeight,
    hovered,
    onMouseEnter,
    onMouseLeave,
}) => {
    const { from, to, render, style, hasElapsed, onClick, index } =
    data;

    const midnight = from.clone().startOf('day');
    const minFromMidnight = moment.duration(from.diff(midnight)).as('minutes');
    const durationMin = moment.duration(to.diff(from)).as('minutes');
    const top = round((minFromMidnight / 60) * cellHeight, 0) + columnPaddingTop;
    const height = max([round((durationMin / 60) * cellHeight), cellHeight]);

    return (
        <div
            className={classNames({
                'auk-calendar-week-card': true,
                'auk-calendar-week-card--fade': hasElapsed,
                'auk-calendar-week-card--hovered': hovered,
            })}
            style={{ height, top, zIndex: index }}
            onClick={(e) => {
                e.stopPropagation();
                !hasElapsed && onClick();
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <div className="auk-calendar-week-card__inner" style={style}>
                {render ? render(hasElapsed) : null}
            </div>
        </div>
    );
};

export const WeekCalendarAnchorButton = (props) => {
    return <AukButton.Outlined onClick={props.onClick}>Today</AukButton.Outlined>;
};

const generateDayEvents = (current, event, tz, index) => {
    const eventStartDate = moment(event.from).utcOffset(tz).startOf('day');
    const eventEndDate = moment(event.to).utcOffset(tz).startOf('day');
    const hasElapsed = moment(event.to).isBefore(current);
    const style = event.styles(hasElapsed);

    if (eventStartDate.isSame(eventEndDate))
        return [
            {
                ...event,
                from: moment(event.from),
                to: moment(event.to),
                hasElapsed,
                index,
                style: { ...style, borderRadius: 4 },
            },
        ];

    const eventStart = moment(event.from);
    const eventEnd = moment(event.to);
    let pointer = eventStart.clone();
    const _events = [];

    while (pointer.isBefore(eventEnd)) {
        const next = moment(pointer).add(1, 'day').startOf('day');
        const from = moment(pointer);
        const to = eventEnd.isBefore(next) ? eventEnd : next.clone();
        const hasSpilloverDay = eventEnd.isAfter(next);
        const borderRadius = `${from.isSame(eventStart) ? '4px 4px' : '0px 0px'} ${
            to.isSame(eventEnd) ? '4px 4px' : '0px 0px'
        }`;

        _events.push({
            ...event,
            from,
            to,
            showNext: hasSpilloverDay,
            hasElapsed,
            index,
            style: { ...style, borderRadius, zIndex: index },
            render: hasSpilloverDay ? undefined : event.render, // dont provide render function for first half of cross-day event
            _from: from.toISOString(),
            _to: to.toISOString(),
        });

        pointer = next;
    }

    return _events;
};
