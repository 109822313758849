import React, { useEffect, useState } from 'react';
import './index.scss';

import { SPAWrapper } from "../../components/SPAWrapper";
import TimeSeriesFilter from './components/TimeSeriesFilter';
import TimeReportTable from './components/TimeReportTable';
import TimeReportSummaryTable from './components/TimeReportSummaryTable';
import ReportColumnsSelect from './components/ReportColumnsSelect';
import ReportExportButton from './components/ReportExportButton';
import ToggleSummary from './components/ToggleSummary';
import OeeSkuButton from './components/OeeSkuButton';
import { fetchOeeReportData, setOeeReportColumns } from '../../../store/old/UI/Report/Report.action';
import { useDispatch, useSelector } from 'react-redux';

const MIN_COLUMNS_LENGTH = 3;

const TimeBasedReportPage = () => {
    const dispatch = useDispatch();
    const [showSummary, setShowSummary] = useState(true);
    const selectedColumns = useSelector(appState => appState.ui.report.oeeColumns);

    return <SPAWrapper className="report-page">
        <div className="report-page__header">
            <TimeSeriesFilter/>
        </div>
        <div className="report-page__sub-header">
            <OeeSkuButton onClick={(options) => dispatch(fetchOeeReportData(options))}/>
            <ReportColumnsSelect 
                onSelect={(values) => { dispatch(setOeeReportColumns(values)) }}
                selectedKeys={selectedColumns}
            />
            <ReportExportButton 
                tableId="timeBasedReportTable" 
                columnsLength={MIN_COLUMNS_LENGTH + selectedColumns.length} 
                fileName="time-based-report.csv"
            />
            <ToggleSummary onClick={() => setShowSummary(!showSummary)}/>
        </div>
        <div className="report-page__summary">
            { showSummary ? <TimeReportSummaryTable/> : null }
        </div>
        <div className="report-page__content">
            <TimeReportTable/>
        </div>
    </SPAWrapper>
}

export default TimeBasedReportPage;
