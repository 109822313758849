import moment from "moment"
import { formatValidNumber, uiDatetimeFormat, } from '../../utils/helpers';
import findProductionSpeed from "../../../lib/lean_ops/findProductionSpeed";
import findMeanTimeBetweenFailure from "../../../lib/lean_ops/findMeanTimeBetweenFailure";
import findMeanTimeToRepair from "../../../lib/lean_ops/findMeanTimeToRepair";

export const reportAccessors = {
    START: (record) => moment(record.start).format(uiDatetimeFormat),
    END: (record) => moment(record.end).format(uiDatetimeFormat),
    OEE1: (record) => record.oee.factors[0].value,
    OEE2: (record) => record.oee.factors[1].value,
    OUTPUT: (record) => record.oee.totalOutput,
    DEFECTS: (record) => record.oee.totalDefects,
    CALENDAR_TIME: (record) => getCalendarTimeSeconds(record) / 3600, // hours
    SCHEDULED_PRODUCTION_TIME: (record) => getScheduledProductionTimeSeconds(record) / 3600, // hours
    GROSS_OPERATING_TIME: (record) => getGrossOperatingTimeSeconds(record) / 3600, // hours
    NET_OPERATING_TIME: (record) => getNetOperatingTimeSeconds(record) / 3600, // hours
    EFFECTIVE_TIME: (record) => getEffectiveTimeSeconds(record) / 3600, // hours
    UNUTILISED: (record) => record.oee.aggregate_oee.uu * getCalendarTimeSeconds(record) / 3600 / 100,
    BREAKDOWNS: (record) => record.oee.aggregate_oee.bd * getCalendarTimeSeconds(record) / 3600 / 100,
    SETUP: (record) => record.oee.aggregate_oee.st * getCalendarTimeSeconds(record) / 3600 / 100,
    MINOR_STOPS: (record) => record.oee.aggregate_oee.ms * getCalendarTimeSeconds(record) / 3600 / 100,
    SPEED_LOSS: (record) => record.oee.aggregate_oee.sl * getCalendarTimeSeconds(record) / 3600 / 100,
    REJECTS: (record) => record.oee.aggregate_oee.rj * getCalendarTimeSeconds(record) / 3600 / 100,
    REWORK: (record) => record.oee.aggregate_oee.rw * getCalendarTimeSeconds(record) / 3600 / 100,
    NO_DATA: (record) => record.oee.aggregate_oee2.na * getCalendarTimeSeconds(record) / 3600 / 100,
    UNSCHEDULED: (record) => record.oee.aggregate_oee.us * getCalendarTimeSeconds(record) / 3600 / 100,
    PLANNED_DOWNTIME: (record) => record.oee.aggregate_oee.pd * getCalendarTimeSeconds(record) / 3600 / 100,
    LOA_LOSS: (record) => record.oee.factors[2].value,
    AVA_LOSS: (record) => record.oee.factors[3].value,
    PER_LOSS: (record) => record.oee.factors[4].value,
    QUA_LOSS: (record) => record.oee.factors[3].value,
    PRODUCTION_SPEED: (record) => formatValidNumber(findProductionSpeed(record.oee.totalOutput, getScheduledProductionTimeSeconds(record)) * 60, '--'),
    CYCLE_TIME: (record) => formatValidNumber((getScheduledProductionTimeSeconds(record) / 60) / record.oee.totalOutput, '--'),
    MTBF: (record) => {
        const bd = getBreakdowns(record);

        if (!bd.length) return '--';
        return findMeanTimeBetweenFailure(record.oee.waterfall, bd) / 3600;
    },
    MTTR: (record) => {
        const bd = getBreakdowns(record);

        if (!bd.length) return '--';
        return findMeanTimeToRepair(bd) / 3600;
    },
    LABEL_DURATION: (record) => record.label.duration / 3600,
    LABEL_NOTES: (record) => record.label.notes
}

const getCalendarTimeSeconds = (record) => record.oee.waterfall[0].duration;
const getScheduledProductionTimeSeconds = (record) => record.oee.waterfall[4].duration;
const getGrossOperatingTimeSeconds = (record) => record.oee.waterfall[8].duration;
const getNetOperatingTimeSeconds = (record) => record.oee.waterfall[11].duration;
const getEffectiveTimeSeconds = (record) => record.oee.waterfall[14].duration;
const getBreakdowns = (record) => record.oee.effective_labels.filter(l => l.oee === 'bd');

