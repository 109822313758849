import { DatePicker, Form, Select, Space, TreeSelect } from 'antd';
import React, { useMemo } from 'react';

import './ReportFilter.scss'
import { defaultFilterOption, regexMatch } from '../../../utils/helpers';
import { getBlocksTreeData } from '../../../utils/blocks';
import { entityBlock } from '../../../../store/old/Blocks/Blocks.selector';
import { useDispatch, useSelector } from 'react-redux';
import AukButton from '../../../components/AukButton';
import { CaretRightOutlined, EllipsisOutlined } from '@ant-design/icons';
import { arraySkus, skusSelector } from '../../../../store/old/Sku/Sku.selector';
import { setSkuReportFilter } from '../../../../store/old/UI/Report/Report.action';
import ReportRangePicker from './ReportRangePicker';

const VALIDATE = {
    DATE_RANGE: [
        {
            required: true,
            message: 'Date range is required.',
        }
    ],
    SKU_ID: [
        {
            required: true,
            message: 'SKU is required.',
        }
    ],
    BLOCK: [
        {
            required: true,
            message: 'Asset is required.',
        }
    ],
}

const SkuSeriesFilter = (props) => {
    const dispatch = useDispatch();
    const skusState = useSelector(skusSelector);
    const filterState = useSelector(appState => appState.ui.report.skuFilter);
    const rootBlock = useSelector(entityBlock);
    const [form] = Form.useForm();

    const blocksTreeData = useMemo(() => {
        return [getBlocksTreeData(rootBlock, { disabledAccessor: (n) => !n.asset && !n.children.length })]
    }, [rootBlock]);


    const skuOptions = useMemo(() => {
        return arraySkus(skusState).map(sku => ({
            value: sku.sku_id,
            label: sku.codeName
        }))
    }, [skusState]);

    return (
        <Space>
            <Form
                name="sku-series-filter"
                id="skuSeriesFilter"
                form={form}
                className="report-filter"
                onFinish={() => {
                    const formState = form.getFieldsValue(true);
                    dispatch(setSkuReportFilter(formState));
                }}
            >
                <Form.Item
                    colon={false}
                    label={'Date Range'}
                    name="dateRange"
                    initialValue={filterState.dateRange}
                    rules={VALIDATE.DATE_RANGE}
                >
                    <ReportRangePicker />
                </Form.Item>
                <Form.Item
                    colon={false}
                    label={'Asset'}
                    name="blockIds"
                    initialValue={filterState.blockIds}
                    rules={VALIDATE.BLOCK}
                >
                    <TreeSelect
                        maxTagCount={1}
                        maxTagPlaceholder={<EllipsisOutlined />}
                        treeCheckable
                        allowClear
                        style={{width: 280}}
                        treeDefaultExpandAll
                        showSearch
                        filterTreeNode={defaultFilterOption}
                        treeData={blocksTreeData}
                    />
                </Form.Item>
                <Form.Item
                    colon={false}
                    label={'SKU'}
                    name="skuIds"
                    initialValue={filterState.skuIds}
                    rules={VALIDATE.SKU_ID}
                >
                    <Select 
                        style={{width: 280}}
                        mode="multiple"
                        options={skuOptions}
                        maxTagCount={1}
                        maxTagPlaceholder={<EllipsisOutlined />}
                        showSearch
                        filterOption={(input, option) => regexMatch(option.label, input, { escape: true })}
                    />
                </Form.Item>
                <Form.Item
                    colon={false}
                    label=" "
                >
                    <AukButton.Blue htmlType="submit" ghost>
                        <CaretRightOutlined/> Apply
                    </AukButton.Blue>
                </Form.Item>
            </Form>
        </Space>
    )
}

export default SkuSeriesFilter