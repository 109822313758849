
import * as d3 from 'd3';
import React, { useContext, useMemo, useState } from 'react';
import { Collapse, Popover, Radio } from 'antd';
import { isEmpty } from 'lodash';
import { AreaChartOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { AssetDomainContext } from '.';
import AssetWidget from './AssetWidget';
import ChartEditable from './components/ChartEditable';
import OEEStackColumnChart from '../../../Charts/OEEStackColumnChart';
import { OEEDials } from '../../../Widgets';
import { ColumnChart, LabelsChart, MultiLineChart } from '../../../Charts/v1';
import { Colors, OEELegend } from '../../../Charts';
import { LabelsChartTooltip, SpeedChartTooltip, TargetsChartTooltip } from '../../../Charts/v1/tooltips/templates';
import { generateContinuousDomain } from '../../../utils/charts';
import { labelDisplayText } from '../../../utils/labels';
import { LABEL_DRAWER } from '../../Labels/components';
import AukButton from '../../../components/AukButton';
import AukTooltip from '../../../components/AukTooltip';
import findProductionSpeed from '../../../../lib/lean_ops/findProductionSpeed';

const MARGIN = { top: 10, left: 35, right: 20, bottom: 20 };

const { ISSUE_LABELLER, PRODUCTION_LABELLER, TARGET_PRODUCTION_LABELLER } = LABEL_DRAWER.TYPE;

const labelsProps = {
    margin: { top: 0, left: 35, right: 20, bottom: 0 },
    xAccessor: (d) => new Date(d.from),
    x2Accessor: (d) => new Date(d._to),
    colorAccessorLabel: (d) => d.color,
    colorAccessorText: () => '#fafafa',
    textAccessor: labelDisplayText,
    useTooltip: true,
    htmlTooltip: LabelsChartTooltip,
    keyAccessor: (d) => d.label_id,
};

const targetsProps = {
    margin: labelsProps.margin,
    xAccessor: labelsProps.xAccessor,
    x2Accessor: (d) => new Date(d.to),
    colorAccessorLabel: () => '#2176FF',
    colorAccessorText: () => '#fafafa',
    textAccessor: (d) => (d.sku ? d.sku.codeName : 'Planned Production'),
    useTooltip: true,
    htmlTooltip: TargetsChartTooltip,
    keyAccessor: (d) => d.target_id,
};

const AssetBody = (props) => {
    return (
        <div className="asset-default__body">
            <div className="asset-default__body__charts">
                <CollapsibleOEEYieldChart />
                <CollapsibleChannelAndFusionCharts skuMultipleCharts={props.skuMultipleCharts}/>
            </div>
            <div className="asset-default__body__widgets">
                <OEEDialsWidget />
                <ProductionSpeedWidget />
            </div>
        </div>
    );
};

export default AssetBody;

const CollapsibleOEEYieldChart = () => {
    const {
        asset,
        onBrushCancel,
        onBrushEnd,
        controls,
        doubleClickLabel,
        onClickOutput,
        window,
        showOutput,
    } = useContext(AssetDomainContext);

    return (
        <div className="asset-default__oee-chart">
            <Collapse ghost defaultActiveKey={[1]}>
                <Collapse.Panel
                    className="mb-2"
                    key={1}
                    header={
                        <div className="d-flex align-items-center">
                            <span className="mr-2" style={{ fontSize: 12 }}>
                Overall Equipment Effectiveness
                            </span>

                            <Popover
                                placement="rightBottom"
                                content={
                                    <span
                                        className="legend-popover-container d-flex"
                                        style={{ width: '60vw' }}
                                    >
                                        <OEELegend />
                                    </span>
                                }
                            >
                                <InfoCircleOutlined />
                            </Popover>
                        </div>
                    }
                >
                    <div
                        className="w-100 d-flex flex-column"
                        style={{ overflow: 'hidden' }}
                    >
                        <div className="d-flex justify-content-end">
                            <AukTooltip.Help
                                title={
                                    showOutput ? 'Click to show OEE' : 'Click to show output'
                                }
                            >
                                <AukButton.ToggleBlue
                                    className="d-flex align-items-center mb-2"
                                    active={showOutput}
                                    size="small"
                                    style={{
                                        marginRight: 20,
                                        opacity: showOutput ? 1 : 0.6,
                                    }}
                                    onClick={onClickOutput}
                                >
                                    <AreaChartOutlined /> Output
                                </AukButton.ToggleBlue>
                            </AukTooltip.Help>
                        </div>
                        <div style={{ height: 18 }}>
                            <LabelsChart
                                className="issue-labels-chart"
                                {...labelsProps}
                                xScale={d3.scaleTime()}
                                xDomain={window}
                                data={asset.issueLabels}
                                onDoubleClick={doubleClickLabel(
                                    ISSUE_LABELLER,
                                    asset.block,
                                    asset.oee.oee
                                )}
                            />
                        </div>
                        <div style={{ height: 140 }}>
                            {showOutput ? (
                                asset._primaryChart.dataSource.mode === '2a' ? (
                                    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                    Output chart not available for digital state inputs.
                                    </div>
                                ) : (
                                    <ColumnChart
                                        data={asset.oee.oee}
                                        xScale={d3.scaleTime()}
                                        yScale={d3.scaleLinear()}
                                        xDomain={window}
                                        yDomain={generateContinuousDomain(
                                            asset.oee.oee,
                                            (d) => d.yield
                                        )}
                                        margin={MARGIN}
                                        colorAccessor={() => Colors.green[0]}
                                        xAccessor={(d) => d.time}
                                        yAccessor={(d) => d.yield}
                                        useBrush={true}
                                        onBrushEnd={(bounds, scaled, brushElement, data) =>
                                            onBrushEnd(
                                                ISSUE_LABELLER,
                                                asset.block,
                                                bounds,
                                                scaled,
                                                data
                                            )
                                        }
                                        onBrushCancel={onBrushCancel}
                                        htmlTooltip={(d) => `Output: ${d.yield}`}
                                        showYGrid={true}
                                    />
                                )
                            ) : (
                                <OEEStackColumnChart
                                    xDomain={window}
                                    data={controls.oee2 ? asset.oee.oee2 : asset.oee.oee}
                                    margin={MARGIN}
                                    brush={{
                                        useBrush: true,
                                        onBrushEnd: (bounds, scaled, brushElement, data) =>
                                            onBrushEnd(
                                                ISSUE_LABELLER,
                                                asset.block,
                                                bounds,
                                                scaled,
                                                data
                                            ),
                                        onBrushCancel: onBrushCancel,
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};

const CollapsibleChannelAndFusionCharts = (props) => {
    const {
        asset,
        onBrushCancel,
        onBrushEnd,
        controls,
        doubleClickLabel,
        window,
        openSkuMultiple,
        skuLabels
    } = useContext(AssetDomainContext);

    const validSkuChartIndex = props.skuMultipleCharts
        .reduce((acc, curr, i) => ({...acc, [curr.chart_id]: i}), {});

    return (
        <div className="asset-default__charts">
            <Collapse ghost defaultActiveKey={asset._charts.map((c, i) => c.chart_id)}>
                {asset._charts.map((c, i) => {
                    const isPrimary = i === 0;
                    const aggregatedChartSkuLabels = skuLabels.aggregated[c.chart_id];
                    const totalSkuQuantity = aggregatedChartSkuLabels ? aggregatedChartSkuLabels.reduce((acc, curr) => acc += curr.quantity, 0) : ''
                    return (
                        <Collapse.Panel
                            key={c.chart_id}
                            header={
                                <span style={{ fontSize: 12 }}>
                                    {c.title}{' '}
                                    <i style={{ fontSize: 11 }}>({c.units})</i>
                                </span>
                            }
                        >
                            {isPrimary && (
                                <div className="asset-default__charts__labels pb-3">
                                    <div style={{ height: 18 }}>
                                        <LabelsChart
                                            className="targets-chart"
                                            {...targetsProps}
                                            xScale={d3.scaleTime()}
                                            xDomain={window}
                                            data={asset.targets}
                                            onDoubleClick={doubleClickLabel(
                                                TARGET_PRODUCTION_LABELLER,
                                                undefined,
                                                []
                                            )}
                                        />
                                    </div>
                                    <div style={{ height: 18 }}>
                                        <LabelsChart
                                            className="production-labels-chart"
                                            {...labelsProps}
                                            xScale={d3.scaleTime()}
                                            xDomain={window}
                                            data={asset.productionLabels}
                                            onDoubleClick={doubleClickLabel(
                                                PRODUCTION_LABELLER,
                                                asset._primaryChart.dataSource,
                                                asset._primaryChart.dataSource.data
                                            )}
                                        />
                                    </div>
                                </div>
                            )}
                            <div style={{ height: 140 }}>
                                <ChartEditable
                                    isPrimary={isPrimary}
                                    meta={c.dataSource}
                                    asset={asset}
                                    controls={controls}
                                    window={window}
                                    margin={MARGIN}
                                    onBrushCancel={onBrushCancel}
                                    onBrushEnd={(bounds, scaled, brushElement, data) =>
                                        onBrushEnd(PRODUCTION_LABELLER, c.dataSource, bounds, scaled, data)
                                    }
                                    onClickTitle={() => {
                                        openSkuMultiple(validSkuChartIndex[c.chart_id])
                                    }}
                                    appendTitle={totalSkuQuantity ? `/ ${totalSkuQuantity.toLocaleString()} pcs` : ''}
                                />
                            </div>
                        </Collapse.Panel>
                    );
                })}
            </Collapse>
        </div>
    );
};

const OEEDialsWidget = () => {
    const { asset, controls } = useContext(AssetDomainContext);

    const data = isEmpty(asset.oee)
        ? {
            final_effective: 0,
            loading: 0,
            availability: 0,
            performance: 0,
            quality: 0,
        }
        : {
            final_effective: asset.oee.overall.final_effective,
            loading: asset.oee.overall.loading,
            availability: asset.oee.overall.availability,
            performance: asset.oee.overall.performance,
            quality: asset.oee.overall.quality,
        };

    return (
        <AssetWidget style={{ height: 220, flexShrink: 0 }}>
            <div className="w-100 h-100 d-flex">
                <OEEDials display="column" data={data} oee2={controls.oee2} />
            </div>
        </AssetWidget>
    );
};

const yAccessor = (d) => d.val;
const xAccessor = (d) => d.time;
const yTickFormat = (d) => (`${d}`.length > 4 ? d.toExponential(1) : d);

const secToUnitTime = {
    SECOND: 1,
    MINUTE: 60,
    HOUR: 3600
}

const LINE_COLORS = ['#616161', '#616161'];
const LINE_DASH = [[], [4,4]]

const getScheduledProductionTime = d => d.waterfall[4].duration

const ProductionSpeedWidget = () => {
    const { asset, window, data: dataStore } = useContext(AssetDomainContext);
    const [unitTime, setUnitTime] = useState('MINUTE')
    
    const data = useMemo(() => {
        
        // real time speed
        const rtSpeedSeries = asset.oee.oee.map(d => {
            const scheduledProductionTime = getScheduledProductionTime(d);
            return {
                time: d.time,
                val: findProductionSpeed(d.yield, scheduledProductionTime) * secToUnitTime[unitTime]
            }
        });
        
        let totalScheduledProductionTimeSec = 0;
        let totalOutput = 0;

        const avgSpeedSeries = asset.oee.oee.map(d => {
            totalOutput += d.yield;
            totalScheduledProductionTimeSec += getScheduledProductionTime(d);
            return {
                time: d.time,
                val: totalScheduledProductionTimeSec === 0 ? 0 : (findProductionSpeed(totalOutput, totalScheduledProductionTimeSec) * secToUnitTime[unitTime]),
            }
        });

        return {
            rts: rtSpeedSeries,
            avg: avgSpeedSeries
        }
    }, [dataStore, unitTime])
    
    const currentRts = useMemo(() => {
        const last = data.rts[data.rts.length - 1];
        if (!last) return '-';
        return last.val.toFixed(1);
    }, [data])
    
    const avgSpeed = useMemo(() => {
        const last = data.avg[data.avg.length - 1];
        if (!last) return '-';
        return last.val.toFixed(1);
    }, [data])
    
    const yDomain = generateContinuousDomain(data.rts, yAccessor);

    return (
        <AssetWidget style={{ flexGrow: 1 }}>
                <>
                    <div
                        className='d-flex'
                        style={{
                            position: 'absolute',
                            zIndex: 2,
                            fontSize: 11,
                            left: '1em',
                            fontWeight: 400,
                            top: 10
                        }}
                    >
                        <ProductionSpeedChartCounter
                            title="Current Speed"
                            value={currentRts}
                            unit={unitTime.toLowerCase()}
                            chartLegend={<ProductionSpeedChartLegend strokeWidth={1} strokeStyle="solid" strokeColor="#616161"/>}
                            popoverContent={(
                                <div className="d-flex flex-column" >
                                    <div style={{fontSize: 10}}><i>(Production output - Reject/rework) /</i></div>
                                    <div style={{fontSize: 10}}><i>Scheduled Production Time</i></div>
                                </div>
                            )}
                        />
                        <ProductionSpeedChartCounter
                            title="Average Speed"
                            value={avgSpeed}
                            unit={unitTime.toLowerCase()}
                            chartLegend={<ProductionSpeedChartLegend strokeWidth={1} strokeStyle="dashed" strokeColor="#616161"/>}
                            popoverContent={(
                                <div className="d-flex flex-column" >
                                    <div style={{fontSize: 10}}><i><strong>Cumulative</strong> (Production output - Reject/rework) / </i></div>
                                    <div style={{fontSize: 10}}><i><strong>Cumulative</strong> Scheduled Production Time</i></div>
                                </div>
                            )}
                        />
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            zIndex: 2,
                            fontSize: 11,
                            right: '1em',
                            fontWeight: 400,
                            top: 10
                        }}
                    >
                        <ProductionSpeedChartUnitSelect value={unitTime} onChange={setUnitTime}/>
                    </div>
                    <MultiLineChart
                        margin={{ top: 60, left: 35, right: 15, bottom: 20 }}
                        xScale={d3.scaleTime()}
                        yScale={d3.scaleLinear()}
                        data={[data.rts, data.avg]}
                        xDomain={window}
                        yDomain={yDomain}
                        xAccessor={xAccessor}
                        yAccessor={yAccessor}
                        lineDashAccessor={(_, i) => LINE_DASH[i]}
                        colorAccessor={(_, i) => LINE_COLORS[i]}
                        useBrush={false}
                        htmlTooltip={SpeedChartTooltip}
                        yTickFormat={yTickFormat}
                        showYGrid={true}
                        xTicks={4}
                    />
                </>
        </AssetWidget>
    );
};

const ProductionSpeedChartUnitSelect = (props) => {
    return (
        <div className='d-flex justify-content-end align-items-center'>
            <span className="mr-2">UOM</span>
            <Radio.Group size="small" value={props.value} onChange={(e) => props.onChange(e.target.value)}>
                <Radio.Button value="SECOND">s</Radio.Button>
                <Radio.Button value="MINUTE">m</Radio.Button>
                <Radio.Button value="HOUR">h</Radio.Button>
            </Radio.Group>
        </div>
    )
}

const ProductionSpeedChartCounter = (props) => {
    return (
        <div className="d-flex flex-column p-1 mr-2" style={{background: '#fff', border: '1px solid #ddd', borderRadius: 2}}>
            <div className="d-flex align-items-center">
                {props.title}
                <Popover placement="top" 
                    content={props.popoverContent}
                >
                    <InfoCircleOutlined className="ml-2" />
                </Popover>
            </div>
            <div className="py-1">
                <span style={{fontSize: 16}}>{props.value}</span> <span style={{fontSize: 11}}>per {props.unit}</span>
            </div>
            {props.chartLegend}
        </div>
    )
}

const ProductionSpeedChartLegend = (props) => {
    return <div className="mr-3 mb-2" style={{width: 'calc(100% - 16px)', height: 1, border: `${props.strokeWidth}px ${props.strokeStyle} ${props.strokeColor}`}}/>
}
