import React from 'react';

import AukButton from '../../../components/AukButton';
import { exportTableToCSV } from '../../../utils/dataExports';

const ReportExportButton = (props) => {
    return (
        <AukButton.Outlined 
            className="mr-2"
            icon={<i className="fas fa-download mr-2" />}
            onClick={() => { exportTableToCSV(props.tableId, props.fileName, {
                cellsFilter: (cell, i) => i < props.columnsLength
            })}
        }
        >
            Export
        </AukButton.Outlined>
    );
}

export default ReportExportButton;