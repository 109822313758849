import moment from 'moment';
import { api_getAssetOEEData, api_getChannelData, api_getFusionData } from '../../Data/Data.services';
import { api_getAssetLabels } from '../../Labels/Labels.services';
import { api_getBlockOutput } from '../../Blocks/Blocks.services';
import { batchBlockOee } from '../../Data/Data.helpers';
import { all } from 'redux-saga/effects';
import { values } from 'lodash';

const WITH_LABELS_RANGE = moment.duration(1, "week").as("seconds")

export const api_getAssetRowData = async (entity_id, asset_id, metadata_id, fusion_id, query) => {
    const promises = [
        api_getAssetOEEData(entity_id, asset_id, query),
        api_getAssetLabels(entity_id, asset_id, { date_range: query.date_range }),
    ];

    if (metadata_id) {
        promises.push(api_getChannelData(entity_id, metadata_id, query));
    } else if (fusion_id) {
        promises.push(api_getFusionData(entity_id, fusion_id, query));
    }

    return Promise.all(promises);
};

export function* api_getBlockRowData(entity, block_id, query) {
    const rangeDurationSeconds = moment.duration(moment(query.date_range.upper).diff(moment(query.date_range.lower))).as("seconds");
    const withLabels = rangeDurationSeconds <= WITH_LABELS_RANGE;

    const promises = [
        batchBlockOee({ entity, block_id, query, options: { withLabels }}),
        api_getBlockOutput(entity.entity_id, block_id, {
            starts_at: query.date_range.lower,
            ends_at: query.date_range.upper,
            resolution: query.res_x,
            period: query.res_period
        })
    ];

    const [oee, output] = yield all(promises)

    return [
        oee.oee[block_id],
        values(oee.labels[block_id]),
        {
            data: output.map(d => ({
                time: d.starts_at,
                int: d.duration,
                val: d.value
            }))
        }
    ];
}
