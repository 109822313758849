/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';

// COMPONENTS
import TrendAnalysis from './TrendAnalysis.component';

// SELECTORS
import { controlsState } from '../../../store/old/UI/Controls/Controls.selector';
import { trendState } from '../../../store/old/UI/Trend/TrendUI.selector';
import {
    mapsAssetsByPolicy,
    arrayAssetsWithoutUsingBlock,
} from '../../../store/old/Assets/Assets.selector';

// ACTIONS
import {
    TrendLoadData,
    TrendSetDataDisplay,
    ClearTrendRows,
    TrendRowUpdate,
    RemoveTrendRow,
    TrendRefreshData,
} from '../../../store/old/UI/Trend/TrendUI.action';

class TrendAnalysisContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.sku_oee !== this.props.sku_oee) {
            this.props.handleRefreshData();
        }
    }

    render() {
        return <TrendAnalysis {...(this.props)} />;
    }
}

const mapStateToProps = (root) => {
    const { oee2, sku_oee } = controlsState(root);
    const assetsByPolicy = mapsAssetsByPolicy(root.auth.user, root.assets.assets);
    return {
        oee2,
        sku_oee,
        assets: assetsByPolicy,
        arrayAssets: arrayAssetsWithoutUsingBlock(mapsAssetsByPolicy(root.auth.user, assetsByPolicy)),
        blocks: root.blocks.blocks,
        fusions: root.fusions.fusions,
        metadata: root.metadata.metadata,
        trendStore: trendState(root),
        oeeMasks: root.ui.oee.masks,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleRefreshData: (callback) =>
            dispatch(TrendRefreshData(callback)),
        handleClickUpdateNavigator: (data, callback) => dispatch(TrendLoadData(data, callback)),
        handleClickMetricDisplay: (metric) =>
            dispatch(TrendSetDataDisplay(metric)),
        handleClickClear: () => dispatch(ClearTrendRows()),
        handleUpdateRow: (data) => dispatch(TrendRowUpdate(data)),
        handleRemoveRow: (uuid) => dispatch(RemoveTrendRow(uuid)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TrendAnalysisContainer);
