/* eslint-disable react/prop-types */

import React, { useContext, useMemo } from 'react';
import moment from 'moment';
import VTable, {
    CustomFilterDropdown,
    CustomFilterIcon,
} from '../../components/VTable';
import { regexMatch, uiDatetimeFormat } from '../../utils/helpers';
import { ArrowRightOutlined } from '@ant-design/icons';

import './ParetoLabelList.scss';
import { ParetoContext } from '.';
import { Link } from 'react-router-dom';
import { round } from 'lodash';


const ParetoLabelList = (props) => {
    const { data } = props;
    const { showLabelNotes } = useContext(ParetoContext);

    const columns = useMemo(
        () =>
            [
                {
                    title: <div className="pareto-label-item">Tagged</div>,
                    render: (text, record, index) => {
                        const { label } = record;
                        const from = moment(label.from).format(uiDatetimeFormat);
                        const to = label.isOpen ? 'Now' : moment(label.to).format(uiDatetimeFormat);
                        return (
                            <div className="pareto-label-item">
                                {from} to {to}
                            </div>
                        );
                    },
                    sorter: (a, b) => Number(moment(a.from).isBefore(moment(b.from))),
                    width: 220,
                    hide: false,
                },
                {
                    title: <div className="pareto-label-item">Asset</div>,
                    render: (text, record, index) => {
                        return (
                            <div
                                title={record.label.asset.asset_name}
                                className="pareto-label-item text-overflow-ellipsis"
                            >
                                {record.label.asset.asset_name}
                            </div>
                        )},
                    sorter: (a, b) => {
                        if (a.label.asset.asset_name < b.label.asset.asset_name) return -1;
                        if (a.label.asset.asset_name > b.label.asset.asset_name) return 1;
                        return 0;
                    },
                    width: 120,
                    hide: false,
                },
                {
                    title: <div className="pareto-label-item">Hours (Eff. Hours)</div>,
                    render: (text, record, index) => {
                        const { effective_duration } = record.label;
                        const from  = moment(record.label.from);
                        const to = moment(record.label.to);
                        const duration = round(moment.duration(to.diff(from)).as('seconds'), 2)
                        return (
                            <div className="pareto-label-item text-align-right">
                                {(duration / 3600).toFixed(2)} (
                                {(effective_duration / 3600).toFixed(2)})
                            </div>
                        );
                    },
                    sorter: (a, b) =>
                        a.label.effective_duration - b.label.effective_duration,
                    width: 120,
                    hide: false,
                },
                {
                    title: <div className="pareto-label-item">Notes</div>,
                    render: (text, record, index) => {
                        return (
                            <div
                                title={record.label.notes}
                                className="pareto-label-item text-overflow-ellipsis"
                            >
                                {record.label.notes}
                            </div>
                        );
                    },
                    sorter: (a, b) => a.label.notes - b.label.notes,
                    filterSearch: true,
                    onFilter: (value, record) =>
                        regexMatch(record.label.notes, value, { escape: true }),
                    filterDropdown: CustomFilterDropdown,
                    filterIcon: (filtered) => <CustomFilterIcon active={filtered} />,
                    width: 140,
                    hide: !showLabelNotes,
                },
                {
                    title: '',
                    render: (text, record, index) => (
                        <Link to={record.url} className="pareto-label-item">
                            <ArrowRightOutlined />
                        </Link>
                    ),
                    width: 40,
                    hide: false,
                },
            ].filter((col) => !col.hide),
        [showLabelNotes]
    );

    return (
        <div className="pareto-label-list">
            <div className="d-flex flex-column h-100">
                <VTable
                    rowHeight={28}
                    columns={columns}
                    dataSource={data}
                    rowKey={({ label }) => label.label_id}
                />
            </div>
        </div>
    );
};

export default ParetoLabelList;
