import React, { useState } from 'react';
import './index.scss';

import { SPAWrapper } from "../../components/SPAWrapper";
import IssueSeriesFilter from './components/IssueSeriesFilter';
import IssueReportTable from './components/IssueReportTable';
import IssueReportSummaryTable from './components/IssueReportSummaryTable';
import ReportExportButton from './components/ReportExportButton';
import ToggleSummary from './components/ToggleSummary';
import { withIssues } from '../../Wrappers/HOCs/withIssues';

const IssueReportPage = () => {
    const [showSummary, setShowSummary] = useState(true);

    return <SPAWrapper className="report-page">
        <div className="report-page__header">
            <IssueSeriesFilter/>
        </div>
        <div className="report-page__sub-header">
            <ReportExportButton 
                columnsLength={6}
                tableId="issueReportTable" 
                fileName="issue-report.csv"
            />
            <ToggleSummary onClick={() => setShowSummary(!showSummary)}/>
        </div>
        <div className="report-page__summary">
            { showSummary ? <IssueReportSummaryTable/> : null }
        </div>
        <div className="report-page__content">
            <IssueReportTable/>
        </div>
    </SPAWrapper>
}

export default withIssues(IssueReportPage);
