import { ReportConstants as K } from './Report.constants';

export const setOeeReportFilter = (oeeFilter) => {
    return {
        type: K.ACTIONS.SET_OEE_REPORT_FILTER,
        payload: { oeeFilter }
    }
}

export const setOeeTableData = (filters, dataSource) => {
    return {
        type: K.ACTIONS.SET_OEE_TABLE_DATA,
        payload: { filters, dataSource }
    }
}

export const setSkuTableData = (filters, dataSource) => {
    return {
        type: K.ACTIONS.SET_SKU_TABLE_DATA,
        payload: { filters, dataSource }
    }
}

export const setIssueTableData = (filters, dataSource) => {
    return {
        type: K.ACTIONS.SET_ISSUE_TABLE_DATA,
        payload: { filters, dataSource }
    }
}

export const setOeeReportColumns = (oeeColumns) => {
    return {
        type: K.ACTIONS.SET_OEE_REPORT_COLUMNS,
        payload: { oeeColumns }
    }
}

export const setSkuReportColumns = (skuColumns) => {
    return {
        type: K.ACTIONS.SET_SKU_REPORT_COLUMNS,
        payload: { skuColumns }
    }
}

export const setOeeReportData = (oeeData) => {
    return {
        type: K.ACTIONS.SET_OEE_REPORT_DATA,
        payload: { oeeData }
    }
}

export const fetchOeeReportData = (options, callback) => {
    return {
        type: K.ACTIONS.REFRESH_OEE_REPORT_DATA,
        payload: { options },
        callback
    }
}

export const setSkuReportFilter = (skuFilter) => {
    return {
        type: K.ACTIONS.SET_SKU_REPORT_FILTER,
        payload: { skuFilter }
    }
}

export const setSkuReportData = (skuData) => {
    return {
        type: K.ACTIONS.SET_SKU_REPORT_DATA,
        payload: { skuData }
    }
}

export const fetchSkuReportData = (options, callback) => {
    return {
        type: K.ACTIONS.REFRESH_SKU_REPORT_DATA,
        payload: { options },
        callback
    }
}

export const setIssueReportFilter = (issueFilter) => {
    return {
        type: K.ACTIONS.SET_ISSUE_REPORT_FILTER,
        payload: { issueFilter }
    }
}

export const setIssueReportData = (issueData) => {
    return {
        type: K.ACTIONS.SET_ISSUE_REPORT_DATA,
        payload: { issueData }
    }
}

export const fetchIssueReportData = (options, callback) => {
    return {
        type: K.ACTIONS.REFRESH_ISSUE_REPORT_DATA,
        payload: { options },
        callback
    }
}
