import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import type ImpactReportModel from "../../api/models/impact_report.model"
import { keyBy, pickBy } from "lodash"

interface ImpactReportsState {
    loading: boolean
    reports: Record<string | number, ImpactReportModel>
    viewing: ImpactReportModel | null
}

const initialState: ImpactReportsState = {
    loading: false,
    reports: {},
    viewing: null,
}

export const impactReportsSlice = createSlice({
    name: "reports",
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        clear: () => {
            return initialState
        },
        set: (state, action: PayloadAction<ImpactReportModel[]>) => {
            state.reports = keyBy(action.payload, "id")
        },
        add: (state, action: PayloadAction<ImpactReportModel[]>) => {
            state.reports = { ...state.reports, ...keyBy(action.payload, "id") }
        },
        remove: (state, action: PayloadAction<number>) => {
            state.reports = pickBy(state.reports, report => report.id !== action.payload)
        },
        view: (state, action: PayloadAction<ImpactReportModel | null>) => {
            state.viewing = action.payload
        }
    },
})

export const { clear, set, setLoading, view, add, remove } = impactReportsSlice.actions

export default impactReportsSlice.reducer
