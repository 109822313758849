import moment from 'moment';
import * as d3 from 'd3';
import { round } from 'lodash';
import { api_getAssetLabels } from '../../store/old/Labels/Labels.services';

// CHART BRUSH
export const getLabelsChartXScale = (domain, selector) => {
    if (!document.querySelector(selector)) return [];
    const canvasWidth = document.querySelector(selector).clientWidth;
    const range = [0, canvasWidth];
    return d3.scaleTime().domain(domain).range(range);
};

// GENERAL
export const isLabelInRange = (label, rangeStart, rangeEnd) => {
    const from = moment(label.from);
    const to = label.to ? moment(label.to) : moment();
    return !(to.isBefore(rangeStart) || from.isAfter(rangeEnd));
};

export const getSpan = (range, unit = 'hours') => {
    const val = round(moment(range[1]).diff(moment(range[0]), 'hours', true), 2);
    return `${val} ${unit}`;
};

export const checkFutureLabel = (label) => {
    const now = moment();

    return (
        now.isBefore(label.from) || now.isBetween(label.from, label.to, null, '(]')
    );
};

export const findOverlappingLabels = (labelArr, brushStart, brushEnd, current) => {
    return labelArr.filter((l) => {
        return !(
            moment(l._to).isSameOrBefore(moment(brushStart)) ||
      moment(l.from).isSameOrAfter(moment(brushEnd)) ||
      l.label_id === (current && current.label_id)
        );
    });
};

export function compileLabel(params, options = {}) {
    const { from, to, values, label_id } = params;

    return {
        label_id,
        from,
        to,
        label_values: collateLabelValues(values),
        ...options,
    };

}

// GENERAL
export function labelDisplayText(label) {
    return label.label_values
        ? label.label_values
            .filter((v) => v.type !== 'notes')
            .map((v) => {
                const { type } = v;

                let fieldValue = '';

                switch (type) {
                case 'sku':
                    fieldValue = label.sku.codeName;
                    break;
                case 'issue':
                    fieldValue = label.issue.name;
                    break;
                default:
                    fieldValue = v.value;
                }

                return `[${type.toUpperCase()}] ${fieldValue}`;
            })
            .join(' | ')
        : '';
}

export function generateStopLabel(label) {
    const { from, label_id } = label;

    return {
        label_id,
        from,
        to: new Date(),
    };
}

export function generateForwardLabel(attributes, current = {}) {
    const newLabelValues = collateLabelValues(attributes);
    return _.isEmpty(current)
        ? { label_values: newLabelValues, from: new Date() }
        : {
            ...current.clone(),
            label_values: current.label_values.concat(newLabelValues),
        };
}

function collateLabelValues(attributes) {
    /*
    attributes should be of the form
    {
      sku: sku <Object>,
      issue: issue <Object>,
      [others]: value <String>
    }
  */
    return Object.entries(attributes)
        .filter(([_, value]) => value)
        .map(([type, value]) => {
            if (type === 'sku') return { type, sku_id: value }; // change to pass in sku_id
            if (type === 'issue') return { type, issue_id: value };
            return { type, value };
        });
}

export const fetchAssetIssueLabels = (entityId, assetId, range) => {
    const query = { 
        types: ["issue"],
        date_range: {
            lower: range[0],
            upper: range[1]
        }
    }

    return api_getAssetLabels(entityId, assetId, query)
}

export const fetchAssetSkuLabels = (entityId, assetId, range) => {
    const query = { 
        types: ["sku"],
        date_range: {
            lower: range[0],
            upper: range[1]
        }
    }

    return api_getAssetLabels(entityId, assetId, query)
}

export const filterLabelByTypeId = (label, type, assessor) => {
    return label.label_values.find(v => v.type === type && assessor(v))
}

