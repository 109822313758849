import * as d3 from 'd3';
import React from 'react';
import { AutoSizer } from 'react-virtualized';

import { BaseChartSimple } from './BaseChart';
import {
    clearCanvas,
    genColor,
    getTimeIntervalBandwidth,
    getTimeSeriesBrushXSelection,
} from '../../utils/charts';

const RADIUS = 2;

class LineChartSimple extends BaseChartSimple {
    constructor(props) {
        super(props);

        this.redrawCustom = this.redrawCustom.bind(this);
        this.redrawCanvas = this.redrawCanvas.bind(this);
        this.redrawHidden = this.redrawHidden.bind(this);
    }

    redrawCustom() {
        const {
            props: {
                transition,
                duration,
                data,
                xAccessor,
                yAccessor,
                colorAccessor,
                isArea,
            },
            x,
            y,
            h,
            custom,
        } = this;

        const t = transition || null;

        // line
        const lineSelection = custom.selectAll('.path').data([data], (d, i) => i);

        lineSelection
            .enter()
            .append('custom')
            .attr('class', 'path')
            .attr('stroke', colorAccessor);

        lineSelection.transition(t).duration(duration);

        lineSelection.exit().remove();

        // area
        if (isArea) {
            const areaSelection = custom.selectAll('.area').data([data], (d, i) => i);

            areaSelection
                .enter()
                .append('custom')
                .attr('class', 'area')
                .attr('fill', '#ddd');

            areaSelection.transition(t).duration(duration);

            areaSelection.exit().remove();
        }

        // dots
        const dotSelection = custom
            .selectAll('.dot')
            .data(data, (d) => x(xAccessor(d)));

        dotSelection
            .enter()
            .append('custom')
            .attr('class', 'dot')
            .attr('r', RADIUS)
            .attr('fill', colorAccessor)
            .attr('cx', (d) => x(xAccessor(d)))
            .attr('cy', (d) => Math.min(y(yAccessor(d)), h - 0.5));

        dotSelection
            .transition(t)
            .duration(duration)
            .attr('cx', (d) => x(xAccessor(d)))
            .attr('cy', (d) => Math.min(y(yAccessor(d)), h - 0.5));

        dotSelection.exit().remove();

        this.redrawYGridLinesCustom();
    }

    redrawCanvas() {
        const {
            props: { xAccessor, yAccessor, dots, isArea },
            x,
            y,
            w,
            h,
            canvas,
            custom,
        } = this;

        // clear canvas
        const context = canvas.node().getContext('2d');
        clearCanvas(context, w, h);

        // draw line
        const line = d3
            .line()
            .x((d) => Math.round(x(xAccessor(d))))
            .y((d) => Math.round(Math.min(y(yAccessor(d)), h - 0.5)))
            .context(context);

        const lineSelection = custom.selectAll('.path');

        lineSelection.each(function (d, i) {
            const node = d3.select(this);

            if (node.empty()) return;

            context.beginPath();
            line(d);
            context.lineWidth = 2;
            context.strokeStyle = node.attr('stroke');
            context.stroke();
            context.closePath();
        });

        // draw area
        if (isArea) {
            const area = d3
                .area()
                .x((d) => x(xAccessor(d)))
                .y0(h)
                .y1((d) => y(yAccessor(d)))
                .context(context);

            const areaSelection = custom.selectAll('.area');

            areaSelection.each(function (d, i) {
                const node = d3.select(this);

                if (node.empty()) return;
                context.beginPath();

                area(d);
                context.fillStyle = node.attr('fill');
                context.fill();
            });
        }

        // draw dots
        if (dots) {
            const dotSelection = custom.selectAll('.dot');
            dotSelection.each(function (d, i) {
                const node = d3.select(this);

                if (node.empty()) return;

                context.beginPath();
                context.arc(
                    node.attr('cx'),
                    node.attr('cy'),
                    node.attr('r'),
                    2 * Math.PI,
                    false
                );
                context.fillStyle = node.attr('fill');
                context.fill();
                context.closePath();
            });
        }

        this.redrawYGridLinesCanvas();
    }

    redrawHidden() {
        const {
            props: { getBandwidth },
            w,
            h,
            x,
            colorToNode,
            hidden,
            custom,
        } = this;

        const hiddenContext = hidden.node().getContext('2d');
        clearCanvas(hiddenContext, w, h);

        const dotSelection = custom.selectAll('.dot');
        dotSelection.each(function (d, i) {
            const node = d3.select(this);

            if (node.empty()) return;
            
            const bandwidth = getBandwidth(d, x);
            const color = genColor(i);
            colorToNode.set(color, d);

            hiddenContext.beginPath();
            hiddenContext.fillStyle = color;
            hiddenContext.rect(+node.attr('cx'), 0, bandwidth, h);
            hiddenContext.fill();
            hiddenContext.closePath();
        });
    }

    showFocus(d) {
        const {
            props: { xAccessor, yAccessor, colorAccessor },
            x,
            y,
            focus,
        } = this;

        focus
            .attr('opacity', 1)
            .attr('r', RADIUS + 4)
            .attr('fill', colorAccessor)
            .attr('cx', x(xAccessor(d)))
            .attr('cy', y(yAccessor(d)))
            .style('stroke', colorAccessor)
            .style('fill', 'none');
    }
}

export class LineChart extends React.Component {
    render() {
        return (
            <AutoSizer>
                {({ width, height }) => (
                    <LineChartSimple {...this.props} width={width} height={height} />
                )}
            </AutoSizer>
        );
    }
}

LineChart.defaultProps = {
    colorAccessor: () => 'steelblue',
    data: [],
    xDomain: [],
    yDomain: [],
    dots: true,
    isArea: false,
    xAccessor: (d) => d,
    yAccessor: (d) => d,
    useBrush: false,
    snapBrush: getTimeSeriesBrushXSelection,
    getBandwidth: getTimeIntervalBandwidth,
    useTooltip: true,
    htmlTooltip: (d) => d,
    focusEl: 'circle',
};

/* requiredProps - example */
/*
  <LineChart
    xScale={d3.scaleTime()}
    yScale={d3.scaleLinear()}
    data={data}
    xDomain={[store.startDate, store.endDate]}
    yDomain={generateContinuousDomain(data, (d) => d.val)}
    xAccessor={(d) => d.time}
    yAccessor={(d) => d.val}
    useBrush={true}
    htmlTooltip={ColumnChartTooltip}
    focusEl="circle"
  /> 
*/
