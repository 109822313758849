import { isFinite } from "lodash";
import { store } from "../../store";

export const parseChartArguments = (c) => {
    return [
        c.chart_id,
        c.asset_id,
        c.title,
        c.chart_order,
        c.units,
        c.chart_type,
        c.color,
        c.control_limits,
        c.fusion?.fusion_id,
        c.metadatum?.metadata_id,
        c.modifier
    ]
}

export class Chart {
    constructor(
        chart_id,
        asset_id,
        title,
        chart_order,
        units,
        chart_type,
        color,
        control_limits,
        fusion_id,
        metadata_id,
        modifier
    ) {
        this.chart_id = chart_id;
        this.asset_id = asset_id;
        this.title = title;
        this.chart_order = chart_order;
        this.units = units || 'units'
        this.chart_type = chart_type;
        this.color = color || '#4682b4';
        this.control_limits = control_limits;
        this.fusion_id = fusion_id;
        this.metadata_id = metadata_id;
        this.modifier = isFinite(modifier) ? modifier : 1;
    }

    get asset() {
        if (!this.asset_id) return null;

        const {
            assets: { assets },
        } = store.getState();

        return assets[this.asset_id];        
    }

    get fusion() {
        if (!this.fusion_id) return null;

        const {
            fusions: { fusions },
        } = store.getState();

        return fusions[this.fusion_id];
    }
    
    get metadatum() {
        if (!this.metadata_id) return null;

        const {
            metadata: { metadata },
        } = store.getState();

        return metadata[this.metadata_id];
    }

    get dataSource() {
        return this.fusion || this.metadatum;
    }
}