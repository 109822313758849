/* eslint-disable react/display-name */

import moment from "moment"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { controlsState, getWindow } from "../../store/old/UI/Controls/Controls.selector"
import { minDevicePushRate } from "../../store/old/Devices/Devices.selector"
import { eventEmitter } from "../../legacy/auxStore"
import CONSTANTS from "../../legacy/Constants"
import { generateSpanObj, getBrowserTz, getRangeFromCustomSpan, getRangeFromSpan, withinStreamingWindow } from "../../legacy/utils/controls"
import { currentEntitySelector } from "../../store/old/Entity/Entity.selector"
import { CUSTOM_SPAN } from "../../store/old/UI/Controls/Controls.constants"
import { generateUrlQuery } from "../../legacy/utils/url"
import { useLocation, useNavigate } from "react-router-dom"

let intervalId: NodeJS.Timeout | undefined

const WithStreaming = (props: Props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const tabVisible = useSelector(appState => (appState as any).ui.window.visibility)
    const window = useSelector(getWindow)
    const controls = useSelector(controlsState)
    const minPushRate = useSelector(minDevicePushRate)
    const entity = useSelector(currentEntitySelector)

    const changeStreamingWindow = (_controls: any) => {
        const { span, startDate, endDate, resolution } = _controls
        const { dayStartTimezone } = entity

        const now = moment()

        let newStart, newEnd

        if (span === CUSTOM_SPAN) {
            [newStart, newEnd] = getRangeFromCustomSpan(startDate, endDate, now)
        } else {
            [newStart, newEnd] = getRangeFromSpan(
                now,
                generateSpanObj(span),
                getBrowserTz(),
                dayStartTimezone
            )
        }

        const query = generateUrlQuery({
            startDate: newStart, 
            endDate: newEnd,
            resolution,
            span
        })

        navigate(`${location.pathname}${query}`)
    }

    useEffect(() => {
        if (!controls.initialized) return
        if (controls.startDate.isAfter(controls.endDate)) return

        eventEmitter.trigger(CONSTANTS.EVENTS.REFRESH, null)
    }, [
        controls.startDate.toISOString(), 
        controls.endDate.toISOString(), 
        controls.resolution,
        controls.sku_oee
    ])

    useEffect(() => {
        if (controls.startDate.isAfter(controls.endDate)) return

        if (!controls.streaming) return

        const streamIntervalMs = getStreamIntervalMs(controls.resolution, minPushRate)
        intervalId = setInterval(() => { 
            const now = moment()

            if (!tabVisible) return

            if (!withinStreamingWindow(controls.startDate, controls.endDate, now, controls.resolution)) {
                changeStreamingWindow(controls)
                return
            }

            eventEmitter.trigger(CONSTANTS.EVENTS.REFRESH, null) 
        }, streamIntervalMs)

        return () => {
            clearInterval(intervalId)
        }

    }, [
        controls.streaming, 
        controls.startDate.toISOString(), 
        controls.endDate.toISOString(), 
        controls.resolution,
        controls.sku_oee,
        minPushRate,
        tabVisible
    ])

    return <>{props.render({ controls, window })}</>
}

interface Props {
    render: (props: ControlsRenderProps) => React.ReactNode 
}

export interface ControlsRenderProps {
    controls: any;
    window: any
}

const withStreaming = (Component: React.FC<any>) => {
    return (componentProps: any) => {
        return (
            <WithStreaming
                render={(props) => <Component {...componentProps} {...props} />}
            />
        )
    }
}

export default withStreaming


const getStreamIntervalMs = (res: { res_x: number, res_period: string }, minPushRate: number) => {
    const { res_x, res_period } = res
    
    const intervalMs = Math.max(
        moment.duration(res_x, res_period as moment.unitOfTime.Base).as("milliseconds"),
        minPushRate * 1000
    )
    
    const maxInterval = moment.duration(1, "weeks").as("milliseconds")
    return Math.min(intervalMs, maxInterval)
}
