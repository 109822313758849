import moment from 'moment';
import { csvDatetimeFormat, getAllLeaves } from '../../utils/helpers';
import { round } from 'lodash';
import { getMaskedOEE } from '../../utils/oee';

const oeeColumns = (masks) =>
    getMaskedOEE(masks).sort((a, b) => a.csvOrder - b.csvOrder);

const getData = (row, data) => {
    const { uuid, metric } = row;

    switch (metric.value) {
    case 'data':
        return {
            timeSeries: data[uuid].data,
            labels: data[uuid].labels,
        };
    default:
        return {
            oee: data[uuid].oee,
            timeSeries: data[uuid].oee.oee,
            labels: data[uuid].labels,
        };
    }
};

const getLabelFields = (block) => {
    if (block.asset) {
        return block.asset.labelFields.map(f => f.field);
    };

    const assets = getAllLeaves(block, b => b.asset).map(b => b.asset);
    const labelFieldsSet = assets.reduce((acc, curr) => {
        return new Set([...acc].concat(...curr.labelFields.map(f => f.field)))
    }, new Set())

    return [...labelFieldsSet]
}

const getBlock = (blocks, row) => {
    return blocks[row.block_id];
};

const getChart = (asset, row) => {
    return asset._charts.find((c) => c.chart_id === row.chart_id);
};

const findLabelsInInterval = (datum, labels) => {
    const intervalStart = new moment(datum.time);
    const intervalEnd = intervalStart.clone().add(datum.int, 'milliseconds');
    return labels.filter((l) => {
        let { from, to } = l;
        from = new moment(from);
        to = to ? new moment(to) : new moment();
        return (
            from.isBetween(intervalStart, intervalEnd, null, '[)') ||
      to.isBetween(intervalStart, intervalEnd, null, '(]') ||
      intervalStart.isBetween(from, to, null, '[]')
        );
    });
};

const generateLabelRow = (block, labels) => {
    const composite = labels.reduce((acc, curr) => {
        Object.keys(curr.attributes).forEach((key) => {
            const value = curr.attributes[key];
            acc[key] = acc[key] ? acc[key].concat(value) : [value];
        });
        return acc;
    }, {});

    return getLabelFields(block).map((field) => {
        const key = field.toLowerCase();
        return composite[key] ? `"${composite[key].join(' | ')}"` : '';
    });
};

const generateDataRowOEE = (datum, masks) => {
    const { time, oee } = datum;

    return [
        moment(time).format(csvDatetimeFormat),
        ...oeeColumns(masks).map(({ key }) => round(oee[key], 2)),
    ];
};

const generateDataRowProduction = (datum) => {
    const { time, val } = datum;
    return [moment(time).format(csvDatetimeFormat), val];
};

const getHeaderColumns = (row, block, chart, masks) => {
    const labelHeader = getLabelFields(block);
    const oeeHeader = oeeColumns(masks).map((cat) => cat.altLabel);
    const productionHeader = [chart ? chart.title : block.block_name];

    const header = (row.metric.value === 'data' ? productionHeader : oeeHeader)
        .concat(...labelHeader)
        .join(',');

    return 'Time,' + header;
};

const getAggregateBlock = (data, masks) => {
    const cols = oeeColumns(masks);
    return [
        'Total (hours),' +
      cols
          .map(({ key }) =>
              round((data[key] * data.totalSeconds) / 100 / 3600, 2)
          )
          .join(','),
        'Percent,' + cols.map(({ key }) => round(data[key], 2)).join(','),
    ].join('\n');
};

const trendCSV = (blocks, rows, data, masks) => {
    const chunks = rows.map((row) => {
        const block = getBlock(blocks, row);
        const chart = block.asset ? getChart(block.asset, row) : null;
        const { timeSeries, labels, oee } = getData(row, data);

        const labelsBlock = timeSeries.map((datum) => {
            const result = findLabelsInInterval(datum, labels);
            return generateLabelRow(block, result);
        });

        const dataBlock = timeSeries.map((datum) =>
            row.metric.value === 'data'
                ? generateDataRowProduction(datum)
                : generateDataRowOEE(datum, masks)
        );

        const csvRows = timeSeries
            .map((x, i) => dataBlock[i].concat(...labelsBlock[i]))
            .join('\n');

        const headers = getHeaderColumns(row, block, chart, masks);

        let aggregateBlock = '';
        if (row.metric.value !== 'data') {
            aggregateBlock = getAggregateBlock(oee.aggregate_oee, masks);
        }

        const title = block.asset ? `${block.asset.asset_name} (${chart.chart_title})` : block.block_name;

        return (
            title +
      '\n' +
      headers +
      '\n' +
      csvRows +
      '\n' +
      aggregateBlock +
      '\n'
        );
    });

    return chunks.join('\n');
};

export default trendCSV;
