import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AukButton from '../../../components/AukButton';
import { ToggleOEESKU } from '../../../../store/old/UI/Controls/Controls.action';

const OeeSkuButton = (props) => {
    const dispatch = useDispatch();
    const sku_oee = useSelector(appState => appState.ui.controls.sku_oee);

    return (
        <AukButton.Blue
            className="mr-3"
            style={{width: 80}}
            ghost={!sku_oee}
            onClick={(e) => {
                dispatch(ToggleOEESKU());
                props.onClick({sku_oee: !sku_oee});
            }}
        >
            {`SKU-${sku_oee ? 'ON' : 'OFF'}`}
        </AukButton.Blue>
    )
}

export default OeeSkuButton;